import SystemBoundaryGridPdf from 'components/v2/compiler/grids/system-boundary/SystemBoundaryGridPdf';
import React from 'react';
import { EPDDefinitionModel, Option, SystemBoundaryModel } from 'types/types';
import DebugOnly from 'util/dev-tools/DebugOnly';
import RenderCounter from 'util/dev-tools/RenderCounter';

import { PDF_H1, PDF_PAGE, PDF_Table } from './Styles.styled';

const SystemBoundaryPage: React.FunctionComponent<{
  epdVersionId: string;
  epdDefinition: EPDDefinitionModel;
  systemBoundaryData?: SystemBoundaryModel;
  systemBoundaryDescriptionLookup: Option[];
}> = ({ epdVersionId, epdDefinition, systemBoundaryData, systemBoundaryDescriptionLookup }) => {
  const systemBoundaryHasExcludedModulesLookup = [
    { value: true, label: 'Yes, there is an excluded module, or there are excluded modules' },
    { value: false, label: 'No, there is no excluded module, or there are no excluded modules' },
  ];

  return (
    <PDF_PAGE className="landscape">
      <DebugOnly>
        <RenderCounter componentName="SystemBoundary" threshold={3} />
      </DebugOnly>

      <PDF_H1>System boundary</PDF_H1>
      <PDF_Table>
        <thead>
          <tr>
            <th style={{ width: '30%' }}></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Description of the System boundary</td>
            <td>{systemBoundaryDescriptionLookup.find((x: any) => x.value == systemBoundaryData?.description)?.label}</td>
          </tr>
          <tr>
            <td>Excluded modules</td>
            <td>
              {systemBoundaryHasExcludedModulesLookup.find((x) => x.value == systemBoundaryData?.hasExcludedModules)?.label}
            </td>
          </tr>
          {systemBoundaryData?.hasExcludedModules && (
            <tr>
              <td>Justification for omission of modules</td>
              <td>{systemBoundaryData?.excludedModulesJustification}</td>
            </tr>
          )}
        </tbody>
      </PDF_Table>
      <br />
      <SystemBoundaryGridPdf epdVersionId={epdVersionId} productType={epdDefinition.productType} />
    </PDF_PAGE>
  );
};

export default SystemBoundaryPage;
