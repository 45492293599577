import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useEpdFormat } from 'services/api/queries';
import EpdCompiler from 'pages/v2/compiler/EpdCompiler';
import EpdWizard from './EpdWizard';
import { QUERY_KEYS } from 'services/api/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { EPDModel } from 'services/EpdClient';

const WizardSelector: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const epdFormatQuery = useEpdFormat(id);
  const queryClient = useQueryClient();
  const { companyId, companyAccountId } = useContext(CompanyContext);

  let epdFormat = epdFormatQuery?.data;
  if (!epdFormat) {
    // Try checking EPD wizard format optimistically in already available data
    const epds = queryClient.getQueryData<EPDModel[]>([QUERY_KEYS.EPDS_LIST_DASHBOARD, companyId, companyAccountId]);
    if (epds) {
      const currentEpd = epds.find((e) => e.id === id);
      if (currentEpd) {
        epdFormat = currentEpd.isCompilerFormat ? 'compiler' : 'wizard';
      }
    }
  }

  if (!epdFormat) {
    return null;
  }

  return epdFormat === 'compiler' ? <EpdCompiler /> : <EpdWizard />;
};

export default WizardSelector;
