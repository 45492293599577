import MandatoryIcon from 'components/icons/MandatoryIcon';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { EPDDeveloperModel, EPDModel, EPDVerifierModel, VersionStateType } from 'services/EpdClient';
import { FieldState } from 'services/EpdClient';
import * as Yup from 'yup';

import { WizardStepValidationSchema } from './types';
import { EPDBasedOnUnitEnum } from 'types/types';

export function Validation(props: { validation: WizardStepValidationSchema; field: keyof EPDModel; epd?: EPDModel }) {
  const { t } = useTranslation();

  if (props.epd == null) {
    return null;
  }

  try {
    props.validation.validateSyncAt(props.field, props.epd);
  } catch (error: any) {
    const messageExists = i18next.exists(error.message);
    return <MandatoryIcon message={messageExists ? t(error.message) : undefined} />;
  }

  return null;
}

export const isNullOrEmptyHtml = (value: any) => {
  const valueWithoutPTag = value.replace(/(<([^>]+)>)/gi, '');

  const stringWithSpaces = valueWithoutPTag.replace(/&nbsp;/g, ' ');

  return stringWithSpaces.trim().length === 0;
};

// Validation Helpers
export const validatedString = (prop: keyof EPDModel | string) => ({
  [prop]: Yup.string().when([`${prop}State`], (state: FieldState, schema: Yup.StringSchema) =>
    state.mandatory
      ? schema.test('not-empty-or-empty-html-tags', 'Required', (value) => {
          if (!value || value.trim() === '' || value.trim() === '<p></p>' || isNullOrEmptyHtml(value)) {
            return false;
          }
          return true;
        })
      : schema.nullable()
  ),
});

export const validatedDate = (prop: keyof EPDModel) => ({
  [prop]: Yup.date()
    .transform((value, input) => {
      if ((input ?? '') === '') return null;
      if (isNaN(value)) return null;
      if (value.toISOString().slice(0, 10) === '1900-12-31') return null;
      return new Date(value);
    })
    .when([`${prop}State`], (state: FieldState, schema: Yup.DateSchema) =>
      state.mandatory ? schema.required() : schema.nullable()
    ),
});

export const validatedArray = (prop: keyof EPDModel) => ({
  [prop]: Yup.array().when([`${prop}State`], (state: FieldState, schema: Yup.ArraySchema<any>) =>
    state.mandatory ? schema.min(1) : schema.nullable()
  ),
});

export const validatedEnum = (prop: keyof EPDModel, enumValues: any) => ({
  [prop]: Yup.mixed().when([`${prop}State`], (state: FieldState, schema: Yup.MixedSchema) =>
    state.mandatory ? schema.oneOf(Object.values(enumValues)) : schema.nullable()
  ),
});

export const validatedDocuments = (prop: keyof EPDModel) => ({
  [prop]: Yup.array()
    .when([`${prop}State`], (state: FieldState, schema: Yup.ArraySchema<any>) =>
      state.mandatory ? schema.min(1) : schema.nullable()
    )
    .test('contains-epd-document', 'At least one document must be the type of "EPD document"', (documents) => {
      if (!documents || !documents?.length) return true;
      return documents.some((doc: any) => doc.name.includes('EPD document'));
    }),
});

export const validatedDataSourcesDictionary = (prop: keyof EPDModel) => ({
  [prop]: Yup.array().when([`${prop}State`], (state: FieldState, schema: Yup.ArraySchema<any>) =>
    schema
      .of(
        Yup.object().shape({
          dataSource: Yup.object().shape({ id: Yup.string().required(), name: Yup.string().required() }),
          dataSourceVersion: Yup.object().shape({ id: Yup.string().required(), name: Yup.string().required() }),
        })
      )
      .when([], { is: () => state.mandatory === true, then: schema.min(1), otherwise: schema.nullable() })
  ),
});

export const validatedSubObject = (prop: keyof EPDModel) => ({
  [prop]: Yup.object().when([`${prop}State`], (state: FieldState, schema: Yup.ObjectSchema) =>
    state.mandatory ? schema.shape({ id: Yup.string().required() }) : schema.nullable()
  ),
});

export const validatedNumber = (prop: keyof EPDModel) => ({
  [prop]: Yup.number().when([`${prop}State`], (state: FieldState, schema: Yup.NumberSchema) =>
    state.mandatory ? schema.required() : schema.nullable()
  ),
});

const validatedEPDDeveloper = (prop: keyof EPDModel) => ({
  [prop]: Yup.array()
    .when([`${prop}State`], (state: FieldState, schema: Yup.ArraySchema<any>) =>
      state.mandatory ? schema.required() : schema.nullable()
    )
    .test({
      name: 'not-same-verifier-company',
      message: 'epdWizard.wizardStep1.properties.epdDeveloper.messages.sameCompany',
      test: function (value: any) {
        const epdVerifier = this.parent.epdVerifier as EPDVerifierModel;

        return (
          !value ||
          !epdVerifier ||
          value.length === 0 ||
          !value.find(
            (developer: EPDDeveloperModel) =>
              developer.companyId === epdVerifier.companyId &&
              developer.contactUserId !== epdVerifier.contactUserId &&
              !epdVerifier.isExternalMembership
          )
        );
      },
    })
    .test({
      name: 'not-same-verifier-user',
      message: 'epdWizard.wizardStep1.properties.epdDeveloper.messages.sameUser',
      test: function (value: any) {
        const epdVerifier = this.parent.epdVerifier as EPDVerifierModel;

        return (
          !value ||
          !epdVerifier ||
          value.length === 0 ||
          !value.find((developer: EPDDeveloperModel) => developer.contactUserId === epdVerifier.contactUserId)
        );
      },
    }),
});

const validatedEPDVerifier = (prop: keyof EPDModel, verifiers?: EPDVerifierModel[]) => ({
  [prop]: Yup.object()
    .when([`${prop}State`], (state: FieldState, schema: Yup.ObjectSchema) =>
      state.mandatory ? schema.shape({ id: Yup.string().required() }) : schema.nullable()
    )
    .test({
      name: 'not-same-developer-company',
      message: 'epdWizard.wizardStep1.properties.epdVerifier.messages.sameCompany',
      test: function (value: any) {
        const epdDevelopers = this.parent.epdDevelopers as EPDDeveloperModel[];

        return (
          !value ||
          !epdDevelopers ||
          epdDevelopers.length === 0 ||
          !epdDevelopers.find(
            (developer: EPDDeveloperModel) =>
              developer.companyId === value.companyId &&
              developer.contactUserId !== value.contactUserId &&
              !value.isExternalMembership
          )
        );
      },
    })
    .test({
      name: 'not-same-developer',
      message: 'epdWizard.wizardStep1.properties.epdVerifier.messages.sameUser',
      test: function (value: any) {
        const epdDevelopers = this.parent.epdDevelopers as EPDDeveloperModel[];

        return (
          !value ||
          !epdDevelopers ||
          epdDevelopers.length === 0 ||
          !epdDevelopers.find((developer: EPDDeveloperModel) => developer.contactUserId === value.contactUserId)
        );
      },
    })
    .test({
      name: 'excluded-from-company',
      message: 'epdWizard.wizardStep1.properties.epdVerifier.messages.notAvailable',
      test: function (value: any) {
        if (!value || !verifiers) {
          return true;
        }
        const parent = this.parent as EPDModel;
        const currentVersionState = (parent.versionState as VersionStateType) || VersionStateType.Draft;
        const statesWhereHavingMissingVerifierIsFine = [
          VersionStateType.Approved,
          VersionStateType.Registered,
          VersionStateType.RegisteredEditorial,
          VersionStateType.Deregistered,
          VersionStateType.LicenseeAdminVerification,
        ];
        if (statesWhereHavingMissingVerifierIsFine.includes(currentVersionState)) {
          return true;
        }
        const isVerifierAvailable = verifiers.some((v) => v.contactUserId === value.contactUserId);
        return isVerifierAvailable;
      },
    }),
});

export const validatedPublicVersionDate = (prop: keyof EPDModel, epdVersions?: EPDModel[]) => ({
  [prop]: Yup.date()
    .transform((value, input) => {
      if ((input ?? '') === '') return null;
      if (isNaN(value)) return null;
      if (value.toISOString().slice(0, 10) === '1900-12-31') return null;
      return new Date(value);
    })
    .when([`${prop}State`], (state: FieldState, schema: Yup.DateSchema) =>
      state.mandatory ? schema.required() : schema.nullable()
    )
    .test({
      name: 'not-later-than-registration-date',
      message: 'epdWizard.wizardStep4.properties.revisionDate.messages.earlierThanRegistrationDate',
      test: function (value: any) {
        const publicationDate = this.parent.registrationDate;

        return (
          !this.parent.publicVersionDateState.mandatory ||
          (value && new Date(value.toDateString()) >= new Date(publicationDate.toDateString()))
        );
      },
    }),
});

export const validatedSubtype = (prop: keyof EPDModel) => ({
  [prop]: Yup.object().when([`${prop}State`], (state: FieldState, schema: Yup.ObjectSchema) => {
    return state.mandatory ? schema.shape({ id: Yup.string().required() }) : schema.nullable();
  }),
});

export const step1ValidationSchema = (verifiersDataSource?: EPDVerifierModel[]) =>
  Yup.object<Partial<EPDModel>>({
    ...validatedString('title'),
    ...validatedEPDDeveloper('epdDevelopers'),
    ...validatedSubObject('preverifiedTool'),
    ...validatedSubObject('preverifiedToolVersion'),
    ...validatedEPDVerifier('epdVerifier', verifiersDataSource),
    ...validatedSubObject('pcr'),
    ...validatedString('contactPerson'),
  });

export const step2ValidationSchema = Yup.object<Partial<EPDModel>>({
  // Unable to validate subtype since it's not matching the state and value
  // fields in case. Also as a string enum there is no simple way to validate
  // since string constraints will not accept a string enum.
  ...validatedDocuments('documents'),
  ...validatedString('text'),
  ...validatedString('gtin'),
  ...validatedString('products'),
  ...validatedArray('productImages'),
  ...validatedArray('lcAs'),
  ...validatedString('materialProperties'),
  ...validatedString('referenceFlows'),
  ...validatedNumber('referenceYear'),
  ...validatedArray('geographicalScopes'),
  ...validatedString('declaredStandards'),
  ...validatedString('geographicalDescription'),
  ...validatedString('technologyDescription'),
  ...validatedString('technicalPurpose'),
  ...validatedDataSourcesDictionary('epdDataSources'),
  ...validatedString('referencePackageVersion15804'),
  ...validatedString('useAdvice'),
  ...validatedSubtype('subtype'),
  ...validatedString('functionalUnitDescription'),
  ...validatedEnum('epdBasedOnUnit', EPDBasedOnUnitEnum),
});

export const step3ValidationSchema = Yup.object<Partial<EPDModel>>({
  ...validatedArray('verificationReports'),
  ...validatedArray('processCertificates'),
  ...validatedDate('approvalDate'),
});

export const step4ValidationSchema = (epdVersions?: EPDModel[]) =>
  Yup.object<Partial<EPDModel>>({
    ...validatedDate('registrationDate'),
    ...validatedPublicVersionDate('publicVersionDate', epdVersions),
    ...validatedDate('validUntil'),
  });
