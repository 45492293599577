import { CollectionsClientV2, FileParameter, SaveCollectionModel } from './EpdClient';

const client = new CollectionsClientV2(process.env.REACT_APP_API_URL);

class CollectionService {
  static getCollections = async (companyId?: string) => {
    return await client.getAll(companyId);
  };
  static getCollection = async (collectionId: string) => {
    return await client.get(collectionId);
  };
  static deleteCollection = async (collectionId: string) => {
    return await client.delete(collectionId);
  };
  static addCollectionFile = async (collectionId?: string, propertyName?: string, file?: FileParameter) => {
    return await client.addCollectionFile(collectionId, propertyName, file);
  };

  static saveCollection = async (collection: SaveCollectionModel) => {
    return await client.save(collection);
  };

  static publishCollection = async (id: string) => {
    return await client.publish(id);
  };

  static depublishCollection = async (id: string) => {
    return await client.depublish(id);
  };
}

export default CollectionService;
