import SmartLabel from 'components/v2/labels/SmartLabel';
import { ProcessStatus } from 'constants/constants';
import { useTranslation } from 'react-i18next';
import { PCRModel } from 'services/EpdClient';
import FileService from 'services/FileService';
import { A } from 'styles/Styles.styled';
import { RadioButtonStyled } from 'styles/v2/Styles.styled';
import { Option } from 'types/types';

import { DownloadButton, PcrListItem, PcrListItemHeader } from '../../../../pcr-selector/styled';
import ProductCategorySelector from './ProductCategorySelector';

interface TProps {
  productCategoryList: Option[];
  productCategory: Option | undefined;
  pcrList: PCRModel[];
  pcr: PCRModel | undefined;
  status: ProcessStatus;
  onProductCategorySelect: (value: Option | undefined) => void;
  onPcrSelect: any;
  disableCategorySelector: boolean;
}

const PcrSelectorDetails = ({
  productCategoryList,
  productCategory,
  pcrList,
  pcr,
  status,
  onProductCategorySelect,
  onPcrSelect,
  disableCategorySelector,
}: TProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SmartLabel label={'Category'} />
      {!disableCategorySelector ? (
        <ProductCategorySelector
          name={'productCategory'}
          options={productCategoryList}
          value={productCategory}
          isClearable
          onChange={(value: any) => {
            onProductCategorySelect(value);
          }}
          placeholder={'Type a category name for a quick search'}
        />
      ) : (
        <ProductCategorySelector
          name={'productCategory'}
          options={[]}
          value={{ label: 'Construction products', value: '' }}
          isClearable
          onChange={() => {}}
          isDisabled
        />
      )}

      <div style={{ margin: '0.5rem 0 1rem 0' }}>
        {status === ProcessStatus.Fetching && (
          <div style={{ padding: '1rem', fontStyle: 'italic' }}>{t('epdWizard.wizardStep1.pcrMenu.messages.fetchList')}</div>
        )}
        {status === ProcessStatus.Success &&
          (!!pcrList?.length ? (
            pcrList.map((item) => (
              <PcrListItem key={item.id} className={pcr?.id === item.id ? 'selected' : ''}>
                <PcrListItemHeader>
                  <span style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>{item.fullName}</span>
                  <RadioButtonStyled
                    inputId={item.id}
                    name="pcrItem"
                    checked={pcr?.id === item.id}
                    onChange={() => onPcrSelect(item)}
                  />
                </PcrListItemHeader>
                {item.cpCs && (
                  <div>
                    {item.cpCs.map((cpc) => (
                      <div style={{ padding: '0.5rem 0 0 0 ' }} key={cpc.id}>
                        <em>{cpc.code}</em> <span>{cpc.description}</span>
                      </div>
                    ))}
                  </div>
                )}
                {item.documents &&
                  item.documents?.length > 0 &&
                  item?.documents?.map(
                    (file, index) =>
                      file && (
                        <div key={index}>
                          <div style={{ padding: '0.5rem 0 0 0 ' }}>
                            <span>{file?.name || t('epdWizard.wizardStep1.pcrMenu.placeholderImage')}</span>
                          </div>
                          <A target="_blank" href={FileService.getImageUrl(file?.id || '')}>
                            <DownloadButton label="Download file" icon="pi pi-download" />
                          </A>
                        </div>
                      )
                  )}
              </PcrListItem>
            ))
          ) : (
            <div style={{ padding: '1rem', fontStyle: 'italic' }}>
              {'No items in this category or category is not selected'}
            </div>
          ))}
      </div>
    </>
  );
};

export default PcrSelectorDetails;
