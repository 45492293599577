import { editAdminSelectStyles } from 'components/form/EditAdminSelectStyles';
import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import { HelpBox } from 'components/v2/help-boxes';
import { toaster } from 'components/v2/toast';
import { Field, FieldArray, Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AdminPreverifiedToolsService from 'services/admin/AdminPreverifiedToolsService';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import { useDictionaryValues } from 'services/api/queries';
import styled from 'styled-components';
import {
  AdminBox,
  ButtonSmall,
  Container,
  EditFull,
  H2,
  InfoText,
  Label,
  LabelBox,
  StyledReactSelect,
} from 'styles/Styles.styled';
import { formatDate, toOptions } from 'util/utils';

import { AdminGridForm, ButtonsContainer } from '../users/style';
import { validationSchema } from './validation';

const EditTool: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const [preverifiedTool, setPreverifiedTool] = React.useState<any>(null);
  const dictionaryValuesQuery = useDictionaryValues(EPD_DICTIONARY_CODES.PRE_VERIFIED_TOOL_TYPE);
  const toolTypeLookup = dictionaryValuesQuery.data || [];

  useEffect(() => {
    const loadPreVerifiedTool = async () => {
      const result = await AdminPreverifiedToolsService.getTool(id);
      setPreverifiedTool(() => ({
        ...result,
        validityDate: formatDate(result.validityDate),
        approvalDate: formatDate(result.approvalDate!),
        toolTypeOption: toOptions(toolTypeLookup).find((x) => x.value == result.toolType?.toString()),
        preverifiedToolVersions: result?.preverifiedToolVersions?.map((i: any) => {
          return { ...i, approvalDate: new Date(i.approvalDate), validityDate: new Date(i.validityDate) };
        }),
      }));
    };

    if (toolTypeLookup?.length) loadPreVerifiedTool();
  }, [toolTypeLookup]);

  if (!preverifiedTool) {
    return <InfoText>Loading...</InfoText>;
  }

  const onSaveChanges = async (values: any) => {
    try {
      const newValues = {
        ...values,
        toolType: values.toolTypeOption.value,
        preverifiedToolVersions: values.preverifiedToolVersions.map((i: any) => {
          return { ...i, approvalDate: formatDate(i.approvalDate), validityDate: formatDate(i.validityDate) };
        }),
      };
      await AdminPreverifiedToolsService.updateTool(id, newValues);
      toaster({ severity: 'success', summary: 'Success', details: 'Information has been saved' });
    } catch (error) {}
  };

  const MyInput = ({ field, form, index, fieldName, ...rest }: any) => {
    const errors = form.errors.preverifiedToolVersions || [];
    const error = errors[index] ? errors[index][fieldName] : undefined;

    const touches = form.touched.preverifiedToolVersions || [];
    const touch = touches[index] ? touches[index][fieldName] : undefined;

    return (
      <div>
        <InputText {...field} {...rest} />
        {touch && error && <div className="p-error">{error}</div>}
      </div>
    );
  };

  const MyCalendar = ({ field, form, index, fieldName, ...rest }: any) => {
    const errors = form.errors.preverifiedToolVersions || [];
    const error = errors[index] ? errors[index][fieldName] : undefined;

    const touches = form.touched.preverifiedToolVersions || [];
    const touch = touches[index] ? touches[index][fieldName] : undefined;

    return (
      <div>
        <Calendar {...field} {...rest} dateFormat="mm/dd/yy" />
        {touch && error && <div className="p-error">{error}</div>}
      </div>
    );
  };

  const currentDate = new Date();
  const emptyVersion = {
    id: null,
    name: '',
    approvalDate: currentDate,
    validityDate: currentDate,
    verifierName: '',
  };

  return (
    <Container>
      <EditFull>
        <H2>Preverified tool details</H2>
        <HelpBox>
          {'Do not forget to press save button in case of any changes after you finished data modifications.'}
        </HelpBox>
        <AdminBox style={{ backgroundColor: '#FDFDFD' }}>
          <Formik
            enableReinitialize
            initialValues={preverifiedTool}
            onSubmit={onSaveChanges}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              <AdminGridForm id="editPreverifiedToolsForm" autoComplete="off">
                <LabelBox>
                  <FixedLabel>Name</FixedLabel>
                </LabelBox>
                <ValueBox>
                  <AdminTextInput name="name" type="text" />
                </ValueBox>
                <LabelBox>
                  <FixedLabel>Verifier</FixedLabel>
                </LabelBox>
                <ValueBox>
                  <AdminTextInput name="verifierName" type="text" />
                </ValueBox>
                <LabelBox>
                  <FixedLabel>Approval date</FixedLabel>
                </LabelBox>
                <ValueBox>
                  <AdminTextInput name="approvalDate" type="date" />
                </ValueBox>
                <LabelBox>
                  <FixedLabel>Validity date</FixedLabel>
                </LabelBox>
                <ValueBox>
                  <AdminTextInput name="validityDate" type="date" />
                </ValueBox>
                <LabelBox>
                  <FixedLabel>Website</FixedLabel>
                </LabelBox>
                <ValueBox>
                  <AdminTextInput name="website" type="text" />
                </ValueBox>
                <LabelBox>
                  <FixedLabel>Tool type</FixedLabel>{' '}
                </LabelBox>
                <ValueBox>
                  <StyledReactSelect
                    name="toolTypeOption"
                    styles={editAdminSelectStyles}
                    options={toOptions(toolTypeLookup)}
                  />
                </ValueBox>

                <FieldArray name="preverifiedToolVersions">
                  {(arrayHelpers) => (
                    <div className="w-full" style={{ gridColumn: '1/6' }}>
                      <hr />
                      <VersionsHeader>
                        <h2>Versions</h2>
                        <ButtonSmall
                          type="button"
                          onClick={() => {
                            arrayHelpers.insert(0, emptyVersion);
                          }}
                        >
                          Add Version
                        </ButtonSmall>
                      </VersionsHeader>
                      {!(values?.preverifiedToolVersions?.length > 0) && 'No versions yet'}
                      {values.preverifiedToolVersions?.map((_: any, index: any) => (
                        <div
                          className="w-full"
                          key={index}
                          style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}
                        >
                          <Field name={`preverifiedToolVersions[${index}].id`} style={{ display: 'none' }} />

                          <FieldGroup>
                            {index === 0 && <Label>Version name</Label>}
                            <Field
                              name={`preverifiedToolVersions[${index}].name`}
                              render={(props: any) => <MyInput {...props} fieldName="name" index={index} />}
                            />
                          </FieldGroup>
                          <FieldGroup>
                            {index === 0 && <Label>Verifier name</Label>}
                            <Field name={`preverifiedToolVersions[${index}].verifierName`}>
                              {(props: any) => <MyInput {...props} fieldName="verifierName" index={index} />}
                            </Field>
                          </FieldGroup>
                          <FieldGroup>
                            {index === 0 && <Label>Approval date</Label>}
                            <Field name={`preverifiedToolVersions[${index}].approvalDate`}>
                              {(props: any) => <MyCalendar {...props} fieldName="approvalDate" index={index} />}
                            </Field>
                          </FieldGroup>
                          <FieldGroup>
                            {index === 0 && <Label>Validity date</Label>}
                            <Field name={`preverifiedToolVersions[${index}].validityDate`}>
                              {(props: any) => <MyCalendar {...props} fieldName="validityDate" index={index} />}
                            </Field>
                          </FieldGroup>
                          <FieldGroup>
                            {index === 0 && <Label>&nbsp;</Label>}
                            <ButtonSmall
                              style={{ alignSelf: 'center', padding: '5px' }}
                              type="button"
                              onClick={() => {
                                arrayHelpers.remove(index);
                                toaster({
                                  severity: 'warning',
                                  summary: 'Save your changes',
                                  details:
                                    "Don't forget to press save to finalyze the operation. Now the item has been deleted only on UI and you can proceed making changes.",
                                });
                              }}
                            >
                              Remove
                            </ButtonSmall>
                          </FieldGroup>
                        </div>
                      ))}
                    </div>
                  )}
                </FieldArray>
                <ButtonsContainer>
                  <ButtonSmall type="submit">{'Save'}</ButtonSmall>
                </ButtonsContainer>
              </AdminGridForm>
            )}
          </Formik>
        </AdminBox>
      </EditFull>
    </Container>
  );
};

const AdminTextInput = styled(LargeRadiusTextInput)`
  box-sizing: border-box;
  width: 100%;
  grid-column-start: 2;
  grid-column-end: 3;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 5;
  }
`;

const FixedLabel = styled(Label)`
  flex: 0 0 200px;
  align-self: start;
  margin: 0.5rem 1rem 0.5rem 0; /*risky change? */
  padding-top: 10%;

  > svg {
    margin: 0 0.5rem;
  }

  > div + svg {
    margin: 0;
  }
`;

const ValueBox = styled.div`
  min-height: 38px;
  grid-column-start: 2;
  grid-column-end: 3;
  align-self: center;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 6;
  }
`;

const VersionsHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export default EditTool;
