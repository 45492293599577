import React from 'react';
import WizardInputSkeleton from 'components/v2/epd-wizard-inputs/WizardInputSkeleton';
import { DoubleFieldPanel, FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Skeleton } from 'primereact/skeleton';

const SkeletonAdditionalInformationTab: React.FC<{}> = () => {
  return (
    <>
      <FieldPanelFullWidth style={{ rowGap: 0 }}>
        <Skeleton height="54px" />
        <DoubleFieldPanel className="mt-5">
          <WizardInputSkeleton />
          <WizardInputSkeleton />
        </DoubleFieldPanel>
        <Skeleton width="8rem" className="mt-2" />
      </FieldPanelFullWidth>
      <FieldPanelFullWidth>
        {Array(4)
          .fill(0)
          .map((_, i) => (
            <div key={i}>
              <Skeleton width="20rem" />
              <Skeleton height="106px" className="mt-2" />
            </div>
          ))}
      </FieldPanelFullWidth>
    </>
  );
};

export default SkeletonAdditionalInformationTab;
