import React from 'react';
import { VersionHistorySummaryGridSkeleton } from '../VersionHistorySummaryGrid';
import { useEpdVersionHistoryDataOrWhileLoadingThenNull } from '../utils/EpdVersionHistoryDataContext';
import VersionHistorySection from '../VersionHistorySection';
import VersionHistoryScenarioGrid from './VersionHistoryScenarioGrid';
import ModuleGridPdf from '../../grids/scenarios/ModuleGridPdf';

interface ScenarioModuleItem {
  moduleId: string;
  dataGridCaption: string;
  dataGridModuleName: string;
  descriptionLabel: string;
}

const VersionHistoryScenariosSection: React.FC<{}> = () => {
  const data = useEpdVersionHistoryDataOrWhileLoadingThenNull();

  return (
    <VersionHistorySection title="Scenarios">
      {data?.scenarios?.map((scenario) => {
        const possibleModules: (ScenarioModuleItem | false | null | undefined)[] = [
          data.systemBoundaryGrid.declaredModules?.a4 && {
            moduleId: 'A4',
            dataGridCaption: 'Module A4: Transport to the building site',
            dataGridModuleName: 'Module A4 information',
            descriptionLabel: 'Module A4',
          },
          data.systemBoundaryGrid.declaredModules?.a5 && {
            moduleId: 'A5',
            dataGridCaption: 'Module A5: Installation in the building',
            dataGridModuleName: 'Module A5 information',
            descriptionLabel: 'Module A5',
          },
          data.systemBoundaryGrid.declaredModules?.b1 && {
            moduleId: 'B1',
            dataGridCaption: 'Module B1: Use',
            dataGridModuleName: 'Module B1 information',
            descriptionLabel: 'Module B1',
          },
          data.systemBoundaryGrid.declaredModules?.b2 && {
            moduleId: 'B2',
            dataGridCaption: 'Module B2: Maintenance',
            dataGridModuleName: 'Module B2 information',
            descriptionLabel: 'Module B2',
          },
          data.systemBoundaryGrid.declaredModules?.b3 && {
            moduleId: 'B3',
            dataGridCaption: 'Module B3: Repair',
            dataGridModuleName: 'Module B3 information',
            descriptionLabel: 'Module B3',
          },
          data.systemBoundaryGrid.declaredModules?.b4 && {
            moduleId: 'B4',
            dataGridCaption: 'Module B4: Replacement',
            dataGridModuleName: 'Module B4 information',
            descriptionLabel: 'Module B4',
          },
          data.systemBoundaryGrid.declaredModules?.b5 && {
            moduleId: 'B5',
            dataGridCaption: 'Module B5: Refurbishment',
            dataGridModuleName: 'Module B5 information',
            descriptionLabel: 'Module B5',
          },
          data.systemBoundaryGrid.declaredModules?.b6 && {
            moduleId: 'B6',
            dataGridCaption: 'Module B6: Operational energy use',
            dataGridModuleName: 'Module B6 information',
            descriptionLabel: 'Module B6',
          },
          data.systemBoundaryGrid.declaredModules?.b7 && {
            moduleId: 'B7',
            dataGridCaption: 'Module B7: Operational water use',
            dataGridModuleName: 'Module B7 information',
            descriptionLabel: 'Module B7',
          },
          (data.systemBoundaryGrid.declaredModules?.c1 ||
            data.systemBoundaryGrid.declaredModules?.c2 ||
            data.systemBoundaryGrid.declaredModules?.c3 ||
            data.systemBoundaryGrid.declaredModules?.c4) && {
            moduleId: 'C1',
            dataGridCaption: 'Module C: End-of-life',
            dataGridModuleName: 'Module C information',
            descriptionLabel: 'Module C',
          },
          scenario.enabledRSL && {
            moduleId: 'RSL',
            dataGridCaption: 'Reference service life',
            dataGridModuleName: 'Reference service life information',
            descriptionLabel: 'Reference service life',
          },
          data.systemBoundaryGrid.declaredModules?.d && {
            moduleId: 'D',
            dataGridCaption: 'Module D: Beyond product life cycle',
            dataGridModuleName: 'Module D information',
            descriptionLabel: 'Module D',
          },
        ];
        const modules = possibleModules.filter((m) => m) as ScenarioModuleItem[];

        return (
          <React.Fragment key={scenario.id}>
            <VersionHistoryScenarioGrid scenario={scenario} />

            {modules.map((m) => (
              <ModuleGridPdf
                key={m.moduleId}
                epdVersionId={data.epdVersionId}
                scenarioId={scenario.id}
                module={m.moduleId}
                gridCaption={m.dataGridCaption}
                moduleName={m.dataGridModuleName}
                isDefaultScenario={scenario.isDefaultScenario}
              />
            ))}
          </React.Fragment>
        );
      }) ?? (
        <>
          <VersionHistorySummaryGridSkeleton skeletonRowsCount={7} />
        </>
      )}
    </VersionHistorySection>
  );
};

export default VersionHistoryScenariosSection;
