import React from 'react';
import { useGetEpdScrapInputs, useGetEpdShareOfTotalScrapInput } from 'services/api/queries';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';
import { ColumnFooterInputNumber, DataTableStyled } from '../StyledGrid';

const ContributionOfScrapInputsGridPdf: React.FunctionComponent<{ epdVersionId: string }> = ({ epdVersionId }) => {
  const shareOfTotalScrapInput = useGetEpdShareOfTotalScrapInput(epdVersionId!).data;

  const scrapInputs = useGetEpdScrapInputs(epdVersionId!).data;

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">Scrap inputs data</HeaderCaptionSemiBold>
    </div>
  );

  const footerNumberEditor = () => (
    <ColumnFooterInputNumber
      value={shareOfTotalScrapInput?.sharePercent}
      suffix=" %"
      mode="decimal"
      step={0.01}
      maxFractionDigits={2}
      disabled
    />
  );

  const footerGroup = (
    <ColumnGroup>
      <Row style={{ borderTop: 'none' }}>
        <Column
          footer={<div>The share of the total scrap input that was assumed to come with an environmental burden</div>}
          className="p-row-note"
        />
        <Column footer={footerNumberEditor} colSpan={4} className="p-row-note" />
      </Row>
    </ColumnGroup>
  );

  const formatScrapInputValue = (value: number | undefined) => (!!value ? `${value}, kg CO2 eq./tonne` : null);

  return (
    <DataTableStyled dataKey="id" header={header} footerColumnGroup={footerGroup} value={scrapInputs} showGridlines>
      <Column field="name" header="Material scrap name" />
      <Column field="value" header="Material scrap value" body={(rowData) => formatScrapInputValue(rowData.value)} />
    </DataTableStyled>
  );
};

export default ContributionOfScrapInputsGridPdf;
