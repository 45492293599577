import { EpdLinks } from 'routes/EpdRoutes';
import { EPDCopyGroupStatus, EpdGroupDashboardModel } from 'services/EpdClient';
import { EPDGroupCardDashboardStatus } from 'styles/v2/Styles.styled';
import { formatDate } from 'util/utils';

import { CardName, CardSmallText, CopyGroupCard } from './styles';

type TProps = {
  epdGroup: EpdGroupDashboardModel;
  background: any;
};

const EpdGroupCard = ({ epdGroup, background }: TProps) => {
  const groupStatus =
    epdGroup.status === EPDCopyGroupStatus.ApprovedByLicenseeAdmin
      ? 'Sent for publication'
      : epdGroup.status === EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin
      ? 'Declined publication'
      : epdGroup.status;
  return (
    <CopyGroupCard to={EpdLinks.editCopyEpd(epdGroup.id)} background={background}>
      <CardName>{epdGroup.name}</CardName>
      <div>
        <CardSmallText>Copy group</CardSmallText>
        <CardSmallText>Created: {formatDate(epdGroup?.createdAt)}</CardSmallText>
        <EPDGroupCardDashboardStatus status={epdGroup.status}>{groupStatus}</EPDGroupCardDashboardStatus>
      </div>
    </CopyGroupCard>
  );
};

export default EpdGroupCard;
