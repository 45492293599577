import { Skeleton } from 'primereact/skeleton';
import { DataTableStyled } from '../grids/StyledGrid';
import { Column } from 'primereact/column';

export interface SummaryGridRow {
  name: string;
  value: string;
  isHtmlRichText?: boolean;
}

const NAME_COLUMN_WIDTH = 250;

const VersionHistorySummaryGrid: React.FC<{ rows: SummaryGridRow[] }> = ({ rows }) => {
  const cellBody = (rowData: SummaryGridRow) => {
    if (rowData.isHtmlRichText) {
      return <span dangerouslySetInnerHTML={{ __html: rowData.value }} />;
    }
    return rowData.value;
  };
  return (
    <DataTableStyled dataKey="name" value={rows} showHeaders={false} showGridlines>
      <Column field="name" style={{ width: NAME_COLUMN_WIDTH }} />
      <Column field="value" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }} body={cellBody} />
    </DataTableStyled>
  );
};
export default VersionHistorySummaryGrid;

export const VersionHistorySummaryGridSkeleton: React.FC<{ skeletonRowsCount: number }> = ({ skeletonRowsCount }) => {
  const cellBody = <Skeleton />;
  const dummyRows = Array.from(Array(skeletonRowsCount)).map((_, i) => ({ key: i }));
  return (
    <DataTableStyled dataKey="key" value={dummyRows} showHeaders={false} showGridlines>
      <Column field="key" style={{ width: NAME_COLUMN_WIDTH }} body={cellBody} />
      <Column field="value" body={cellBody} />
    </DataTableStyled>
  );
};
