import { FunctionComponent, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProcessStatus, Role } from 'constants/constants';
import { CreateInvitationModel } from 'services/EpdClient';
import { useTranslation } from 'react-i18next';
import { PoorMansError } from 'util/utils';
import { Formik } from 'formik';
import { SuccessText, ErrorText } from 'styles/Styles.styled';
import { toaster } from 'components/v2/toast';
import MembershipService from 'services/MembershipService';
import * as Yup from 'yup';
import styled from 'styled-components';

interface TProps {
  companyId: string | undefined;
}

const InviteExternalPractitionerTextInput: FunctionComponent<TProps> = ({ companyId }) => {
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [serverError, setServerError] = useState<string>();

  const { t } = useTranslation();

  const initialValues: CreateInvitationModel = {
    invitedUserEmail: '',
    companyId,
    roleId: Role.EPDDeveloper,
    message: '',
    asGuest: true,
  };

  const inviteSchema = Yup.object({
    invitedUserEmail: Yup.string().email(t('messages.invalidEmail')).required(t('messages.required')),
  });

  const handleInviteUser = async (values: CreateInvitationModel) => {
    setServerError('');
    try {
      setStatus(ProcessStatus.Fetching);
      await MembershipService.createInvitation(values);
      toaster({
        severity: 'success',
        summary: 'An invitation email has been sent to the user.',
      });
      setStatus(ProcessStatus.Success);
    } catch (ex) {
      setServerError(PoorMansError(ex));
      setStatus(ProcessStatus.Error);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={inviteSchema}
        onSubmit={(values) => handleInviteUser(values)}
        validateOnChange={false}
      >
        {({ values, errors, setFieldValue, handleSubmit }) => (
          <>
            <InputGroupStyled className="p-inputgroup" disabled={false}>
              <InputTextStyled
                name="invitedUserEmail"
                type="email"
                placeholder="email@example.com"
                onChange={(e: any) => setFieldValue('invitedUserEmail', e.target.value)}
              />
              <InputAddon className="p-inputgroup-addon">
                <StyledInvitePractitionerButton
                  type="submit"
                  label="Send"
                  onClick={() => handleSubmit()}
                  disabled={!values.invitedUserEmail}
                />
              </InputAddon>
            </InputGroupStyled>
            <ErrorText>{errors.invitedUserEmail}</ErrorText>
            {status === ProcessStatus.Success && <SuccessText>{t('manageMembers.messages.successInvitation')}</SuccessText>}
          </>
        )}
      </Formik>
      {status === ProcessStatus.Error && (
        <ErrorText>{serverError ? serverError : t('manageMembers.errors.wrong')}</ErrorText>
      )}
    </>
  );
};

const StyledInvitePractitionerButton = styled(Button)`
  height: 1.5rem;
  min-height: 22px;
  background-color: transparent !important;
  color: ${(props) => props.theme.colors.primaryOrange};
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;
  border: none;
  padding: 0px;

  &&:hover {
    color: ${(props) => props.theme.colors.elementsButtonHover};
  }

  &:disabled {
    color: ${(props) => props.theme.colors.elementsButtonDisabled};
  }

  ${(props) => props.theme.typography.buttonButtonDefault};
`;

const InputTextStyled = styled(InputText)`
  padding: 1rem;
  border: 1px solid
    ${(props) => (props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.elementsInputBorder)};
  border-radius: 2px;
  margin: 0;
  background-color: ${(props) => props.theme.colors.primaryWhite};

  &.p-component.p-disabled {
    background-color: ${(props) => props.theme.colors.elementsFilledInput};
  }

  &:focus {
    border-color: ${(props) => (props.disabled ? props.theme.colors.elementsInputBorder : props.theme.colors.primaryOrange)};
  }

  &:focus-visible {
    outline: 2px solid
      ${(props) => (props.disabled ? props.theme.colors.elementsInputBorder : props.theme.colors.primaryOrange)};
  }

  &:hover {
    border-color: ${(props) => (props.disabled ? props.theme.colors.elementsInputBorder : props.theme.colors.primaryOrange)};
  }

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 44px;
`;

const InputGroupStyled = styled.div<{ disabled: boolean | undefined }>`
  width: auto;
  border: 1px solid ${(props) => (props.disabled ? 'transparent' : props.theme.colors.elementsInputBorder)};
  &:disabled {
    border: none;
  }
  &:hover {
    border-color: ${(props) => (props.disabled ? 'transparent' : props.theme.colors.primaryOrange)};
  }
  .p-inputgroup-addon:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  ${InputTextStyled} {
    border: none;
    cursor: auto;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
`;

const InputAddon = styled.span<{ disabled?: boolean | undefined }>`
  max-width: 60px;
  border: none;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.primaryWhite};
  cursor: auto;
  padding: 0.5rem 1rem 0.5rem 0rem;
  min-width: 0;
  .pi {
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 700;
  }
`;

export default InviteExternalPractitionerTextInput;
