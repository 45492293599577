import ErrorFallback from 'components/error-fallback/ErrorFallback';
import OverlaySpinner from 'components/spinners/OverlaySpinner';
import TitleAndBack from 'components/v2/TitleAndBack';
import CompilerToolButtons from 'components/v2/compiler/CompilerToolButtons';
import StepPanel from 'components/v2/compiler/StepPanel';
import useCompilerLogic from 'components/v2/compiler/useCompilerLogic';
import { confirmQuestion } from 'components/v2/confirm-dialog/ConfirmQuestion';
import { ProcessStatus } from 'constants/constants';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Skeleton } from 'primereact/skeleton';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { EpdLinks } from 'routes/EpdRoutes';
import { useEPDDefinitionInformation } from 'services/api/queries';
import styled from 'styled-components';
import { Container } from 'styles/Styles.styled';

import StatusBox from '../../../components/v2/compiler/StatusBox';
import { ErrorTextBox, WizardHeaderButtonBox, WizardHeaderGrid } from '../../../styles/v2/Styles.styled';
import { withGreyBackground } from '../WithGreyBackground';

const EpdCompiler: React.FunctionComponent = () => {
  const [actionStatus, setActionStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const { epdId, epdVersionId, history, redirect, stepId, steps, compilerCompositeStatus } = useCompilerLogic();
  const actionsStates = compilerCompositeStatus?.actionsStatus;

  const epdDefinition = useEPDDefinitionInformation(epdVersionId!).data;

  const onGoBack = () => {
    confirmQuestion({
      caption: 'Do you want to leave the editing process?',
      description: (
        <div>
          All entered data will be saved.
          <p style={{ marginTop: '0.5rem' }}>You can access your EPD from the dashboard.</p>
        </div>
      ),
      acceptLabel: 'Leave',
      rejectLabel: 'Cancel',
      onConfirm: () => {
        history.push(EpdLinks.dashboard());
      },
      closable: false,
      contentStyle: { paddingTop: '2.5rem' },
    });
  };

  if (redirect) {
    return redirect;
  }

  return (
    <Container>
      <ConfirmDialog />
      {actionStatus === ProcessStatus.Fetching && <OverlaySpinner />}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <WizardHeaderGrid>
          <TitleAndBack
            title={epdDefinition !== undefined ? epdDefinition.declarationName : <Skeleton width="311px" height="34px" />}
            backTo={'Back to the dashboard'}
            onBack={onGoBack}
          />
          <ToolButtonsContainer>
            {epdVersionId && epdDefinition && actionsStates && (
              <CompilerToolButtons
                epdId={epdId}
                epdVersionId={epdVersionId}
                epdDefinition={epdDefinition}
                actionsStates={actionsStates}
                onActionStatusChange={setActionStatus}
              />
            )}
          </ToolButtonsContainer>
        </WizardHeaderGrid>

        <WizardContentsGrid>
          <div>
            <StatusBox
              statusDisplayName={actionsStates?.statusDisplayName}
              statusMessageCode={actionsStates?.statusMessageCode}
            />

            <StepsPanelContainer>
              {steps.map((step, index) => (
                <StepPanel
                  key={index}
                  path={step.path}
                  label={step.label}
                  name={step.name}
                  description={step.description}
                  isSelected={stepId === step.id}
                  isValid={step.isValid(compilerCompositeStatus?.errors)}
                />
              ))}
            </StepsPanelContainer>
          </div>
          {/* HACK: The key attribute is supposed to refresh text inputs in uncontrolled components.
                    But it also causes an annoying state reset when the page finishes loading.
          */}
          <MainViewFull key={!!epdDefinition ? 'ready' : 'not ready'}>
            {steps.find((s) => s.id === stepId)?.render()}
          </MainViewFull>
        </WizardContentsGrid>
      </ErrorBoundary>
    </Container>
  );
};

const ToolButtonsContainer = styled(WizardHeaderButtonBox)`
  min-height: 48px;
`;

const StepsPanelContainer = styled.div`
  padding: 0;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);
`;

const WizardContentsGrid = styled.div`
  display: grid;
  width: 100%;
  margin-bottom: 40px;
  align-items: start;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  border: 2 solid green;

  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 3fr 10fr;
  }
`;

const MainViewFull = styled.div`
  flex: 0 0 100%;

  ${ErrorTextBox} {
    word-break: break-word;
  }
`;

export default withGreyBackground(EpdCompiler);
