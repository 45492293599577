import React from 'react';
import WizardInputSkeleton from 'components/v2/epd-wizard-inputs/WizardInputSkeleton';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';

const SkeletonStep5: React.FC<{}> = () => {
  return (
    <>
      <FieldPanelFullWidth style={{ marginTop: 0 }}>
        <WizardInputSkeleton />
      </FieldPanelFullWidth>
      <FieldPanelFullWidth>
        <WizardInputSkeleton />
      </FieldPanelFullWidth>
      <FieldPanelFullWidth>
        {Array(6)
          .fill(0)
          .map((_, i) => (
            <WizardInputSkeleton key={i} />
          ))}
      </FieldPanelFullWidth>
    </>
  );
};

export default SkeletonStep5;
