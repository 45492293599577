import styled, { keyframes } from 'styled-components';

const ellipsis = keyframes`
  to {
    width: 15px;
  }
`;

// Credit: https://stackoverflow.com/a/28074607
const TextWithAnimatedEllipsis = styled.div`
  ${(props) => props.theme.typography.bodyInput};
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ${ellipsis} steps(4, end) 900ms infinite;
    content: '...';
    width: 0px;
  }
`;
export default TextWithAnimatedEllipsis;
