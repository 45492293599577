import React from 'react';
import WizardInputSkeleton from 'components/v2/epd-wizard-inputs/WizardInputSkeleton';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Skeleton } from 'primereact/skeleton';

const SkeletonProductInformationTab: React.FC<{}> = () => {
  return (
    <FieldPanelFullWidth>
      <Skeleton width="10rem" />
      <WizardInputSkeleton />
      {Array(6)
        .fill(0)
        .map((_, i) => (
          <div key={i}>
            <Skeleton width="20rem" />
            <Skeleton height="67px" className="mt-2" />
          </div>
        ))}
    </FieldPanelFullWidth>
  );
};

export default SkeletonProductInformationTab;
