import React, { ChangeEvent } from 'react';
import FileUpload from 'components/v2/file-upload/FileUpload';
import { HelpBox } from 'components/v2/help-boxes';
import { HyperLink } from 'styles/Styles.styled';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { ButtonUploadIcon } from 'components/v2/icons';
import { EPD_LCA_TEMPLATES_URL } from 'constants/constants';
import { useImportLcaResultsFile } from 'services/api/mutations';
import TextWithAnimatedEllipsis from 'components/v2/file-upload/TextWithAnimatedEllipsis';
import { confirmAlert } from 'components/v2/confirm-dialog/ConfirmAlert';
import { toaster } from 'components/v2/toast';

interface Props {
  epdVersionId: string;
  hasSomeResultsAlready: boolean;
}

const ImportLcaResults: React.FC<Props> = ({ epdVersionId, hasSomeResultsAlready }) => {
  const importLcaResultsFileMutation = useImportLcaResultsFile(epdVersionId);

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file = target && target.files && target.files[0];
    if (!file) {
      return null;
    }

    if (hasSomeResultsAlready) {
      const agreeToContinue = await new Promise((resolve) => {
        confirmAlert({
          caption: 'Replacing LCA results',
          description:
            'Uploading a new file will replace the current data. Any unsaved changes will be lost. Do you want to proceed?',
          onConfirm: () => resolve(true),
          onReject: () => resolve(false),
          acceptLabel: 'Confirm',
        });
      });
      if (!agreeToContinue) {
        return;
      }
    }

    const formData = new FormData();
    formData.append('File', file, file.name);

    try {
      await importLcaResultsFileMutation.mutateAsync(formData);

      toaster({ severity: 'success', summary: 'Imported LCA results', details: 'LCA results are successfully imported.' });
    } catch (ex: any) {
      const errorCode = ex?.response?.data;
      toaster({
        severity: 'error',
        summary: 'Error',
        details:
          errorCode === 'invalid-template'
            ? 'The file couldn’t be processed. Make sure it’s using the right template and try uploading it again.'
            : 'Something went wrong while processing your file. Please try again or reach out to support if the problem continues.',
      });
    }
  };

  return (
    <FieldPanelFullWidth style={{ gridRowGap: 16 }}>
      <div>
        <HelpBox>
          <span>
            The LCA results can be either directly added manually to the tables below or autofilled based upon uploaded LCA
            results document in the excel format. The excel template can be found on{' '}
            <HyperLink target="_blank" rel="noopener noreferrer" href={EPD_LCA_TEMPLATES_URL}>
              environdec.com
            </HyperLink>
          </span>
        </HelpBox>
        <FileUpload
          label="Import LCA results"
          icon={ButtonUploadIcon}
          accept=".xlsx"
          validExtensions={['xlsx']}
          name="LCA"
          onFileUpload={uploadFile}
          disabled={importLcaResultsFileMutation.isPending}
        />
      </div>
      {importLcaResultsFileMutation.isPending && <TextWithAnimatedEllipsis>Uploading file</TextWithAnimatedEllipsis>}
    </FieldPanelFullWidth>
  );
};

export default ImportLcaResults;
