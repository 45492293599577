import { useEPDDefinitionInformation, useEpdSystemBoundaryGrid } from 'services/api/queries';
import { EPDProductType } from 'types/types';
import { SINGLE_PRODUCT_ID } from '../constants';
import { LcaGridNote } from '../grids/GridService';

interface LcaGridHelperResult {
  generalDisclaimerNote: LcaGridNote | null;
  showVariationColumn: boolean;
}

export default function useLcaGridHelper(epdVersionId: string): LcaGridHelperResult {
  const epdDefinitionInformation = useEPDDefinitionInformation(epdVersionId).data;
  const systemBoundaryGridData = useEpdSystemBoundaryGrid(epdVersionId).data;
  const modules = systemBoundaryGridData?.declaredModules;

  let generalDisclaimerNote: LcaGridNote | null = null;
  const declaresAnyModuleC = !!modules && !!(modules.c1 || modules.c2 || modules.c3 || modules.c4);
  if (declaresAnyModuleC) {
    generalDisclaimerNote = {
      note: 'General disclaimer',
      description:
        (epdDefinitionInformation?.productType ?? EPDProductType.Goods) === EPDProductType.Goods
          ? 'It is discouraged to use the results of modules A1-A3 without considering the results of module C.'
          : 'It is discouraged to use the results of modules A1-A5 without considering the results of module C.',
    };
  }

  return {
    generalDisclaimerNote,
    showVariationColumn: (epdDefinitionInformation?.epdClassification ?? SINGLE_PRODUCT_ID) !== SINGLE_PRODUCT_ID,
  };
}
