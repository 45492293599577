import React from 'react';
import ConfirmWithCommentDialog, { ConfirmWithCommentPayload } from './ConfirmWithCommentDialog';
import { HelpBox, HelpBoxHeader } from '../help-boxes';

type Props = {
  isOpened: boolean;
  onClose(payload: ConfirmWithCommentPayload | null): void;
};

const ConfirmEditorialSendToPublishDialog: React.FC<Props> = ({ isOpened, onClose }) => {
  return (
    <ConfirmWithCommentDialog
      header="Send to publish"
      help={
        <HelpBox>
          <HelpBoxHeader>This EPD was approved by the verifier.</HelpBoxHeader>
          <span>
            You are allowed to make only the editorial changes to the verified EPD, such as correction of spelling errors
            without verification.
          </span>
          <span>Please specify which editorial changes were made to this EPD.</span>
        </HelpBox>
      }
      inputLabelText="Editorial changes description"
      primaryButtonText="Submit"
      isOpened={isOpened}
      onClose={onClose}
      isCommentRequired
    />
  );
};

export default ConfirmEditorialSendToPublishDialog;
