import { AddCollectionEPDModel, CollectionsClient } from './EpdClient';

const client = new CollectionsClient(process.env.REACT_APP_API_URL);

class CollectionService {
  static getCollections = async (companyId?: string) => {
    return await client.getAll(companyId);
  };
  static getAllEPDs = (companyId: string, accountId: string) => {
    return client.getAllEPDs(companyId, accountId);
  };
  static deleteCollection = async (collectionId: string) => {
    return await client.delete(collectionId);
  };
  static addCollectionEPD = async (collectionId: string, epdId: string) => {
    const collection: AddCollectionEPDModel = {
      collectionId: collectionId,
      propertyName: 'EPD',
      newValue: epdId,
    };
    return await client.addCollectionEPD(collection);
  };
  static removeCollectionEpd = async (collectionId: string, epdId: string) => {
    return await client.removeCollectionEPD(collectionId, epdId);
  };
}

export default CollectionService;
