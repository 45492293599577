import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import React, { useEffect, useState } from 'react';
import { PCRModel } from 'services/EpdClient';
import PcrService from 'services/PcrService';
import { useUpdateEpdPCRInformation } from 'services/api/mutations';
import { FieldPanelFullWidth, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { FieldPanelInput } from 'styles/v2/Styles.styled';
import CPcrSelector from './c-pcr-selector/CPcrSelector';
import PcrSelector from './pcr-selector/PcrSelector';
import { PCRInformationModel } from 'types/types';

const PcrInformationPanel: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  pcrInformation: PCRInformationModel;
  validationState: any;
}> = ({ epdId, epdVersionId, pcrInformation, validationState }) => {
  const updateMutation = useUpdateEpdPCRInformation(epdVersionId);

  const onChange = (propertyName: string, newValue: any) => {
    updateMutation.mutate({
      requestData: { epdId, versionId: epdVersionId, propertyName, newValue },
    });
  };

  const onChangePcr = (propertyName: 'PCR' | 'CPCR', newPCR: PCRModel | undefined) => {
    updateMutation.mutate({
      requestData: { epdId, versionId: epdVersionId, propertyName, newValue: newPCR ? newPCR.id : newPCR },
      newOptimisticValue: newPCR,
    });
  };

  const [cpcrList, setCPcrList] = useState<PCRModel[]>([]);

  const getCPcrList = async () => {
    if (!pcrInformation?.pcr?.productCategories?.length) return;
    const selectedCategory = pcrInformation.pcr.productCategories[0];
    const res = await PcrService.findPcr('c-pcr', undefined, selectedCategory.id, false, 'compiler');
    setCPcrList(res);
  };

  useEffect(() => {
    if (!pcrInformation?.pcr) {
      setCPcrList([]);
      return;
    }
    getCPcrList();
  }, [pcrInformation?.pcr]);

  const handleChangePcr = async (pcr: PCRModel | undefined) => {
    if (!pcr?.id) return;
    onChangePcr('PCR', pcr);
  };

  const handleRemovePcr = async () => {
    onChangePcr('PCR', undefined);
    onChangePcr('CPCR', undefined);
  };

  const handleChangeCPcr = async (cpcr: PCRModel | undefined) => {
    if (!cpcr?.id) return;
    onChangePcr('CPCR', cpcr);
  };

  const handleRemoveCPcr = async () => {
    onChangePcr('CPCR', undefined);
  };

  const selectedCpcrCategory = pcrInformation.cpcrProductCategory
    ? {
        value: pcrInformation.cpcrProductCategory.id,
        label: pcrInformation.cpcrProductCategory.title,
      }
    : null;

  const cpcrCategoryOptions = pcrInformation.cpcr?.cpcrProductCategories?.map((x) => {
    return {
      value: x.id,
      label: x.title,
    };
  });

  const showCpcrSelector = !!pcrInformation.cpcr || !!cpcrList.length;

  const { errors, fieldsState } = validationState;

  return (
    <FieldPanelFullWidth>
      <FieldPanelHeader>Pcr Information</FieldPanelHeader>
      <FieldPanelInput>
        <PcrSelector
          label={'PCR'}
          tooltip={'Product category rules.'}
          tooltipPosition={'top | right'}
          pcr={pcrInformation.pcr}
          onChangePcr={handleChangePcr}
          onRemovePcr={handleRemovePcr}
          error={errors.pcr}
          required
          disabled={false}
        />
      </FieldPanelInput>
      {showCpcrSelector && (
        <FieldPanelInput>
          <CPcrSelector
            label={'C-PCR'}
            tooltip={'Complementary product category rules.'}
            tooltipPosition={'top | right'}
            cpcr={pcrInformation.cpcr}
            cpcrList={cpcrList}
            onChangeCPcr={handleChangeCPcr}
            onRemoveCPcr={handleRemoveCPcr}
            required={false}
            disabled={false}
          />
        </FieldPanelInput>
      )}
      {!!pcrInformation.cpcr && (
        <CompilerSelectControlledComponent
          label="Product category covered in the c-PCR"
          tooltip="If possible, choose a fitting product category for your product(s)."
          name="cpcrProductCategory"
          singleValueColor={!selectedCpcrCategory ? '#757575' : 'hsl(0, 0%, 20%)'}
          options={cpcrCategoryOptions}
          required
          value={!selectedCpcrCategory ? { label: 'Select' } : selectedCpcrCategory}
          onChanged={onChange}
          placeholder="Select"
          state={fieldsState}
          error={errors.cpcrProductCategory}
          isClearable
        />
      )}
    </FieldPanelFullWidth>
  );
};

export default PcrInformationPanel;
