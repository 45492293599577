import React from 'react';
import WizardInputSkeleton from 'components/v2/epd-wizard-inputs/WizardInputSkeleton';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Skeleton } from 'primereact/skeleton';

const SkeletonPcrLcaAndVerificationTab: React.FC<{}> = () => {
  return (
    <>
      {/* PCR */}
      <FieldPanelFullWidth>
        <Skeleton width="10rem" />

        <div>
          <WizardInputSkeleton />
          <Skeleton width="5rem" className="mt-2" />
        </div>
      </FieldPanelFullWidth>

      {/* LCA */}
      <FieldPanelFullWidth>
        <Skeleton width="10rem" />
        <WizardInputSkeleton />
      </FieldPanelFullWidth>

      {/* Verification */}
      <FieldPanelFullWidth>
        <Skeleton width="10rem" />

        {Array(4)
          .fill(0)
          .map((_, i) => (
            <WizardInputSkeleton key={i} />
          ))}
      </FieldPanelFullWidth>
    </>
  );
};

export default SkeletonPcrLcaAndVerificationTab;
