import React from 'react';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Skeleton } from 'primereact/skeleton';

const SkeletonTechnicalPerformanceTab: React.FC<{}> = () => {
  return (
    <>
      <FieldPanelFullWidth>
        <Skeleton height="84px" />
      </FieldPanelFullWidth>
      <FieldPanelFullWidth>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Skeleton width="10rem" />
            <Skeleton width="10rem" />
          </div>
          <Skeleton height="134px" className="mt-3" />
        </div>
      </FieldPanelFullWidth>
    </>
  );
};

export default SkeletonTechnicalPerformanceTab;
