import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextInputControlledComponent from 'components/v2/epd-wizard-inputs/WizardTextInputControlled';
import { HelpBox } from 'components/v2/help-boxes';
import { toaster } from 'components/v2/toast';
import { Command, ProcessStatus, Role } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useState } from 'react';
import CompanyService from 'services/CompanyService';
import { ConnectVerifierModel, CountryModel } from 'services/EpdClient';
import { VerificationType } from 'services/EpdClient';
import { useConnectEpdIndividualVerifier, useRequestCollaboration } from 'services/api/mutations';
import styled from 'styled-components';
import { Option, VerifierType } from 'types/types';
import { EPDVerifierCompanyModel, EPDVerifierModel } from 'types/types';

import VerifierCompaniesList from './VerifierCompaniesList';

const VerifierFilterOption = {
  All: 'All',
  ApprovedIndividual: 'Approved Individual Verifiers',
  CertificationBodies: 'Accredited Certification Bodies',
};

type TProps = {
  epdId: string;
  epdVersionId: string;
  epdVerifier?: EPDVerifierModel | undefined;
  onHide: any;
  maximizable?: boolean;
  isOpened: boolean;
};

const EpdVerifierSelectorDialog = ({
  epdId,
  epdVersionId,
  epdVerifier,
  onHide,
  maximizable,
  isOpened,
  ...props
}: TProps) => {
  const { companyId } = useContext(CompanyContext);
  const [countries, setCountries] = useState<Option[]>([]);
  const [verifiers, setVerifiers] = useState<EPDVerifierCompanyModel[]>([]);
  const [filteredVerifierCompanies, setFilteredVerifierCompanies] = useState<EPDVerifierCompanyModel[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<Option | undefined>();
  const [selectedVerifier, setSelectedVerifier] = useState<EPDVerifierCompanyModel | undefined>();
  const [inquiryStatus, setInquiryStatus] = useState<[ProcessStatus, Command]>([ProcessStatus.None, Command.None]);

  const verifierTypeOptions = [
    { value: VerifierFilterOption.All, label: VerifierFilterOption.All },
    { value: VerifierFilterOption.ApprovedIndividual, label: VerifierFilterOption.ApprovedIndividual },
    { value: VerifierFilterOption.CertificationBodies, label: VerifierFilterOption.CertificationBodies },
  ] as Option[];

  const [selectedVerifierType, setSelectedVerifierType] = useState<Option>(verifierTypeOptions[0]);

  const requestCollaborationMutation = useRequestCollaboration(epdVersionId);
  const connectVerifierMutation = useConnectEpdIndividualVerifier(epdVersionId);

  const onChangeVerifierCountry = async (propertyName: string, val: any) => {
    if (!countries.length) return;
    setSelectedCountry(countries.find((c) => c.value === val));
  };

  const onChangeVerifierType = async (propertyName: string, val: any) => {
    setSelectedVerifierType(verifierTypeOptions.find((v) => v.value === val)!);
  };

  const onRequestCollaboration = async (companyId: string) => {
    try {
      setInquiryStatus([ProcessStatus.Fetching, Command.Inquiry]);
      await requestCollaborationMutation.mutateAsync({
        epdVersionId,
        roleId: Role.EPDVerifier,
        companyId,
        verificationType: VerificationType.EPDVerification,
      });
      setInquiryStatus([ProcessStatus.Success, Command.Inquiry]);
      toaster({
        severity: 'success',
        summary: 'Collaboration e-mail request was sent!',
        details: 'Please wait for a confirmation respond.',
      });
      setFilteredVerifierCompanies((prev) =>
        prev.map((c) =>
          c.id === selectedVerifier?.id
            ? { ...c, successOperationText: 'The collaboration request was sent, you can submit a new request in 24 hours.' }
            : c
        )
      );
    } catch {
      setInquiryStatus([ProcessStatus.Error, Command.Inquiry]);
      setFilteredVerifierCompanies((prev) =>
        prev.map((c) => (c.id === selectedVerifier?.id ? { ...c, errorText: 'Something went wrong.' } : c))
      );
    }
  };

  const onAddVerifier = async (verifierId: string) => {
    try {
      const verifier = verifiers.find((v) => v.id === verifierId);
      if (!verifier) {
        throw new Error('Verifier is selected but not found in data set');
      }

      const connectVerifierModel: ConnectVerifierModel = {
        epdId,
        certificationBodyCompanyId: verifier.verifierType === VerifierType.Body ? verifierId : null,
        individualVerifierMembershipId: verifier.verifierType === VerifierType.Individual ? verifierId : null,
      };
      setInquiryStatus([ProcessStatus.Fetching, Command.Connect]);
      await connectVerifierMutation.mutateAsync(connectVerifierModel);
      setInquiryStatus([ProcessStatus.Success, Command.Connect]);
      toaster({
        severity: 'success',
        summary: 'The verifier was added to the EPD!',
        details: 'Now the EPD verifier has an access to the EPD.',
      });
    } catch {
      setInquiryStatus([ProcessStatus.Error, Command.Connect]);
      setFilteredVerifierCompanies((prev) =>
        prev.map((c) => (c.id === selectedVerifier?.id ? { ...c, errorText: 'Something went wrong.' } : c))
      );
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      const result = await CompanyService.getCountries(Role.EPDVerifier);
      const countryOptions = result.map((country: CountryModel) => {
        return { value: country.id, label: country.name } as Option;
      });
      setCountries(countryOptions);
    };
    fetchCountries();
  }, [companyId]);

  useEffect(() => {
    const fetchCompanyVerifiers = async () => {
      let result: EPDVerifierCompanyModel[] = [];

      switch (selectedVerifierType.value) {
        case VerifierFilterOption.All:
          result = await CompanyService.getCompanyVerifiers(
            companyId,
            selectedCountry?.value,
            epdVersionId,
            VerificationType.EPDVerification
          );
          break;
        case VerifierFilterOption.ApprovedIndividual:
          result = await CompanyService.getCompanyIndividualVerifiers(
            companyId,
            selectedCountry?.value,
            epdVersionId,
            VerificationType.EPDVerification
          );
          break;
        case VerifierFilterOption.CertificationBodies:
          result = await CompanyService.getCompanyCertificationBodies(
            companyId,
            selectedCountry?.value,
            epdVersionId,
            VerificationType.EPDVerification
          );
          break;
        default:
          break;
      }

      setVerifiers(result);
      setFilteredVerifierCompanies(result);
    };

    fetchCompanyVerifiers();
  }, [companyId, selectedCountry, selectedVerifierType, isOpened]);

  const handleSearchVerifierCompanies = (event: any) => {
    const searchTerm = event.target.value.toLowerCase();
    if (!searchTerm) {
      setFilteredVerifierCompanies(verifiers);
      return;
    }
    setFilteredVerifierCompanies((prev) =>
      verifiers.filter(
        (item) => item.name?.toLowerCase().includes(searchTerm) || item.email?.toLowerCase().includes(searchTerm)
      )
    );
  };

  return (
    <Dialog
      header="EPD Verifier"
      visible={isOpened}
      style={{ width: '40vw' }}
      contentStyle={!!filteredVerifierCompanies.length ? {} : { overflowY: 'initial' }}
      onHide={() => {
        setSelectedVerifier(undefined);
        setSelectedCountry(undefined);
        setSelectedVerifierType(verifierTypeOptions[0]);
        onHide();
      }}
      maximizable={maximizable}
      {...props}
    >
      <DialogContentContainer>
        <HelpBox>
          To get in touch with one or several verifiers please click on the button “Request collaboration”. An e-mail will be
          sent to the verifier and they will reach out to respond to your query. Once you will get the verifier’s
          collaboration response, you can “Add verifier” to your EPD.
        </HelpBox>
        <DialogContentRow>
          <CompilerSelectControlledComponent
            label="Country"
            name="verifierCountry"
            options={countries}
            value={selectedCountry}
            onChanged={onChangeVerifierCountry}
            placeholder="Select"
            isClearable={true}
          />
        </DialogContentRow>
        <DialogContentRow>
          <CompilerSelectControlledComponent
            label="Verifier type"
            name="verifierType"
            options={verifierTypeOptions}
            value={selectedVerifierType}
            onChanged={onChangeVerifierType}
            placeholder="Select"
            isClearable={false}
          />
        </DialogContentRow>
        <DialogContentRow>
          <WizardTextInputControlledComponent
            label="Verifier name or e-mail"
            tooltipPosition={'right'}
            value={undefined}
            placeholder="Type here to search"
            onChange={handleSearchVerifierCompanies}
            onChanged={() => {}}
            name="nameOrEmail"
            maxLength={150}
          />
        </DialogContentRow>
        <VerifierCompaniesList
          epdVerifier={epdVerifier}
          verifierCompanies={filteredVerifierCompanies}
          selectedVerifierCompany={selectedVerifier}
          onSelectVerifierCompany={setSelectedVerifier}
          onRequestCollaboration={onRequestCollaboration}
          onAddVerifier={onAddVerifier}
          buttonsAreDisabled={
            (inquiryStatus[0] === ProcessStatus.Fetching && inquiryStatus[1] === Command.Inquiry) ||
            (inquiryStatus[0] === ProcessStatus.Fetching && inquiryStatus[1] === Command.Connect)
          }
        />
      </DialogContentContainer>
    </Dialog>
  );
};

const DialogContentContainer = styled.div`
  padding: 0.25rem 2rem 2rem 2rem;
`;

const DialogContentRow = styled.div`
  margin-top: 25px;
`;

export default EpdVerifierSelectorDialog;
