import { Panel } from 'primereact/panel';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PanelContainer } from 'styles/v2/Styles.styled';

import ToggleButton from './panels/toggleable/ToggleButton';

type TProps = {
  statusDisplayName?: string;
  statusMessageCode?: string;
};

const StatusBox = ({ statusDisplayName, statusMessageCode }: TProps) => {
  const { t } = useTranslation();

  const headerTemplate = (options: any) => {
    const className = `${options.className} justify-content-space-between w-full`;

    return (
      <div
        className={className}
        style={{
          borderRadius: options.collapsed ? '4px' : undefined,
          paddingRight: 0,
          height: '3rem',
        }}
      >
        <StatusLabel style={options.collapsed ? { whiteSpace: 'nowrap' } : {}}>
          EPD status: {statusDisplayName ? t(`epdWizard.epdState.status.${statusDisplayName}`) : ''}
        </StatusLabel>
        <ToggleButton options={options} withMargins />
      </div>
    );
  };

  return (
    <PanelContainer style={{ boxShadow: 'none' }}>
      <StatusBoxPanel headerTemplate={(options) => headerTemplate(options)} toggleable collapsed>
        {!!statusDisplayName && !!statusMessageCode && (
          <div>
            <p>{t(`epdWizard.epdState.statusMessage.${statusMessageCode}`)}</p>
            <StatusLabel>{t('epdWizard.epdState.action.title')}</StatusLabel>
            <p>{t(`epdWizard.epdState.actionMessage.${statusMessageCode}`)}</p>
          </div>
        )}
      </StatusBoxPanel>
    </PanelContainer>
  );
};

const StatusLabel = styled.label`
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 185px;
  padding: 8px 0;
`;

const StatusBoxPanel = styled(Panel)`
  background-color: #e2ebe3;
  border-radius: 4px;
  border: 0;
  color: ${(props) => props.theme.colors.primaryBlack70};
  ${StatusLabel} {
    ${(props) => props.theme.typography.headerCaptionSemiBold};
    color: ${(props) => props.theme.colors.primaryGreen};
  }

  .p-panel-header {
    border: 0;
    background-color: transparent;
  }
  .p-panel-content {
    border: 0;
    padding-top: 0;
    background-color: transparent;
    ${(props) => props.theme.typography.bodyInput};
  }

  p {
    margin-top: 4px;
  }
`;

export default StatusBox;
