import React from 'react';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Skeleton } from 'primereact/skeleton';

const SkeletonStep6: React.FC<{}> = () => {
  return (
    <>
      <FieldPanelFullWidth style={{ marginTop: 0 }}>
        <Skeleton width="10rem" />
        <Skeleton height="54px" />
      </FieldPanelFullWidth>
      <FieldPanelFullWidth>
        <Skeleton width="10rem" />
        <Skeleton height="34px" />
      </FieldPanelFullWidth>
    </>
  );
};

export default SkeletonStep6;
