import React from 'react';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Skeleton } from 'primereact/skeleton';

const SkeletonLcaResultsTab: React.FC<{}> = () => {
  return (
    <>
      <FieldPanelFullWidth>
        <div>
          <Skeleton height="54px" />
          <Skeleton width="10rem" className="mt-2" />
        </div>
      </FieldPanelFullWidth>
      {Array(7)
        .fill(0)
        .map((_, i) => (
          <FieldPanelFullWidth key={i}>
            <div style={{ display: 'flex', gap: 16 }}>
              <Skeleton style={{ flexBasis: 0, flexGrow: 1 }} />
              <Skeleton width="10rem" />
            </div>
          </FieldPanelFullWidth>
        ))}
    </>
  );
};

export default SkeletonLcaResultsTab;
