export const compilerStep1Tabs = [
  'epd-definition',
  'pcr-lca-epd-verification',
  'organisation-information',
  'additional-information',
] as const;
export type CompilerStep1Tab = (typeof compilerStep1Tabs)[number];

export const compilerStep2Tabs = ['product-declaration', 'technical-performance', 'content-declaration'] as const;
export type CompilerStep2Tab = (typeof compilerStep2Tabs)[number];

export const compilerStep3Tabs = ['specification', 'system-boundary', 'scenarios', 'results'] as const;
export type CompilerStep3Tab = (typeof compilerStep3Tabs)[number];
