import { CollectionEPDModel } from 'services/EpdClient';
import styled from 'styled-components';
import EpdCollectionItem from './EpdCollectionItem';

type TProps = {
  epds: CollectionEPDModel[];
  isEditMode: boolean;
  onRemoveEpd(id: string): void;
};

const CollectionEpdList = ({ epds, isEditMode, onRemoveEpd }: TProps) => {
  return (
    <EpdCollectionListBox>
      {epds.map((epd) => (
        <EpdCollectionItem key={epd.id} epd={epd} isEditMode={isEditMode} onRemove={onRemoveEpd} />
      ))}
    </EpdCollectionListBox>
  );
};

const EpdCollectionListBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default CollectionEpdList;
