import helpIcon from 'images/icons/svg/info.svg';
import React from 'react';
import styled from 'styled-components';

const HelpIcon: React.FunctionComponent<{ className?: string; content: JSX.Element | string }> = ({
  className,
  content,
}) => {
  return (
    <div className={className}>
      <Image alt="Help" src={helpIcon} />
      <Tooltip>{content}</Tooltip>
    </div>
  );
};

const Image = styled.img`
  height: 1rem;
  vertical-align: top;
  margin: 0;
  cursor: pointer;
  &:hover {
    & + * {
      display: initial;
    }
  }
`;

const Tooltip = styled.div`
  display: none;
  ${(props) => props.theme.fonts.textSmall};
  text-transform: initial;
  position: absolute;
  background: ${(props) => props.theme.colors.darkGray};
  padding: 1rem;
  color: white;
  opacity: 0.85;
  z-index: 1;
`;

export default HelpIcon;
