import { Column, ColumnEvent } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { DataQualityAssessmentItemEnum } from 'services/EpdClient';
import {
  useCreateEpdDataQualityAssessmentItem,
  useDeleteEpdDataQualityAssessmentItems,
  useUpdateEpdDataQualityAssessmentItem,
} from 'services/api/mutations';
import styled from 'styled-components';
import { DataQualityAssessmentItemModel } from 'types/types';
import { formatDate, isPositiveDecimal } from 'util/utils';

import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';
import DynamicGridHeader from '../DynamicGridHeader';
import { cellDateRangeEditor, cellNumberEditor, cellTextEditor } from '../GridCellEditors';
import { cellTotalPercentTemplate } from '../GridService';
import { DataTableStyled, Placeholder } from '../StyledGrid';
import useDynamicGridWithEmptyFirstRow from '../useDynamicGridWithEmptyFirstRow';
import useGridRowSelectionViaCheckbox from '../useGridRowSelectionViaCheckbox';

type TProps = {
  epdVersionId: string;
  rows: DataQualityAssessmentItemModel[] | undefined;
  onMaximize?: () => void;
  errors?: any;
};

const DataQualityAssessmentAndReferenceYearsGrid = ({ epdVersionId, rows, onMaximize, errors }: TProps) => {
  const isReadOnly = useIsReadOnlyMode();

  const createMutation = useCreateEpdDataQualityAssessmentItem(epdVersionId);
  const updateMutation = useUpdateEpdDataQualityAssessmentItem(epdVersionId);
  const deleteMutation = useDeleteEpdDataQualityAssessmentItems(epdVersionId);
  const { dataTableProps, checkboxColumnNode, handleDeleteSelected, isDeleteDisabled, gridId } =
    useGridRowSelectionViaCheckbox<DataQualityAssessmentItemModel>(isReadOnly, deleteMutation);

  const { gridData, handleAddRow, saveModifiedGridData } = useDynamicGridWithEmptyFirstRow<DataQualityAssessmentItemModel>({
    rows,
    createMutation,
    updateMutation,
    emptyEntity: {
      epdVersionId,
      processName: '',
      sourceType: '',
      source: '',
      referenceYearStartDate: '',
      referenceYearEndDate: '',
      dataCategory: '',
      shareOfPrimaryData: '',
    },
  });

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field } = e;

    let newData: (DataQualityAssessmentItemModel & { isFakeRow?: true }) | null = null;

    switch (field) {
      case DataQualityAssessmentItemEnum.ReferenceYear:
        if (Array.isArray(newValue) && newValue.length === 2) {
          newData = {
            ...rowData,
            referenceYearStartDate: newValue[0],
            referenceYearEndDate: newValue[1],
          };
        }
        break;

      case DataQualityAssessmentItemEnum.ShareOfPrimaryData:
        if (isPositiveDecimal(newValue)) {
          newData = {
            ...rowData,
            [field]: newValue,
          };
        }
        break;

      default:
        newData = {
          ...rowData,
          [field]: newValue,
        };
        break;
    }

    if (!newData) {
      return;
    }

    saveModifiedGridData(newData);
  };

  const rowClassName = () => ({ 'p-disabled': isReadOnly });

  const header = (
    <DynamicGridHeader
      caption=""
      onAddRow={handleAddRow}
      onDeleteRows={handleDeleteSelected}
      deleteRowsDisabled={isDeleteDisabled}
      onMaximize={onMaximize}
    />
  );

  const valueBodyTemplate = (fieldName: string, rowData: DataQualityAssessmentItemModel) => {
    let value;
    switch (fieldName) {
      case DataQualityAssessmentItemEnum.ProcessName:
        value = rowData.processName as string | undefined;
        return value ? value : <Placeholder>Type process name, e.g “Manufacturing of product”</Placeholder>;
      case DataQualityAssessmentItemEnum.SourceType:
        value = rowData.sourceType as string | undefined;
        return value ? value : <Placeholder>Type source type, e.g “Database”</Placeholder>;
      case DataQualityAssessmentItemEnum.Source:
        value = rowData.source as string | undefined;
        return value ? value : <Placeholder>Type source, e.g “EPD Owner”</Placeholder>;
      case DataQualityAssessmentItemEnum.ReferenceYear:
        value =
          rowData.referenceYearStartDate &&
          rowData.referenceYearEndDate &&
          (`${formatDate(rowData.referenceYearStartDate)} - ${formatDate(rowData.referenceYearEndDate)}` as
            | string
            | undefined);
        return value ? value : <Placeholder>Select</Placeholder>;
      case DataQualityAssessmentItemEnum.DataCategory:
        value = rowData.dataCategory as string | undefined;
        return value ? value : <Placeholder>Type data category, e.g “Primary data”</Placeholder>;
      case DataQualityAssessmentItemEnum.ShareOfPrimaryData:
        value = rowData.shareOfPrimaryData as number | undefined;
        return value ? `${value}%` : <Placeholder>Type value as appropriate %</Placeholder>;
      default:
        return 'N/A';
    }
  };

  const footerGroup = (
    <ColumnGroup>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" />
        <Column
          className="p-row-note"
          footer={<strong>Total share of primary data, of GWP-GHG results for A1-A3</strong>}
          colSpan={5}
        />
        <Column className="p-row-total" footer={cellTotalPercentTemplate('shareOfPrimaryData', rows, errors)} />
      </Row>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" footer={<div>Note</div>} />
        <Column
          className="p-row-note"
          footer={
            <span>
              The share of primary data is calculated based on GWP-GHG results. It is a simplified indicator for data quality
              that do not capture all relevant aspects of data quality. The indicator is not comparable across product
              categories.
            </span>
          }
          colSpan={6}
        />
      </Row>
    </ColumnGroup>
  );

  return (
    <div style={{ overflowX: 'hidden', width: '100%' }}>
      <QualityAssessmentDataTableStyled
        key={gridId}
        header={header}
        footerColumnGroup={footerGroup}
        value={gridData}
        tableStyle={{ minWidth: '100rem' }}
        dataKey="ord"
        showGridlines
        editMode="cell"
        rowClassName={rowClassName}
        {...dataTableProps}
      >
        {checkboxColumnNode}
        <Column
          field="processName"
          header={'Process name'}
          editor={isReadOnly ? undefined : cellTextEditor}
          onCellEditComplete={onCellEditComplete}
          className="padding"
          body={(rowData) => valueBodyTemplate('processName', rowData)}
        />
        <Column
          field="sourceType"
          header="Source type"
          editor={isReadOnly ? undefined : cellTextEditor}
          onCellEditComplete={onCellEditComplete}
          className="padding"
          body={(rowData) => valueBodyTemplate('sourceType', rowData)}
        />
        <Column
          field="source"
          header="Source"
          editor={isReadOnly ? undefined : cellTextEditor}
          onCellEditComplete={onCellEditComplete}
          className="padding"
          body={(rowData) => valueBodyTemplate('source', rowData)}
        />
        <Column
          field="referenceYear"
          header="Reference year"
          editor={isReadOnly ? undefined : cellDateRangeEditor}
          onCellEditComplete={onCellEditComplete}
          className="padding"
          body={(rowData) => valueBodyTemplate('referenceYear', rowData)}
        />
        <Column
          field="dataCategory"
          header="Data category"
          editor={isReadOnly ? undefined : cellTextEditor}
          onCellEditComplete={onCellEditComplete}
          className="padding"
          body={(rowData) => valueBodyTemplate('dataCategory', rowData)}
        />
        <Column
          field="shareOfPrimaryData"
          header="Share of primary data, of GWP-GHG results for A1-A3"
          editor={isReadOnly ? undefined : cellNumberEditor}
          onCellEditComplete={onCellEditComplete}
          className="padding"
          body={(rowData) => valueBodyTemplate('shareOfPrimaryData', rowData)}
        />
      </QualityAssessmentDataTableStyled>
    </div>
  );
};

const QualityAssessmentDataTableStyled = styled(DataTableStyled)`
  border-collapse: collapse;
  width: auto;

  .p-datatable-table {
    height: 100%;
  }
  .p-datatable-tbody > tr > td.padding,
  .p-datatable-tbody > tr.p-datatable-emptymessage > td {
    padding: 0.5rem 1rem;
  }
  && .p-inputtext.p-dropdown-label.p-placeholder {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
  .p-checkbox.p-component.p-disabled > .p-checkbox-box.p-component {
    opacity: 1;
    border-color: ${(props) => props.theme.colors.elementsDropDown};
  }
`;

export default DataQualityAssessmentAndReferenceYearsGrid;
