import { PDFImagesView } from 'components/v2/images-upload/PDFImagesView';
import React from 'react';
import { SystemBoundaryModel } from 'types/types';

import { PDF_H1, PDF_H2, PDF_P, PDF_PAGE } from './Styles.styled';

const ProcessFlowDiagramsDescriptionPage: React.FunctionComponent<{
  systemBoundaryData?: SystemBoundaryModel;
}> = ({ systemBoundaryData }) => {
  return (
    <PDF_PAGE>
      <PDF_H1>Description of the process flow diagram(s) </PDF_H1>
      <PDF_P>{systemBoundaryData?.processFlowDiagramsDescription}</PDF_P>
      {systemBoundaryData?.processFlowDiagramsImages && systemBoundaryData?.processFlowDiagramsImages.length > 0 && (
        <>
          <PDF_H2>Process flow diagram(s) related images </PDF_H2>
          <PDFImagesView images={systemBoundaryData.processFlowDiagramsImages} />
        </>
      )}
    </PDF_PAGE>
  );
};

export default ProcessFlowDiagramsDescriptionPage;
