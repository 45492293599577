import { useEpdLcaResultsAdditional } from 'services/api/queries';
import {
  createVariationColumnWithDisclaimer,
  createVariationDisclaimerNote,
  LcaResultsAdditionalGridColumns,
  LcaResultsAdditionalGridColumnsPdf,
} from './LcaResultsGridColumns';
import LcaResultsGridExtended from './LcaResultsGridExtended';
import LcaResultsGridExtendedPdf from './LcaResultsGridExtendedPdf';
import useLcaGridHelper from '../../hooks/useLcaGridHelper';
import { LcaGridNote } from '../GridService';

type TProps = {
  epdVersionId: string;
  isForPdf?: boolean;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};

const LcaResultsGridAdditionalVoluntary = ({
  epdVersionId,
  isForPdf,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
}: TProps) => {
  const rows = useEpdLcaResultsAdditional(epdVersionId).data;
  const { generalDisclaimerNote, showVariationColumn } = useLcaGridHelper(epdVersionId);

  const notes: LcaGridNote[] = [];

  if (generalDisclaimerNote) {
    notes.push(generalDisclaimerNote);
  }

  let columns = isForPdf ? LcaResultsAdditionalGridColumnsPdf : LcaResultsAdditionalGridColumns;
  if (showVariationColumn && (!isForPdf || (isForPdf && rows?.some((r) => r.variation)))) {
    const disclaimerNumber = 1;
    columns = [...columns, createVariationColumnWithDisclaimer(disclaimerNumber)];
    notes.push(createVariationDisclaimerNote(disclaimerNumber));
  }

  if (isForPdf) {
    return <LcaResultsGridExtendedPdf epdVersionId={epdVersionId} rows={rows} columns={columns} notes={notes} />;
  }
  return (
    <LcaResultsGridExtended
      epdVersionId={epdVersionId}
      rows={rows}
      columns={columns}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaResultsGridAdditionalVoluntary;
