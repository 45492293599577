import React from 'react';
import WizardInputSkeleton from 'components/v2/epd-wizard-inputs/WizardInputSkeleton';
import { FieldPanel, FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Skeleton } from 'primereact/skeleton';

const SkeletonOrganizationTab: React.FC<{}> = () => {
  const renderSkeletonFields = (count: number) =>
    Array(count)
      .fill(0)
      .map((_, i) => <WizardInputSkeleton key={i} />);

  return (
    <>
      <FieldPanelFullWidth>
        <Skeleton width="10rem" />
        {renderSkeletonFields(3)}
      </FieldPanelFullWidth>
      <FieldPanel>
        <div className="w-full">
          <Skeleton width="15rem" />
        </div>
        {renderSkeletonFields(5)}
      </FieldPanel>
      <FieldPanelFullWidth>
        <div>
          <Skeleton width="20rem" />
          <Skeleton height="67px" className="mt-2" />
        </div>
        <div>
          <Skeleton width="20rem" />
          <Skeleton height="106px" className="mt-2" />
        </div>
        <div>
          <Skeleton width="20rem" />
          <Skeleton height="106px" className="mt-2" />
        </div>
      </FieldPanelFullWidth>
    </>
  );
};

export default SkeletonOrganizationTab;
