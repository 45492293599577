import { Column, ColumnProps } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { LcaResultModel } from 'types/types';
import { DataTableStyledPdf } from './LcaResultsGridPdf';
import { useEpdLcaResultsAdditionalAcronyms } from 'services/api/queries';
import { LcaGridNote } from '../GridService';

type TProps = {
  epdVersionId: string;
  rows: LcaResultModel[] | undefined;
  columns: ColumnProps[];
  notes: LcaGridNote[];
};
const LcaResultsGridExtendedPdf = ({ epdVersionId, rows, columns, notes }: TProps) => {
  const acronymsQuery = useEpdLcaResultsAdditionalAcronyms(epdVersionId);

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Acronyms" colSpan={2} className="p-row-note" />
        <Column
          style={{ padding: '2px' }}
          footer={acronymsQuery.data?.acronyms ?? ''}
          colSpan={columns.length}
          className="p-row-note"
        />
      </Row>
      {notes.map((x, i) => (
        <Row key={i}>
          <Column footer={x.note} colSpan={2} className="p-row-note" />
          <Column style={{ padding: '2px' }} footer={x.description} colSpan={columns.length} className="p-row-note" />
        </Row>
      ))}
    </ColumnGroup>
  );

  return (
    <DataTableStyledPdf
      value={rows}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      footerColumnGroup={footerGroup}
    >
      {columns.map((x) => (
        <Column key={x.field} {...x} />
      ))}
    </DataTableStyledPdf>
  );
};

export default LcaResultsGridExtendedPdf;
