import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as AddImageIcon } from 'assets/images/icons/plus.svg';
import { DeleteImageIcon } from 'components/v2/icons';
import { UploadButton } from 'pages/wizard/WizardStep2';
import { useTranslation } from 'react-i18next';
import { FileModelPlus } from 'services/EpdClient';
import FileService from 'services/FileService';
import styled, { css } from 'styled-components';
import { isValidImageFile } from 'util/utils';
import { Skeleton } from 'primereact/skeleton';
import { confirmDelete } from '../confirm-dialog/ConfirmDelete';
import { toaster } from '../toast';
import { NoImages } from './NoImages';
import useIsReadOnlyMode from '../compiler/hooks/useIsReadOnlyMode';

interface ImageUploadProps {
  name: string;
  images?: FileModelPlus[];
  onUpload(name: string, file: File): any;
  onReorder: (imgIds: string[]) => any;
  onRemove: any;
  disabled?: boolean;
  single?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
}

const SUPPORTED_IMAGE_EXTENSIONS = ['.bmp', '.jpeg', '.jpg', '.png'];
const INPUT_ACCEPT_ATTRIBUTE = SUPPORTED_IMAGE_EXTENSIONS.join(',');

export const ImagesUpload: React.FunctionComponent<ImageUploadProps> = ({
  name,
  images,
  onUpload,
  onReorder,
  onRemove,
  disabled,
  single,
  preferEnabledAppearanceEvenWhenDisabled,
}) => {
  const { t } = useTranslation();
  const [dragId, setDragId] = useState<any>(null);
  const [previewImages, setPreviewImages] = useState<PImg[] | undefined>(undefined);
  const isReadOnly = useIsReadOnlyMode() || disabled;
  const [uploadingPromises, setUploadingPromises] = useState<Promise<void>[]>([]);

  interface PImg {
    id: number;
    imgId?: string;
    imgName?: string | undefined;
    epdFileId?: string;
  }

  useEffect(() => {
    setPreviewImages(
      images?.map((img, index) => {
        return { id: index + 1, imgId: img.id, imgName: img.name, epdFileId: img.epdFileId } as PImg;
      })
    );
  }, [images]);

  const deleteImage = (img: any) => {
    confirmDelete({
      caption: t('confirmModal.deleteImage'),
      onConfirm: () => onRemove(img?.imgId),
    });
  };

  const handleOver = (ev: any) => {
    ev.preventDefault();
  };

  const handleDrag = (ev: any) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev: any) => {
    ev.preventDefault();
    const dragImage = previewImages?.find((image) => image.id.toString() === dragId);
    const dropImage = previewImages?.find((image) => image.id.toString() === ev.currentTarget.id);
    if (dragImage && dropImage) {
      const arr = moveItem(dragImage?.id - 1, dropImage?.id - 1);
      if (arr && arr.length > 1) {
        onReorder(arr.map((i) => i.epdFileId || ''));
      }
      setPreviewImages(arr);
    }
  };

  const moveItem = (from: number, to: number) => {
    const f = previewImages?.splice(from, 1)[0];
    if (f) {
      previewImages?.splice(to, 0, f);
    }

    return previewImages?.map((img, index) => {
      return { id: index + 1, imgId: img.imgId, imgName: img.imgName, epdFileId: img.epdFileId } as PImg;
    });
  };

  const renderImageBox = (img: PImg) => {
    return (
      <ImageBox
        key={img.id}
        id={String(img.id)}
        draggable={!isReadOnly}
        onDragOver={(e) => handleOver(e)}
        onDragStart={(e) => handleDrag(e)}
        onDrop={(e) => handleDrop(e)}
      >
        <ImageBoxImage>{img.imgId && <img alt={`Custom ${name}`} src={FileService.getImageUrl(img.imgId)} />}</ImageBoxImage>
        {!isReadOnly && (
          <ImageRemove onClick={() => deleteImage(img)}>
            <DeleteImageIcon />
          </ImageRemove>
        )}
      </ImageBox>
    );
  };

  const anyImages = (previewImages && previewImages.length > 0) || uploadingPromises.length > 0;
  const [dragActive, setDragActive] = React.useState(false);

  const handleNewFilesUpload = (files: FileList) => {
    const filesCountToUpload = single ? Math.min(files.length, 1) : files.length;
    if (filesCountToUpload > 0 && anyImages && single) {
      // Prevent uploading more than 1 image
      return;
    }
    for (let i = 0; i < filesCountToUpload; i++) {
      const file = files[i];
      if (isValidImageFile(file, SUPPORTED_IMAGE_EXTENSIONS)) {
        const uploadCallResult = onUpload(name, file);

        if (uploadCallResult instanceof Promise) {
          setUploadingPromises((old) => [...old, uploadCallResult]);
          uploadCallResult.finally(() => {
            setUploadingPromises((old) => {
              const index = old.indexOf(uploadCallResult);
              if (index === -1) {
                return old;
              }
              return old.filter((_, i) => i !== index);
            });
          });
        }
      } else {
        toaster({ severity: 'error', summary: 'Invalid image extension', details: 'Available image formats: .png, .jpg' });
      }
    }
  };

  const handleNewImageDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    handleNewFilesUpload(e.dataTransfer.files);
  };

  const handleNewImageDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      window.addEventListener(
        'dragover',
        function (e) {
          /* eslint-disable */
          e = e || event;
          e.preventDefault();
        },
        false
      );
      window.addEventListener(
        'drop',
        function (e) {
          /* eslint-disable */
          e = e || event;
          e.preventDefault();
        },
        false
      );
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const fileInputElement = (
    <input
      type="file"
      accept={INPUT_ACCEPT_ATTRIBUTE}
      name={name}
      onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files) {
          return;
        }
        handleNewFilesUpload(files);
        // Prevent file name from showing up on hover
        e.target.value = '';
      }}
      disabled={isReadOnly}
      multiple={!single}
    />
  );

  return (
    <ImagesPanel
      disabled={isReadOnly && !preferEnabledAppearanceEvenWhenDisabled}
      anyImages={anyImages}
      dragActive={dragActive}
      draggable={!isReadOnly}
      onDragEnter={handleNewImageDrag}
      onDragLeave={handleNewImageDrag}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleNewImageDrop}
    >
      <AntiflickerHack throttleRenderingIfThisChanges={uploadingPromises.length}>
        {!anyImages && <NoImages inputElement={fileInputElement} disabled={disabled} />}
        {previewImages?.map(renderImageBox)}

        {Array(uploadingPromises.length)
          .fill(0)
          .map((_, i) => (
            <ImageSkeleton key={i} height="78px" width="104px" />
          ))}

        {!single && anyImages && !isReadOnly && (
          <UploadButton>
            <AddImageBox>
              <AddImageIcon />
            </AddImageBox>
            {fileInputElement}
          </UploadButton>
        )}
      </AntiflickerHack>
    </ImagesPanel>
  );
};

/**
 * There's a delay between when the loading indicators disappear and uploaded images show up.
 * This causes an annoying flickering. The solution is to throttle rendering, if loading was recently shown.
 */
const AntiflickerHack: React.FC<React.PropsWithChildren<{ throttleRenderingIfThisChanges: number }>> = ({
  throttleRenderingIfThisChanges: throttleRendersIfThisChanges,
  children,
}) => {
  const [renderedChildren, setRenderedChildren] = useState(children);
  const latestChildrenRef = useRef(children);
  const throttleTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const prevMagicValueRef = useRef(throttleRendersIfThisChanges);

  useEffect(() => {
    if (prevMagicValueRef.current === throttleRendersIfThisChanges) {
      setRenderedChildren(children);
      return;
    }

    if (throttleTimeoutRef.current) {
      clearTimeout(throttleTimeoutRef.current);
    }

    prevMagicValueRef.current = throttleRendersIfThisChanges;
    throttleTimeoutRef.current = setTimeout(() => {
      throttleTimeoutRef.current = null;
      setRenderedChildren(latestChildrenRef.current);
    }, 100);
  }, [throttleRendersIfThisChanges]);

  useEffect(() => {
    latestChildrenRef.current = children;
    if (!throttleTimeoutRef.current) {
      setRenderedChildren(children);
    }
  }, [children]);

  return <>{renderedChildren}</>;
};

const InnerBox = styled.div`
  width: auto;
  min-width: 80px;
  max-width: 300px;
  height: 80px;
  border-radius: 4px;
  border: 0.5px solid ${(props) => props.theme.colors.elementsInputBorder};
  position: relative;
  display: flex;
  margin: 7px;
`;

const ImagesPanel = styled.div<{ disabled?: boolean; anyImages?: boolean; dragActive?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 7px;
  min-height: 106px;
  border-width: 1px;
  border-style: ${(props) => (!props.disabled && !props.anyImages ? 'dashed' : 'solid')};
  border-color: ${(props) =>
    props.disabled
      ? props.theme.colors.elementsFilledInput
      : props.anyImages
      ? props.theme.colors.elementsInputBorder
      : props.theme.colors.secondaryLightGreen};

  background-color: ${(props) =>
    props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.primaryWhite};
  color: ${(props) => (props.disabled ? props.theme.colors.primaryBlack50 : props.theme.colors.primaryGreen)};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  border-radius: 4px;
  transform-style: preserve-3d;

  ${(props) =>
    props.dragActive &&
    css`
      && > ${InnerBox}, ${UploadButton} {
        transform: translateZ(-10px);
        pointer-events: none;
      }
      opacity: 0.5;
      animation: pulsate 0.1s infinite alternate;
      border-color: black;
      @keyframes pulsate {
        0% {
          border-style: dashed;
        }
        100% {
          border-style: dotted;
        }
      }
    `}
`;

const ImageBox = styled(InnerBox)`
  flex-wrap: wrap;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.primaryWhite};
`;

const AddImageBox = styled(InnerBox)`
  background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const ImageBoxImage = styled.div`
  flex: 0 0 auto;
  align-self: center;
  width: auto;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 90%;
    max-height: 90%;
  }
`;

const ImageRemove = styled.div`
  top: 1px;
  position: absolute;
  right: 5px;
  cursor: pointer;
`;

const ImageSkeleton = styled(Skeleton)`
  margin: 7px;
`;
