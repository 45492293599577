import React, { ReactNode } from 'react';
import { useUpdateContentDeclarationProduct, useUpdateEpdGeneralInformation } from 'services/api/mutations';
import {
  useEPDDefinitionInformation,
  useEpdContentDeclarationProducts,
  useGetEpdPCRInformation,
} from 'services/api/queries';
import styled from 'styled-components';
import {
  ExpandablePanel,
  ExpandablePanelHeaderName,
  ExpandablePanelModule,
  FieldPanelFullWidth,
  HeaderCaptionSemiBold,
  PanelContainer,
  TitleIconBar,
} from 'styles/v2/Styles.styled';
import { EPDContentDeclarationProductType, EPDProductType } from 'types/types';

import CompilerSelectControlledComponent from '../epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextAreaComponent from '../epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from '../epd-wizard-inputs/WizardTextInput';
import { ExpandableHelpBox } from '../help-boxes';
import TooltipErrorIcon from '../icons/TooltipErrorIcon';
import TooltipHelpIcon from '../icons/TooltipHelpIcon';
import { SINGLE_PRODUCT_ID, YES_NO, YesNoDataSource } from './constants';
import DangerMaterialsGrid from './grids/product-content/DangerMaterialsGrid';
import PackagingMaterialsGrid from './grids/product-content/PackagingMaterialsGrid';
import PanelContentGrid from './grids/product-content/PanelContentGrid';
import ProductComponentsGrid from './grids/product-content/ProductComponentsGrid';
import ToggleButton from './panels/toggleable/ToggleButton';
import SkeletonContentDeclarationTab from './skeletons/SkeletonContentDeclarationTab';

const hasDangerMaterialsLookup = [
  { value: false, label: YES_NO.NO },
  { value: true, label: YES_NO.YES },
];

const ContentDeclarationTab: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  validationState: any;
}> = ({ epdId, epdVersionId, validationState }) => {
  const pcrInformation = useGetEpdPCRInformation(epdVersionId).data;
  const contentDeclarationProducts = useEpdContentDeclarationProducts(epdVersionId).data;
  const epdDefinition = useEPDDefinitionInformation(epdVersionId).data;

  const updateMutation = useUpdateEpdGeneralInformation(epdVersionId);
  const updateContentDeclarationProduct = useUpdateContentDeclarationProduct(epdVersionId);

  const isLoading = !pcrInformation || !contentDeclarationProducts || !epdDefinition || !validationState;
  if (isLoading) {
    return <SkeletonContentDeclarationTab />;
  }

  const { errors, fieldsState } = validationState;

  const averageProductContentDeclaration =
    contentDeclarationProducts.find((x) => x.type === EPDContentDeclarationProductType.Average) ?? null;
  const bestProductContentDeclaration =
    contentDeclarationProducts.find((x) => x.type === EPDContentDeclarationProductType.BestProduct) ?? null;
  const worstProductContentDeclaration =
    contentDeclarationProducts.find((x) => x.type === EPDContentDeclarationProductType.WorstProduct) ?? null;
  const isPanelContentEnabled = pcrInformation.cpcr?.registrationNumber?.toLocaleLowerCase()?.includes('c-pcr-014');

  const isBestCaseEnabled = !!contentDeclarationProducts.find((x) => x.type === EPDContentDeclarationProductType.BestProduct)
    ?.productDescription;
  const isWorstCaseEnabled = !!contentDeclarationProducts.find(
    (x) => x.type === EPDContentDeclarationProductType.WorstProduct
  )?.productDescription;

  const onChangeEpdGeneralInformation = async (propertyName: string, val: any) => {
    if (!epdId) {
      return;
    }
    updateMutation.mutate({ epdId, versionId: epdVersionId, propertyName: propertyName, newValue: val });
  };

  const onChangeEpdContentDeclarationProduct = async (
    type: EPDContentDeclarationProductType,
    propertyName: string,
    val: any
  ) => {
    updateContentDeclarationProduct.mutate({
      type: type,
      versionId: epdVersionId,
      propertyName: propertyName,
      newValue: val,
    });
  };

  const headerTemplate = (
    options: any,
    header: ReactNode,
    tooltip?: string,
    errors?: any,
    tooltipPosition?: any | undefined
  ) => {
    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          {header}
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {errors?.some((error: any) => !!error) && <TooltipErrorIcon content={'Required'} />}{' '}
        </ExpandablePanelHeaderName>
        <ToggleButton options={options} withMargins />
      </div>
    );
  };

  const errorsForEntireBlock = [
    errors?.serviceIncludesProductInSystemBoundary,
    errors?.declarationIrrelevanceExplanation,
    errors?.averageProductComponents,
    errors?.averagePackagingMaterials,
    errors?.averagePanelContents,
  ].filter((err) => err);
  return (
    <>
      <FieldPanelFullWidth>
        <TitleIconBar>
          <HeaderCaptionSemiBold className="white-space-nowrap">Declare content</HeaderCaptionSemiBold>
          {errorsForEntireBlock.length > 0 && <TooltipErrorIcon content={errorsForEntireBlock} />}
        </TitleIconBar>
        <ExpandableHelpBox
          headerTemplate={(options) =>
            headerTemplate(options, <HelpBoldText>How to report “Declared content” values?</HelpBoldText>)
          }
          toggleable
        >
          <span>
            The gross weight of material in the content declaration shall cover 100% of one unit of product and its
            packaging. If there is more than 5% (post-consumer) recycled or biogenic content in the product, this shall be
            declared (if below 5%, this may be declared). If the share of biobased/recycled material is unknown, this part of
            the content declaration can be left out or be declared as 0% (a conservative estimate) or unknown.
          </span>
        </ExpandableHelpBox>
        {epdDefinition.productType === EPDProductType.Services && (
          <CompilerSelectControlledComponent
            label="Does the service include any physical products used within the defined system boundary?"
            tooltip="If the service involves a physical product that is used in the construction, maintenance, repair, replacement, refurbishment, or demolition of several construction works, the content of that product shall be declared in the content declaration"
            placeholder="Select"
            name="serviceIncludesProductInSystemBoundary"
            options={YesNoDataSource}
            value={YesNoDataSource.find((item) => item.value === epdDefinition.serviceIncludesProductInSystemBoundary)}
            onChanged={onChangeEpdGeneralInformation}
            isClearable={false}
            error={errors?.serviceIncludesProductInSystemBoundary}
            required
          />
        )}
        {epdDefinition.productType === EPDProductType.Services &&
          epdDefinition.serviceIncludesProductInSystemBoundary === YES_NO.NO && (
            <WizardTextAreaComponent
              label="Explanation why content declaration is not relevant"
              placeholder="Type here"
              name="declarationIrrelevanceExplanation"
              value={epdDefinition.declarationIrrelevanceExplanation}
              onChanged={onChangeEpdGeneralInformation}
              error={errors?.declarationIrrelevanceExplanation}
              required
            />
          )}
        {epdDefinition.epdClassification && epdDefinition.epdClassification !== SINGLE_PRODUCT_ID && (
          <WizardTextAreaComponent
            label="Content declaration of multiple products"
            name="productDescription"
            value={averageProductContentDeclaration?.productDescription}
            onChanged={(name: any, value: any) => {
              onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.Average, name, value);
            }}
            placeholder="Type here"
            required={true}
            state={fieldsState}
            tooltip="In EPDs of multiple products or sector EPDs, it shall be described in the content declaration section what the content declaration represents."
          />
        )}
        <ProductComponentsGrid
          epdVersionId={epdVersionId}
          serviceIncludesProductInSystemBoundary={epdDefinition.serviceIncludesProductInSystemBoundary}
          errors={errors?.averageProductComponents}
          contentDeclarationType={EPDContentDeclarationProductType.Average}
        />
        <PackagingMaterialsGrid
          epdVersionId={epdVersionId}
          serviceIncludesProductInSystemBoundary={epdDefinition.serviceIncludesProductInSystemBoundary}
          errors={errors?.averagePackagingMaterials}
          contentDeclarationType={EPDContentDeclarationProductType.Average}
        />
        {isPanelContentEnabled && (
          <PanelContentGrid
            epdVersionId={epdVersionId}
            errors={errors?.averagePanelContents}
            contentDeclarationType={EPDContentDeclarationProductType.Average}
            serviceIncludesProductInSystemBoundary={epdDefinition.serviceIncludesProductInSystemBoundary}
          />
        )}
      </FieldPanelFullWidth>
      <StyledExpandablePanel
        headerTemplate={(options) =>
          headerTemplate(
            options,
            <HeaderCaptionSemiBold>Substances with hazardous and toxic properties</HeaderCaptionSemiBold>,
            'Information on the environmental and hazardous/toxic properties of a substances contained in the product shall be declared if the substance is in the candidate list of Substances of Very High Concern (SVHCs) which exceeds the limits for registration with the European Chemicals Agency.',
            [errors?.averageDangerMaterials]
          )
        }
        toggleable
      >
        <CompilerSelectControlledComponent
          label="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
          name="hasDangerMaterials"
          options={hasDangerMaterialsLookup}
          value={hasDangerMaterialsLookup.find((x) => x.value == averageProductContentDeclaration?.hasDangerMaterials)}
          onChanged={(name: any, value: any) => {
            onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.Average, name, value);
          }}
          placeholder="Select..."
          required={true}
          error={errors?.averageDangerMaterials?.isProductContainsToxicSubstances}
        />
        {averageProductContentDeclaration?.hasDangerMaterials && (
          <DangerMaterialsGrid
            epdVersionId={epdVersionId}
            errors={errors?.averageDangerMaterials}
            contentDeclarationType={EPDContentDeclarationProductType.Average}
          />
        )}
      </StyledExpandablePanel>
      {epdDefinition.epdClassification && epdDefinition.epdClassification !== SINGLE_PRODUCT_ID && (
        <ExpandablePanelMarginContainer>
          <ExpandablePanelMultiContainer
            headerTemplate={(options) =>
              headerTemplate(
                options,
                <HeaderCaptionSemiBold>Content declaration of scenario “best-case product”</HeaderCaptionSemiBold>
              )
            }
            toggleable
            collapsed
          >
            <FieldPanelFullWidth style={{ marginTop: -28 }}>
              <WizardTextInputComponent
                label="Best-case product"
                tooltip="The “best-case product” is defined as the product with the lowest GWP-GHG results."
                onChanged={(name: any, value: any) => {
                  onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.BestProduct, name, value);
                }}
                placeholder="Type here"
                error={errors?.comparisonWorstAndBestCase}
                name="productDescription"
                value={
                  contentDeclarationProducts.find((product) => product.type == EPDContentDeclarationProductType.BestProduct)
                    ?.productDescription
                }
              />
            </FieldPanelFullWidth>
            {isBestCaseEnabled && (
              <>
                <FieldPanelFullWidth>
                  <ProductComponentsGrid
                    epdVersionId={epdVersionId}
                    serviceIncludesProductInSystemBoundary={epdDefinition.serviceIncludesProductInSystemBoundary}
                    contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
                  />
                  <PackagingMaterialsGrid
                    epdVersionId={epdVersionId}
                    serviceIncludesProductInSystemBoundary={epdDefinition.serviceIncludesProductInSystemBoundary}
                    contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
                  />
                  {isPanelContentEnabled && (
                    <PanelContentGrid
                      epdVersionId={epdVersionId}
                      contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
                      serviceIncludesProductInSystemBoundary={epdDefinition.serviceIncludesProductInSystemBoundary}
                    />
                  )}
                </FieldPanelFullWidth>
                <ExpandablePanelModule
                  headerTemplate={(options) =>
                    headerTemplate(
                      options,
                      <HeaderCaptionSemiBold>Substances with hazardous and toxic properties</HeaderCaptionSemiBold>,
                      'Information on the environmental and hazardous/toxic properties of a substances contained in the product shall be declared if the substance is in the candidate list of Substances of Very High Concern (SVHCs) which exceeds the limits for registration with the European Chemicals Agency.'
                    )
                  }
                  toggleable
                >
                  <FieldPanelFullWidth>
                    <CompilerSelectControlledComponent
                      label="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
                      name="hasDangerMaterials"
                      options={hasDangerMaterialsLookup}
                      value={hasDangerMaterialsLookup.find(
                        (x) => x.value == bestProductContentDeclaration?.hasDangerMaterials
                      )}
                      onChanged={(name: any, value: any) => {
                        onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.BestProduct, name, value);
                      }}
                      placeholder="Select..."
                    />
                    {bestProductContentDeclaration?.hasDangerMaterials && (
                      <DangerMaterialsGrid
                        epdVersionId={epdVersionId}
                        contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
                      />
                    )}
                  </FieldPanelFullWidth>
                </ExpandablePanelModule>
              </>
            )}
          </ExpandablePanelMultiContainer>
        </ExpandablePanelMarginContainer>
      )}
      {epdDefinition.epdClassification && epdDefinition.epdClassification !== SINGLE_PRODUCT_ID && (
        <ExpandablePanelMarginContainer>
          <ExpandablePanelMultiContainer
            headerTemplate={(options) =>
              headerTemplate(
                options,
                <HeaderCaptionSemiBold>Content declaration of scenario “worst-case product”</HeaderCaptionSemiBold>
              )
            }
            toggleable
            collapsed
          >
            <FieldPanelFullWidth style={{ marginTop: -28 }}>
              <WizardTextInputComponent
                label="Worst-case product"
                tooltip="The “worst-case product” is defined as the product with the highest GWP-GHG results."
                onChanged={(name: any, value: any) => {
                  onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.WorstProduct, name, value);
                }}
                placeholder="Type here"
                name="productDescription"
                error={errors?.comparisonWorstAndBestCase}
                value={
                  contentDeclarationProducts.find((product) => product.type == EPDContentDeclarationProductType.WorstProduct)
                    ?.productDescription
                }
              />
            </FieldPanelFullWidth>
            {isWorstCaseEnabled && (
              <>
                <FieldPanelFullWidth>
                  <ProductComponentsGrid
                    epdVersionId={epdVersionId}
                    serviceIncludesProductInSystemBoundary={epdDefinition.serviceIncludesProductInSystemBoundary}
                    contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
                  />
                  <PackagingMaterialsGrid
                    epdVersionId={epdVersionId}
                    serviceIncludesProductInSystemBoundary={epdDefinition.serviceIncludesProductInSystemBoundary}
                    contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
                  />
                  {isPanelContentEnabled && (
                    <PanelContentGrid
                      epdVersionId={epdVersionId}
                      contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
                      serviceIncludesProductInSystemBoundary={epdDefinition.serviceIncludesProductInSystemBoundary}
                    />
                  )}
                </FieldPanelFullWidth>
                <ExpandablePanelModule
                  headerTemplate={(options) =>
                    headerTemplate(
                      options,
                      <HeaderCaptionSemiBold>Substances with hazardous and toxic properties</HeaderCaptionSemiBold>,
                      'Information on the environmental and hazardous/toxic properties of a substances contained in the product shall be declared if the substance is in the candidate list of Substances of Very High Concern (SVHCs) which exceeds the limits for registration with the European Chemicals Agency.'
                    )
                  }
                  toggleable
                >
                  <FieldPanelFullWidth>
                    <CompilerSelectControlledComponent
                      label="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
                      name="hasDangerMaterials"
                      options={hasDangerMaterialsLookup}
                      value={hasDangerMaterialsLookup.find(
                        (x) => x.value == worstProductContentDeclaration?.hasDangerMaterials
                      )}
                      onChanged={(name: any, value: any) => {
                        onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.WorstProduct, name, value);
                      }}
                      placeholder="Select..."
                    />
                    {worstProductContentDeclaration?.hasDangerMaterials && (
                      <DangerMaterialsGrid
                        epdVersionId={epdVersionId}
                        contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
                      />
                    )}
                  </FieldPanelFullWidth>
                </ExpandablePanelModule>
              </>
            )}
          </ExpandablePanelMultiContainer>
        </ExpandablePanelMarginContainer>
      )}
    </>
  );
};

const ExpandablePanelMarginContainer = styled(PanelContainer)`
  box-shadow: none;
`;

const ExpandablePanelMultiContainer = styled(ExpandablePanel)`
  .p-panel-content {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100%;
    display: grid;
  }
`;

const StyledExpandablePanel = styled(ExpandablePanel)`
  .p-panel-content {
    display: grid;
    grid-row-gap: 30px;
  }
`;

const HelpBoldText = styled.span`
  font-weight: 600;
`;

export default ContentDeclarationTab;
