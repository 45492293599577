import { LcaResultModel } from 'types/types';
import LcaResultsGrid from './LcaResultsGrid';
import LcaResultsGridPdf from './LcaResultsGridPdf';
import {
  createVariationColumnWithDisclaimer,
  createVariationDisclaimerNote,
  LcaResultsGridColumns,
  LcaResultsGridColumnsPdf,
} from './LcaResultsGridColumns';
import { LcaGridNote } from '../GridService';
import useLcaGridHelper from '../../hooks/useLcaGridHelper';

type TProps = {
  epdVersionId: string;
  rows: LcaResultModel[] | undefined;
  isAdditionalDisclaimerVisible: boolean | undefined;
  isForPdf?: boolean;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};

const LcaResultsGridCoreConstruction = ({
  epdVersionId,
  rows,
  isAdditionalDisclaimerVisible,
  isForPdf,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
}: TProps) => {
  const { generalDisclaimerNote, showVariationColumn } = useLcaGridHelper(epdVersionId);

  const formattedRows = (() => {
    if (!rows?.length) {
      return [];
    }

    return rows.map((r) => {
      if (r.indicatorName === 'ADP-minerals&metals') {
        return {
          ...r,
          indicatorName: isAdditionalDisclaimerVisible
            ? 'ADP-minerals&metals<sup>1</sup>, <sup>2</sup>'
            : 'ADP-minerals&metals<sup>1</sup>',
        };
      }
      return r;
    });
  })();

  const notes: LcaGridNote[] = [
    {
      note: 'Acronyms',
      description:
        'GWP-fossil = Global Warming Potential fossil fuels; GWP-biogenic = Global Warming Potential biogenic; GWP-luluc = Global Warming Potential land use and land use change; ODP = Depletion potential of the stratospheric ozone layer; AP = Acidification potential, Accumulated Exceedance; EP-freshwater = Eutrophication potential, fraction of nutrients reaching freshwater end compartment; EP-marine = Eutrophication potential, fraction of nutrients reaching marine end compartment; EP-terrestrial = Eutrophication potential, Accumulated Exceedance; POCP = Formation potential of tropospheric ozone; ADP-minerals&metals = Abiotic depletion potential for non-fossil resources; ADP-fossil = Abiotic depletion for fossil resources potential; WDP = Water (user) deprivation potential, deprivation-weighted water consumption',
    },
  ];

  if (generalDisclaimerNote) {
    notes.push(generalDisclaimerNote);
  }

  notes.push({
    note: (
      <>
        Disclaimer<sup>1</sup>
      </>
    ),
    description:
      'The results of this environmental impact indicator shall be used with care as the uncertainties of these results are high or as there is limited experience with the indicator',
  });

  if (isAdditionalDisclaimerVisible) {
    notes.push({
      note: (
        <>
          Disclaimer<sup>2</sup>
        </>
      ),
      description:
        'The results of the impact categories abiotic depletion of minerals and metals may be highly uncertain in LCAs that include capital goods/infrastructure in generic datasets, in case infrastructure/capital goods contribute greatly to the total results. This is because the LCI data of infrastructure/capital goods used to quantify these indicators in currently available generic datasets sometimes lack temporal, technological and geographical representativeness. Caution should be exercised when using the results of these indicators for decision-making purposes.',
    });
  }

  let columns = isForPdf ? LcaResultsGridColumnsPdf : LcaResultsGridColumns;
  if (showVariationColumn && (!isForPdf || (isForPdf && rows?.some((r) => r.variation)))) {
    const disclaimerNumber = isAdditionalDisclaimerVisible ? 3 : 2;
    columns = [...columns, createVariationColumnWithDisclaimer(disclaimerNumber)];
    notes.push(createVariationDisclaimerNote(disclaimerNumber));
  }

  if (isForPdf) {
    return <LcaResultsGridPdf rows={formattedRows} columns={columns} notes={notes} />;
  }

  return (
    <LcaResultsGrid
      epdVersionId={epdVersionId}
      value={formattedRows}
      columns={columns}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaResultsGridCoreConstruction;
