import React, { useState, useContext } from 'react';
import _ from 'lodash';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { EPDVerifierModel } from 'types/types';
import { Option } from 'types/types';
import EpdVerifierSelectorDialog from './EpdVerifierSelectorDialog';
import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import { useCompanyVerifiers } from 'services/api/queries';
import WizardAddButton from 'components/v2/epd-wizard-inputs/WizardAddButton';

interface EpdVerifierSelectorProps {
  epdId: string;
  epdVersionId: string;
  epdVerifier?: EPDVerifierModel | undefined;
  onChangeVerifier(propertyName: string, verifier: EPDVerifierModel): void;
  errors: any;
}

const EpdVerifierSelector: React.FunctionComponent<EpdVerifierSelectorProps> = ({
  epdId,
  epdVersionId,
  epdVerifier,
  onChangeVerifier,
  errors,
}) => {
  const { companyId } = useContext(CompanyContext);
  const verifiers = useCompanyVerifiers(companyId ?? '').data;
  const [showSelectDialog, setShowSelectDialog] = useState<boolean>(false);
  const { epdVerifier: epdVerifierError, epdVerifierUnavailable } = errors || {};

  const selectedVerifier = !!epdVerifier
    ? ({
        label: epdVerifier.displayName,
        value: epdVerifier.id,
      } as Option)
    : undefined;

  return (
    <>
      <CompilerSelectControlledComponent
        label="EPD verifier"
        tooltip="The third party verifier who is in charge of verification of this EPD. You can click on Add EPD Verifier to reach out to and add new verifiers."
        name="epdVerifier"
        options={verifiers?.map(
          (verifier) =>
            ({
              value: verifier.id,
              label: verifier.displayName,
            } as Option)
        )}
        value={selectedVerifier}
        onChanged={onChangeVerifier}
        placeholder="Select"
        isClearable={true}
        hasError={epdVerifierUnavailable}
        error={epdVerifierError || epdVerifierUnavailable}
        required
      />
      {epdVerifierUnavailable && (
        <span style={{ paddingTop: 10, paddingBottom: 10, color: 'red' }}>{epdVerifierUnavailable}</span>
      )}
      <WizardAddButton label="+ Add EPD Verifier" onClick={() => setShowSelectDialog(true)} />
      <EpdVerifierSelectorDialog
        epdId={epdId}
        epdVersionId={epdVersionId}
        epdVerifier={epdVerifier}
        onHide={() => setShowSelectDialog(false)}
        isOpened={showSelectDialog}
      />
    </>
  );
};

export default EpdVerifierSelector;
