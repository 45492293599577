import { Redirect, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { ActionState } from 'services/EpdClient';
import { useEpdLatestVersionId, useCompilerCompositeStatus, useEPDDefinitionInformation } from 'services/api/queries';
import EpdRoutes, { EpdLinks } from 'routes/EpdRoutes';
import CompilerStepsValidator from './CompilerStepsValidator';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import { IsReadOnlyModeContext } from './hooks/useIsReadOnlyMode';

const compilerStepsValidator = new CompilerStepsValidator()
  .registerStep(1, (step) => {
    step.withFields(
      'generalInformation',
      // EPD Definition
      'title',
      'declaredStandards',
      'versionDescription',
      'productImages',
      'useAdvice',
      'subtype',
      'epdClassification',
      'representativeJustification',
      'determinationOfAverageDescription',
      'listOfContributingManufacturers',
      'sitesSelectionDescription',
      'documentCoverageStatement',
      'productToDeclare',
      'functionalUnitDescription',
      'productFlow',
      'productFlowName',
      'productFlowUnit',
      'productFlowValue',
      'conversionFactor',
      'conversionFactorUnit',
      'conversionFactorValue',
      'references',
      // PCR, LCA, EPD verification
      'pcr',
      'cpcrProductCategory',
      'epdDevelopers',
      'verificationType',
      'thirdPartyVerification',
      'processCertificateReports',
      'preverifiedTool',
      'preverifiedToolVersion',
      'epdVerifier',
      'epdVerifierUnavailable',
      // Organization information
      'companyLogoImages',
      'organizationInformation',
      'organizationInformationAddress'
    );
  })
  .registerStep(2, (step) => {
    step.withFields(
      'products',
      /^products\[\d+\]$/,
      // Content declaration
      'serviceIncludesProductInSystemBoundary',
      'declarationIrrelevanceExplanation',
      'averageProductComponents',
      'averagePackagingMaterials',
      'averagePanelContents',
      'averageDangerMaterials',
      'comparisonWorstAndBestCase',
      // Technical performance
      'technicalPerformance',
    );
  })
  .registerStep(3, (step) => {
    step.withFields(
      // Specification
      'infrastructureAndCapitalGoods',
      'dataQualityAssessment',
      'lcaSpecification',
      'epdDataSource',
      /^epdDataSource\[\d+\]$/,
      'electricity',
      'lcaSoftwareVersion',
      'lcaSoftware',
      'softwareVersion',
      'softwareName',
      'referencePackageVersion15804',
      'technologyDescription',
      'scrapInputs',
      'scrapInputTotalPercent',
      // System boundary
      'systemBoundaryDescription',
      'hasExcludedModules',
      'excludedModulesJustification',
      'declaredModulesErrors',
      // Scenarios
      'scenarios',
      // Results
      'lcaResults',
      'cO2Carbonation',
      'declarationOfCO2Assumptions',
      'transportationPerformance'
    );
  })
  .registerStep(4, (step) => {
    step.withFields('verificationReports', 'validityDate', 'approvalDate');
  })
  .registerStep(5, (step) => {
    step.withFields('registrationDate', 'publicVersionDate', 'serviceAgreementStatus');
  });

const useCompilerLogic = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const match = useRouteMatch<{ id: string; stepId: string }>({ path: EpdRoutes.epd + '/:stepId*' });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const stepId = match ? match.params.stepId : undefined;
  const tab = query.get('tab');
  const redirect =
    !match || !match.params.stepId ? (
      <Redirect to={EpdLinks.epdCompilerSpecific(id, { step: 'step1', tab: 'epd-definition' })} />
    ) : null;

  const epdVersionId = useEpdLatestVersionId(id).data;
  const compilerCompositeStatus = useCompilerCompositeStatus(id).data;
  const epdDefinition = useEPDDefinitionInformation(epdVersionId!).data;

  type StepDefinition = {
    id: string;
    label: string;
    name: string;
    description: string;
    path: string;
    render: () => JSX.Element;
    isValid: (item: any) => boolean | undefined;
  };

  const isMainContentReadOnly = compilerCompositeStatus?.fieldsState.mainContent !== ActionState.Enabled;
  const steps: StepDefinition[] = [
    {
      id: 'step1',
      label: 'Step 1',
      name: 'General information',
      description: 'Fill-in the EPD initial data',
      path: EpdLinks.epdCompilerSpecific(id, { step: 'step1', tab: 'epd-definition' }),
      render: () => {
        return (
          <IsReadOnlyModeContext.Provider value={isMainContentReadOnly}>
            <Step1 epdId={id} epdVersionId={epdVersionId!} tab={tab} validationState={compilerCompositeStatus} />
          </IsReadOnlyModeContext.Provider>
        );
      },
      isValid: (errors) => compilerStepsValidator.isStepValid(1, errors),
    },
    {
      id: 'step2',
      label: 'Step 2',
      name: 'Product declaration',
      description: 'Describe your EPD subject',
      path: EpdLinks.epdCompilerSpecific(id, { step: 'step2', tab: 'product-declaration' }),
      render: () => {
        return (
          <IsReadOnlyModeContext.Provider value={isMainContentReadOnly}>
            <Step2
              epdId={id}
              epdVersionId={epdVersionId}
              tab={tab}
              epdDefinition={epdDefinition}
              validationState={compilerCompositeStatus}
            />
          </IsReadOnlyModeContext.Provider>
        );
      },
      isValid: (errors) => compilerStepsValidator.isStepValid(2, errors),
    },
    {
      id: 'step3',
      label: 'Step 3',
      name: 'LCA information',
      description: 'Describe your LCA output',
      path: EpdLinks.epdCompilerSpecific(id, { step: 'step3', tab: 'specification' }),
      render: () => {
        return (
          <IsReadOnlyModeContext.Provider value={isMainContentReadOnly}>
            <Step3 epdId={id} epdVersionId={epdVersionId!} tab={tab} validationState={compilerCompositeStatus} />
          </IsReadOnlyModeContext.Provider>
        );
      },
      isValid: (errors) => compilerStepsValidator.isStepValid(3, errors),
    },
    {
      id: 'step4',
      label: 'Step 4',
      name: 'EPD verification',
      description: 'Get the third-party approval',
      path: EpdLinks.epdCompilerSpecific(id, { step: 'step4' }),
      render: () => {
        return <Step4 epdId={id} epdVersionId={epdVersionId!} validationState={compilerCompositeStatus} />;
      },
      isValid: (errors) => compilerStepsValidator.isStepValid(4, errors),
    },
    {
      id: 'step5',
      label: 'Step 5',
      name: 'EPD publication',
      description: 'Submit the EPD for publication',
      path: EpdLinks.epdCompilerSpecific(id, { step: 'step5' }),
      render: () => {
        return <Step5 epdId={id} epdVersionId={epdVersionId!} validationState={compilerCompositeStatus} />;
      },
      isValid: (errors) => compilerStepsValidator.isStepValid(5, errors),
    },
    {
      id: 'step6',
      label: 'Step 6',
      name: 'Generated EPD',
      description: 'Get your EPD in PDF format',
      path: EpdLinks.epdCompilerSpecific(id, { step: 'step6' }),
      render: () => {
        return <Step6 epdVersionId={epdVersionId} />;
      },
      isValid: () => undefined,
    },
  ];

  return {
    epdId: id,
    epdVersionId,
    history,
    stepId,
    redirect,
    steps,
    compilerCompositeStatus,
  };
};

export default useCompilerLogic;
