import React, { ReactNode, useState } from 'react';
import useIsReadOnlyMode from 'components/v2/compiler/hooks/useIsReadOnlyMode';
import MaximizedDialog from 'components/v2/compiler/panels/expandable/MaximizedDialog';
import { Column, ColumnProps } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {
  useCreateLcaCustomResultGridDataRow,
  useDeleteLcaCustomResultGridDataRows,
  useUpdateLcaCustomResultGridDataRow,
} from 'services/api/mutationsLcaResults';
import { LcaCustomResultGridDataModel } from 'types/types';

import DynamicGridHeader from '../../DynamicGridHeader';
import { emptyLCAModuleValues, LcaGridNote } from '../../GridService';
import { ColumnFooterInputText, DataTableStyled } from '../../StyledGrid';
import useDynamicGridWithEmptyFirstRow from '../../useDynamicGridWithEmptyFirstRow';
import useGridRowSelectionViaCheckbox from '../../useGridRowSelectionViaCheckbox';

type TProps = {
  epdVersionId: string;
  customResultId: string;
  title?: ReactNode;
  rows: LcaCustomResultGridDataModel[] | undefined;
  columns: ColumnProps[];
  editableFields: { fieldName: string; label: React.ReactNode; value: string; placeholder: string }[],
  notes: LcaGridNote[];
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
  onChangedCustomResult?: (propertyName: string, val: any) => void;
};

const LcaCustomResultsDynamicGrid = ({
  epdVersionId,
  customResultId,
  title,
  rows,
  columns,
  editableFields,
  notes,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
  onChangedCustomResult,
}: TProps) => {
  const [visible, setVisible] = useState(false);

  const isReadOnly = useIsReadOnlyMode() || !!disabled;

  const createMutation = useCreateLcaCustomResultGridDataRow(epdVersionId, customResultId);
  const updateMutation = useUpdateLcaCustomResultGridDataRow(epdVersionId, customResultId);
  const deleteMutation = useDeleteLcaCustomResultGridDataRows(epdVersionId, customResultId);

  const { dataTableProps, checkboxColumnNode, handleDeleteSelected, isDeleteDisabled, gridId } =
    useGridRowSelectionViaCheckbox<LcaCustomResultGridDataModel>(isReadOnly, deleteMutation);

  const rowClassName = () => ({ 'p-disabled': isReadOnly && !preferEnabledAppearanceEvenWhenDisabled });

  const { gridData, handleAddRow, handleCellEdit } = useDynamicGridWithEmptyFirstRow<LcaCustomResultGridDataModel>({
    rows,
    createMutation,
    updateMutation,
    emptyEntity: {
      lcaCustomResultId: customResultId,
      indicatorName: '',
      impactCategory: '',
      unit: '',
      ...emptyLCAModuleValues,
    },
  });

  const header = (
    <DynamicGridHeader
      caption={title}
      onAddRow={handleAddRow}
      onMaximize={visible ? undefined : () => setVisible(true)}
      onDeleteRows={handleDeleteSelected}
      deleteRowsDisabled={isDeleteDisabled}
      hideMutationButtons={isReadOnly && preferEnabledAppearanceEvenWhenDisabled}
    />
  );

  const footerGroup = (
    <ColumnGroup>
      {editableFields.map((x, i) => (
        <Row key={i}>
          <Column footer={x.label} colSpan={2} className="p-row-note" />
          <Column
            style={{ padding: '2px' }}
            footer={
              <ColumnFooterInputText
                type="text"
                defaultValue={x.value as string}
                onBlur={(e) => {
                  x.fieldName && onChangedCustomResult && onChangedCustomResult(x.fieldName, e.target.value);
                }}
                disabled={isReadOnly}
                placeholder={x.placeholder}
              />
            }
            colSpan={columns.length}
            className={isReadOnly ? 'p-row-note' : undefined}
          />
        </Row>
      ))}
      {notes.map((x, i) => (
        <Row key={i}>
          <Column footer={x.note} colSpan={2} className="p-row-note" />
          <Column footer={x.description} colSpan={columns.length} className="p-row-note" />
        </Row>
      ))}
    </ColumnGroup>
  );

  return (
    <>
      <MaximizedDialog title="" visible={visible} onHide={() => setVisible(false)}>
        <DataTableStyled
          key={gridId}
          value={gridData}
          dataKey="ord"
          tableStyle={{ minWidth: '49rem' }}
          showGridlines
          footerColumnGroup={footerGroup}
          header={header}
          editMode="cell"
          rowClassName={rowClassName}
          {...dataTableProps}
        >
          {!(isReadOnly && preferEnabledAppearanceEvenWhenDisabled) && checkboxColumnNode}

          {columns.map((x) => (
            <Column
              key={x.field}
              {...x}
              onCellEditComplete={x.editor ? handleCellEdit : undefined}
              {...(isReadOnly && { editor: undefined })}
            />
          ))}
        </DataTableStyled>
      </MaximizedDialog>

      <DataTableStyled
        value={gridData}
        dataKey="ord"
        tableStyle={{ minWidth: '49rem' }}
        showGridlines
        footerColumnGroup={footerGroup}
        header={header}
        editMode="cell"
        rowClassName={rowClassName}
        {...dataTableProps}
      >
        {!(isReadOnly && preferEnabledAppearanceEvenWhenDisabled) && checkboxColumnNode}

        {columns.map((x) => (
          <Column
            key={x.field}
            {...x}
            onCellEditComplete={x.editor ? handleCellEdit : undefined}
            {...(isReadOnly && { editor: undefined })}
          />
        ))}
      </DataTableStyled>
    </>
  );
};

export default LcaCustomResultsDynamicGrid;
