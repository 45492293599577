import React from 'react';
import VersionHistorySection from '../VersionHistorySection';
import { useEpdVersionHistoryDataOrWhileLoadingThenNull } from '../utils/EpdVersionHistoryDataContext';
import VersionHistorySummaryGrid, { SummaryGridRow, VersionHistorySummaryGridSkeleton } from '../VersionHistorySummaryGrid';
import PackagingMaterialsGridPdf from '../../grids/product-content/PackagingMaterialsGridPdf';
import { EPDContentDeclarationProductType, EPDDeclareContentProductModel, EPDProductType } from 'types/types';
import { ISO_21930_ID, SINGLE_PRODUCT_ID, YES_NO } from '../../constants';
import ProductComponentsGridPdf from '../../grids/product-content/ProductComponentsGridPdf';
import PanelContentGridPdf from '../../grids/product-content/PanelContentGridPdf';
import DangerMaterialsGridPdf from '../../grids/product-content/DangerMaterialsGridPdf';

const VersionHistoryContentDeclarationSection: React.FC<{}> = () => {
  const data = useEpdVersionHistoryDataOrWhileLoadingThenNull();
  const NOT_ASSIGNED = 'N/A';
  const hasDangerMaterialsLookup = [
    { value: false, label: YES_NO.NO },
    { value: true, label: YES_NO.YES },
  ];

  if (!data) {
    return (
      <VersionHistorySection title="Content declaration">
        <VersionHistorySummaryGridSkeleton skeletonRowsCount={4} />
        <VersionHistorySummaryGridSkeleton skeletonRowsCount={4} />
        <VersionHistorySummaryGridSkeleton skeletonRowsCount={4} />
      </VersionHistorySection>
    );
  }

  const isISO21930StandardSelected =
    data.epdDefinitionInformation.declaredStandards?.split(',').includes(ISO_21930_ID) ?? false;
  const isPanelContentEnabled =
    data.pcrInformation.cpcr?.registrationNumber?.toLocaleLowerCase()?.includes('c-pcr-014') ?? false;

  const averageProductContentDeclaration =
    data.contentDeclarationProducts.find((x) => x.type === EPDContentDeclarationProductType.Average) ?? null;
  const bestProductContentDeclaration =
    data.contentDeclarationProducts.find((x) => x.type === EPDContentDeclarationProductType.BestProduct) ?? null;
  const worstProdutContentDeclaration =
    data.contentDeclarationProducts.find((x) => x.type === EPDContentDeclarationProductType.WorstProduct) ?? null;
  const isBestCaseEnabled =
    !!bestProductContentDeclaration?.productDescription &&
    data.epdDefinitionInformation.epdClassification != SINGLE_PRODUCT_ID;
  const isWorstCaseEnabled =
    !!worstProdutContentDeclaration?.productDescription &&
    data.epdDefinitionInformation.epdClassification != SINGLE_PRODUCT_ID;

  const averagePossibleRows: (SummaryGridRow | false)[] = [
    data.epdDefinitionInformation.epdClassification != SINGLE_PRODUCT_ID && {
      name: 'Content declaration of multiple products',
      value: averageProductContentDeclaration?.productDescription ?? NOT_ASSIGNED,
    },
    {
      name: 'Does the service include any physical products used within the defined system boundary?',
      value: data.epdDefinitionInformation.serviceIncludesProductInSystemBoundary ?? NOT_ASSIGNED,
    },
    data.epdDefinitionInformation.serviceIncludesProductInSystemBoundary === 'No' && {
      name: 'Explanation why content declaration is not relevant',
      value: data.epdDefinitionInformation.declarationIrrelevanceExplanation ?? NOT_ASSIGNED,
    },
    {
      name: 'The product contains substances in the list of SVHC that constitute more than 0.1% of its weight',
      value:
        hasDangerMaterialsLookup.find((x) => x.value == averageProductContentDeclaration?.hasDangerMaterials)?.label ??
        NOT_ASSIGNED,
    },
  ];

  const averageRows = averagePossibleRows.filter((r) => r) as SummaryGridRow[];

  const renderContentDeclarationSection = ({
    title,
    product,
  }: {
    title: string;
    product: EPDDeclareContentProductModel;
  }) => {
    return (
      <VersionHistorySection title={title}>
        {product?.type === EPDContentDeclarationProductType.Average ? (
          <VersionHistorySummaryGrid rows={averageRows} />
        ) : (
          <VersionHistorySummaryGrid
            rows={[
              { name: 'Product description', value: product.productDescription ?? NOT_ASSIGNED },
              {
                name: 'The product contains substances in the list of SVHC that constitute more than 0.1% of its weight',
                value: hasDangerMaterialsLookup.find((x) => x.value == product?.hasDangerMaterials)?.label ?? NOT_ASSIGNED,
              },
            ]}
          />
        )}

        <PackagingMaterialsGridPdf
          epdVersionId={data.epdVersionId}
          serviceIncludesProductInSystemBoundary={data.epdDefinitionInformation.serviceIncludesProductInSystemBoundary}
          contentDeclarationType={product.type}
          isISO21930StandardSelected={isISO21930StandardSelected}
        />

        {isPanelContentEnabled && (
          <PanelContentGridPdf
            epdVersionId={data.epdVersionId}
            contentDeclarationType={product.type}
            isISO21930StandardSelected={isISO21930StandardSelected}
          />
        )}

        <ProductComponentsGridPdf
          epdVersionId={data.epdVersionId}
          serviceIncludesProductInSystemBoundary={data.epdDefinitionInformation.serviceIncludesProductInSystemBoundary}
          contentDeclarationType={product.type}
          isISO21930StandardSelected={isISO21930StandardSelected}
        />

        {averageProductContentDeclaration?.hasDangerMaterials && (
          <DangerMaterialsGridPdf epdVersionId={data.epdVersionId} contentDeclarationType={product.type} />
        )}
      </VersionHistorySection>
    );
  };

  return (
    <>
      {averageProductContentDeclaration &&
        renderContentDeclarationSection({
          title: 'Content declaration',
          product: averageProductContentDeclaration,
        })}

      {isBestCaseEnabled &&
        renderContentDeclarationSection({
          title: 'Content declaration - Best-case product',
          product: bestProductContentDeclaration,
        })}

      {isWorstCaseEnabled &&
        renderContentDeclarationSection({
          title: 'Content declaration - Worst-case product',
          product: worstProdutContentDeclaration,
        })}
    </>
  );
};

export default VersionHistoryContentDeclarationSection;
