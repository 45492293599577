import logo from 'images/EPD-logo-au.png';
import { css } from 'styled-components';

import { Theme, defaultTypography } from '.';

const colors = {
  lightGray: '#eeeeee',
  gray: 'rgb(217,218,219)',
  mediumGrey: '#b6b6b6',
  darkGray: 'rgb(142,142,142)',
  lightGreen: 'rgb(165, 204, 178)',
  green: 'rgb(101, 133, 108)',
  mediumGreen: 'rgb(19,95,81)',
  lightOrange: '#EB7342',
  orange: 'rgb(231,81,19)',
  darkOrange: '#cf4811',
  driedGreen: 'rgb(108,140,116)',
  darkKhaki: '#CACF85',
  shadedRed: '#E65F5C',
  black: '#000000',
  regionColorLight: 'rgb(254, 201, 114)',
  regionColor: 'rgb(255, 172, 51)',
  regionColorMedium: 'rgb(241, 150, 0)',
  regionColorDark: '#000000',
  button: '#3386FF',
  buttonHover: 'rgb(165, 204, 278)',
  toolBoxImageFilter: 'invert() brightness(0.4) sepia(1) hue-rotate(0deg) saturate(7);',
  // toolkit
  // primary
  primaryBlack: '#151515',
  primaryBlack50: 'rgba(21, 21, 21, 0.5);',
  primaryBlack70: 'rgba(21, 21, 21, 0.7);',
  primaryBlack80: 'rgba(21, 21, 21, 0.8);',
  primaryWhite: '#FFFFFF',
  primaryOrange: '#E75113',
  primaryOrange01: 'rgba(231, 81, 19, 0.1)',
  primaryGreen: '#1E6052',
  // secondary
  secondaryMiddleGreen: '#6F896A',
  secondaryLightGreen: '#B4D0B6',
  secondaryLightGreen30: 'rgba(180, 208, 182, 0.3);',
  secondaryGray: '#D9DADB',
  // system status
  systemStatusError: '#E20238',
  systemStatusSuccess: '#02C038',
  systemStatusAttention: '#F5DD00',
  systemStatusUnknown: '#EBECF4',
  // status labels
  statusLabelRedText: '#CC302B',
  statusLabelRedBg: '#F6E5E4',
  statusLabelYellowText: '#864A14',
  statusLabelYellowBg: '#FBEBBA',
  statusLabelGreenText: '#1E6052',
  statusLabelGreenBg: '#E2EBE3',
  statusLabelGrayText: '#7F8081',
  statusLabelGrayBg: '#E3E3E3',
  statusLabelBlueText: '#223B60',
  statusLabelBlueBg: '#EBF9FF',
  // elements
  elementsButtonHover: '#FA8656',
  elementsButtonDisabled: '#757575',
  elementsFilledInput: '#F3F4FB',
  elementsInputBorder: '#E0E0E0',
  elementsTableRow: '#F9F9FF',
  elementsDropDown: '#EAEAED',
  elementDropdownSelectedDisabled: '#495057',
};

const sizes = {
  small: 320,
  medium: 720,
  large: 1024,
  xlarge: 1200,
};

const breakpoints = {
  xsmall: 0,
  small: `${sizes.small}px`,
  medium: `${sizes.medium}px`,
  large: `${sizes.large}px`,
  xlarge: `${sizes.xlarge}px`,
};

const customMediaQuery = (minWidth: number) => `@media (min-width: ${minWidth}px)`;
const customMatchMedia = (minWidth: number) => `(min-width: ${minWidth}px)`;
const media = {
  custom: customMediaQuery,
  phone: customMediaQuery(sizes.small),
  tablet: customMediaQuery(sizes.medium),
  desktop: customMediaQuery(sizes.xlarge),
  largeDesktop: customMediaQuery(sizes.xlarge),
};

const matchMedia = {
  custom: customMatchMedia,
  phone: customMatchMedia(sizes.small),
  tablet: customMatchMedia(sizes.medium),
  desktop: customMatchMedia(sizes.xlarge),
};

const fontFamilies = {
  heading: 'Inter',
  body: 'Inter',
};

const fontSizes = {
  xlarge: 4,
  large: 2,
  medium: 1.5,
  mediumish: 1.25,
  mediumSmall: 1.125,
  standard: 1,
  small: 0.875,
  smallDescription: 0.75,
};

const fonts = {
  headingHero: css`
    font-family: ${fontFamilies.heading};
    font-size: ${fontSizes.xlarge}rem;
  `,
  heading1: css`
    font-family: ${fontFamilies.heading};
    font-size: ${fontSizes.large}rem;
  `,
  heading2: css`
    font-family: ${fontFamilies.heading};
    font-size: ${fontSizes.medium}rem;
  `,
  heading3: css`
    font-family: ${fontFamilies.heading};
    font-size: ${fontSizes.mediumish}rem;
  `,
  heading4: css`
    font-family: ${fontFamilies.heading};
    font-size: ${fontSizes.standard}rem;
  `,
  headingDialog: css`
    font-family: ${fontFamilies.heading};
    font-size: ${fontSizes.mediumSmall}rem;
  `,
  text: css`
    font-family: ${fontFamilies.body};
    font-size: ${fontSizes.standard}rem;
    text-transform: none;
  `,
  textSmall: css`
    font-family: ${fontFamilies.body};
    font-size: ${fontSizes.small}rem;
  `,
  textSmallDescription: css`
    font-family: ${fontFamilies.body};
    font-size: ${fontSizes.smallDescription}rem;
  `,
  textLarge: css`
    font-family: ${fontFamilies.body};
    font-size: ${fontSizes.mediumish}rem;
  `,
};

const variables = {
  headerHeight: '90px',
};

const verifiersUrl = 'https://epd-australasia.com/about-us/certified-verifiers/';
const serviceAgreementUrl = '/EPD-Australasia-fees-terms-conditions-1-April-2023.pdf';

const theme: Theme = {
  fontSizes: fontSizes,
  fonts: fonts,
  fontFamilies: fontFamilies,
  colors: colors,
  typography: { ...defaultTypography },
  breakpoints,
  variables,
  media,
  matchMedia,
  logo,
  verifiersUrl,
  serviceAgreementUrl,
};

export default theme;
