import React from 'react';
import PcrInformationPanel from './panels/pcr-information/PcrInformationPanel';
import LcaSupportPanel from './panels/lca-support/LcaSupportPanel';
import ThirdPartyVerificationPanel from './panels/third-party-verification/ThirdPartyVerificationPanel';
import { useGetEpdLcaSupport, useGetEpdPCRInformation, useGetEpdThirdPartyVerification } from 'services/api/queries';
import SkeletonPcrLcaAndVerificationTab from './skeletons/SkeletonPcrLcaAndVerificationTab';

const PcrLcaAndVerificationTab: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  validationState?: any;
}> = ({ epdId, epdVersionId, validationState }) => {
  const pcrInformation = useGetEpdPCRInformation(epdVersionId).data;
  const lcaSupportInformation = useGetEpdLcaSupport(epdVersionId).data;
  const thirdPartyVerification = useGetEpdThirdPartyVerification(epdVersionId).data;

  const isLoading =
    validationState === undefined ||
    pcrInformation === undefined ||
    lcaSupportInformation === undefined ||
    thirdPartyVerification === undefined;
  if (isLoading) {
    return <SkeletonPcrLcaAndVerificationTab />;
  }

  return (
    <>
      <PcrInformationPanel
        epdId={epdId}
        epdVersionId={epdVersionId}
        pcrInformation={pcrInformation}
        validationState={validationState}
      />
      <LcaSupportPanel
        epdId={epdId}
        epdVersionId={epdVersionId}
        lcaSupportInformation={lcaSupportInformation}
        validationState={validationState}
      />
      <ThirdPartyVerificationPanel
        epdId={epdId}
        epdVersionId={epdVersionId}
        thirdPartyVerification={thirdPartyVerification}
        validationState={validationState}
      />
    </>
  );
};

export default PcrLcaAndVerificationTab;
