import { AdminPreverifiedToolsClient } from '../EpdClient';

const client = new AdminPreverifiedToolsClient(process.env.REACT_APP_API_URL);

class AdminPreverifiedToolsService {
  static findTools = async (filter?: string) => {
    return await client.getAll(filter);
  };

  static getTool = async (id?: string) => {
    return await client.get(id as any);
  };

  static updateTool = async (id: string, values: any) => {
    return await client.update(id, values);
  };

  static createTool = async () => {
    return await client.create();
  };
}

export default AdminPreverifiedToolsService;
