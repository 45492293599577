import React from 'react';
import { EPDAdditionalInformationModel } from 'types/types';

import { PDF_H2, PDF_P, PDF_PAGE } from './Styles.styled';

const AdditionalInfoPage: React.FunctionComponent<{
  versionDescription?: string;
  additionalInformation?: EPDAdditionalInformationModel | null;
}> = ({ versionDescription, additionalInformation }) => {
  return (
    <PDF_PAGE>
      <PDF_H2>Version history</PDF_H2>
      <PDF_P>{versionDescription}</PDF_P>

      <PDF_H2>Additional environmental information</PDF_H2>
      <PDF_P dangerouslySetInnerHTML={{ __html: additionalInformation?.environmental ?? '' }} />

      <PDF_H2>Conversion factors</PDF_H2>
      <PDF_P dangerouslySetInnerHTML={{ __html: additionalInformation?.conversion ?? '' }} />

      <PDF_H2>Dangerous substance to indoor air, soil, and water during the use stage</PDF_H2>
      <PDF_P dangerouslySetInnerHTML={{ __html: additionalInformation?.dangerous ?? '' }} />

      <PDF_H2>Economic and social information</PDF_H2>
      <PDF_P dangerouslySetInnerHTML={{ __html: additionalInformation?.economic ?? '' }} />
    </PDF_PAGE>
  );
};

export default AdditionalInfoPage;
