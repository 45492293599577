import React from 'react';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Skeleton } from 'primereact/skeleton';

const SkeletonContentDeclarationTab: React.FC<{}> = () => {
  return (
    <FieldPanelFullWidth>
      <Skeleton width="10rem" />

      <Skeleton height="8rem" />

      <div>
        <Skeleton width="20rem" />
        <Skeleton height="2rem" className="mt-2" />
      </div>

      <div>
        <Skeleton width="20rem" />
        <Skeleton height="2rem" className="mt-2" />
      </div>

      <Skeleton height="25rem" />

      <Skeleton height="25rem" />
    </FieldPanelFullWidth>
  );
};

export default SkeletonContentDeclarationTab;
