import { useState } from 'react';
import { ActionState, EPDModel, FlowModel, FlowPropertyModel, MaterialPropertyModel } from 'services/EpdClient';

import EditMachineReadableDialog from '../../dialogs/EditMachineReadableDialog';
import EpdDetailsGrid, { EpdDetailsGridRow } from '../EpdDetailsGrid';
import MachineReadableEPDKey from '../MachineReadableEPDKey';
import { useReferencePackageVersions15804 } from 'services/api/queries';
import { EPDBasedOnUnitEnum } from 'types/types';

type TProps = {
  epd: EPDModel;
  onChangeEpd: any;
  isReadOnly: boolean;
  isConstruction?: boolean;
  flowProperties: FlowPropertyModel[];
  materialProperties: MaterialPropertyModel[];
};

const MachineReadable = ({ epd, onChangeEpd, isReadOnly, isConstruction, flowProperties, materialProperties }: TProps) => {
  const [isMachineReadableEditOpened, setIsMachineReadableOpened] = useState<boolean>(false);
  const referencePackageVersions15804 = useReferencePackageVersions15804().data;

  const renderReferenceFlowData = (productFlow: FlowModel | undefined) => {
    const flowPropertyName = flowProperties.find((x) => x.id == productFlow?.flowPropertyItem?.flowPropertyId)?.name;
    const conversionFactor = productFlow?.flowPropertyItem?.conversionFactor;
    if (!productFlow?.name || !flowPropertyName || !conversionFactor) {
      return null;
    }
    return `${productFlow?.name}<br />Unit: ${flowPropertyName}, Value: ${conversionFactor}`;
  };

  const detailsGridData: (EpdDetailsGridRow | null)[] = [
    { key: MachineReadableEPDKey.GTIN, value: epd.gtin },
    {
      key: MachineReadableEPDKey.REFERENCE_FLOWS,
      value: renderReferenceFlowData(epd.productFlow),
      isHtml: true,
      required: isConstruction,
    },
    {
      key: MachineReadableEPDKey.MATERIAL_PROPERTIES,
      value: (() => {
        const epdMaterialProperties = epd.productFlow?.materialPropertyItems;
        if (!epdMaterialProperties) {
          return '';
        }

        const formattedProperties = epdMaterialProperties.map((p) => {
          const propertyNameAndUnit = materialProperties.find((m) => m.id === p.materialPropertyId)?.name ?? '-';
          return `Property (Unit): ${propertyNameAndUnit}, Value: ${p.value}`;
        });

        const shouldFormatAsHtmlList = epdMaterialProperties.length >= 2;
        if (shouldFormatAsHtmlList) {
          return formattedProperties.map((p) => `<li>${p}</li>`).join('');
        }

        return formattedProperties.join('');
      })(),
      isHtml: true,
    },
    { key: MachineReadableEPDKey.GEOGRAPHICAL_SCOPE_DESCRIPTION, value: epd.geographicalDescription },
    {
      key: MachineReadableEPDKey.TECHNOLOGY_DESCRIPTION,
      value: epd.technologyDescription,
      required: isConstruction,
    },
    { key: MachineReadableEPDKey.TECHNICAL_PURPOSE, value: epd.technicalPurpose, required: isConstruction },
    { key: MachineReadableEPDKey.SUBTYPE, value: epd.subtype },
    {
      key: MachineReadableEPDKey.DATA_SOURCES,
      value: epd.epdDataSources
        ?.map(
          (x) => `${(epd.epdDataSources?.length || 0) > 1 ? '<li>' : ''}${x.dataSource.name}, ${x.dataSourceVersion.name}`
        )
        .join(''),
      isHtml: true,
      required: isConstruction,
    },
    isConstruction
      ? {
          key: MachineReadableEPDKey.REFERENCE_PACKAGE_VERSION_15804,
          value: referencePackageVersions15804?.versions.find((v) => v.id === epd.referencePackageVersion15804)?.name ?? '',
          required: true,
        }
      : null,
    isConstruction
      ? {
          key: MachineReadableEPDKey.CHARACTERISATION_METHODS,
          value: epd.characterisationMethods,
        }
      : null,
    { key: MachineReadableEPDKey.USE_ADVICE, value: epd.useAdvice, required: isConstruction },
    { key: MachineReadableEPDKey.EPD_BASED_ON_UNIT, value: epd?.epdBasedOnUnit, required: isConstruction },
    epd?.epdBasedOnUnit === EPDBasedOnUnitEnum.FunctionalUnit
      ? {
          key: MachineReadableEPDKey.FUNCTIONAL_UNIT_DESCRIPTION,
          value: epd?.functionalUnitDescription,
          required: true,
        }
      : null,
  ];

  return (
    <>
      <EpdDetailsGrid
        headerLabel={'Information for machine-readable EPDs'}
        isReadOnly={isReadOnly}
        data={detailsGridData.filter((d) => d !== null) as EpdDetailsGridRow[]}
        onEdit={() => setIsMachineReadableOpened(true)}
      />
      <EditMachineReadableDialog
        isOpened={isMachineReadableEditOpened}
        isConstruction={isConstruction}
        epd={epd}
        onHide={() => setIsMachineReadableOpened(false)}
        onChangeEpd={onChangeEpd}
        flowProperties={flowProperties}
        materialProperties={materialProperties}
      />
    </>
  );
};

export default MachineReadable;
