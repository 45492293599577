import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { TextButton } from 'components/v2/buttons';
import WizardSelectControlledComponent from 'components/v2/epd-wizard-inputs/WizardSelectControlled';
import WizardTextInputControlledComponent from 'components/v2/epd-wizard-inputs/WizardTextInputControlled';
import { LabelGroup } from 'components/v2/form/styled';
import { HelpBox } from 'components/v2/help-boxes';
import { MaterialPropertyItemModel, MaterialPropertyModel } from 'services/EpdClient';
import { FieldContainer, FieldPanel, FieldPanelInput } from 'styles/v2/Styles.styled';

type Props = {
  materialProperties: MaterialPropertyModel[];
  epdMaterialProperties: MaterialPropertyItemModel[] | undefined;
  onSave(newItems: MaterialPropertyItemModel[]): Promise<void>;
};

const MaterialPropertiesCopySection: React.FC<Props> = ({ materialProperties, epdMaterialProperties, onSave }) => {
  // Unlike compiler, material properties of the old wizard & copy function don't allow empty values in either of the fields.
  // That's why we can't constantly sync data with server, otherwise items which are being edited, will be overwritten.
  // Anyway, the incomplete items will be lost when the page is refreshed...
  const [ephemeralRows, setEphemeralRows] = useState<MaterialPropertyItemModel[] | undefined>(epdMaterialProperties);
  const { t } = useTranslation();
  const saveMutation = useMutation({
    mutationFn: onSave,
    scope: { id: '1' }, // Just to prevent mutations from runnig concurrently
  });

  useEffect(() => {
    if (!ephemeralRows && epdMaterialProperties) {
      setEphemeralRows(epdMaterialProperties);
    }
  }, [epdMaterialProperties]);

  const handleChangeProperty = (ephemeralIndex: number, propertyName: string, newValue: any) => {
    if (!ephemeralRows) {
      return undefined;
    }

    const newRows = ephemeralRows.map((old, i) => (i === ephemeralIndex ? { ...old, [propertyName]: newValue } : old));
    setEphemeralRows(newRows);
    return newRows;
  };

  const handleCreateRow = () => {
    if (!ephemeralRows) {
      return;
    }

    const newRows = [...ephemeralRows, { materialPropertyId: undefined, value: undefined }];
    setEphemeralRows(newRows);
  };

  const handleDeleteRow = (ephemeralIndex: number) => {
    if (!ephemeralRows) {
      return;
    }

    const newRows = ephemeralRows.filter((_, i) => i !== ephemeralIndex);
    setEphemeralRows(newRows);
    handleSave(newRows);
  };

  const handleSave = (newData: MaterialPropertyItemModel[]) => {
    const completeRows = newData.filter((d) => typeof d.materialPropertyId === 'string' && typeof d.value === 'number');
    saveMutation.mutate(completeRows);
  };

  return (
    <>
      <FieldContainer>
        <FieldPanelInput>
          <LabelGroup>
            <span style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>MATERIAL PROPERTIES</span>
            <i className="pi pi-info-circle" />
          </LabelGroup>
          <HelpBox>
            Declaration of relevant non-scaling physical or chemical product properties. Up to six properties can be added
            with the following units.
            <br />
            Example: Property (Unit): Bulk density (kg/m³). Value: 1
          </HelpBox>
        </FieldPanelInput>
      </FieldContainer>
      <FieldPanel style={{ rowGap: 0 }}>
        {ephemeralRows?.map((item, index) => (
          <React.Fragment key={index}>
            <WizardSelectControlledComponent
              options={materialProperties
                .filter((prop) => !ephemeralRows.find((row) => row.materialPropertyId === prop.id))
                .map((x) => ({ value: x.id, label: x.name }))}
              value={
                materialProperties
                  .filter((p) => p.id === item.materialPropertyId)
                  .map((x) => ({ value: x.id, label: x.name }))[0]
              }
              label="Property(unit)"
              tooltip="Select unit and provide a value."
              isClearable={true}
              name="materialPropertyId"
              onChanged={(propertyName: string, value: any) => {
                const newData = handleChangeProperty(index, propertyName, value);
                if (newData) {
                  handleSave(newData);
                }
              }}
            />
            <div className="flex">
              <WizardTextInputControlledComponent
                value={item.value}
                type="number"
                label="value"
                tooltip="Numeric field"
                placeholder={t('placeholder.insertNumber')}
                name="value"
                onChange={(e) => handleChangeProperty(index, 'value', Number(e.target.value))}
                onChanged={() => handleSave(ephemeralRows)}
              />

              <TextButton
                style={{ alignSelf: 'flex-end', margin: '0.25rem' }}
                type="button"
                text
                icon="pi pi-trash"
                onClick={() => handleDeleteRow(index)}
              />
            </div>
          </React.Fragment>
        ))}
      </FieldPanel>
      {ephemeralRows && ephemeralRows.length < 6 && (
        <TextButton
          className="white-space-nowrap"
          style={{ cursor: 'pointer', paddingTop: '3px' }}
          label={'+ Add property'}
          text
          onClick={handleCreateRow}
        />
      )}
    </>
  );
};

export default MaterialPropertiesCopySection;
