import { LcaResultModel } from 'types/types';
import LcaResultsGrid from './LcaResultsGrid';
import LcaResultsGridPdf from './LcaResultsGridPdf';
import {
  createVariationColumnWithDisclaimer,
  createVariationDisclaimerNote,
  LcaResultsGridColumns,
  LcaResultsGridColumnsPdf,
} from './LcaResultsGridColumns';
import useLcaGridHelper from '../../hooks/useLcaGridHelper';
import { LcaGridNote } from '../GridService';

type TProps = {
  epdVersionId: string;
  rows: LcaResultModel[] | undefined;
  isForPdf?: boolean;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};

const LcaResultsGridAdditionalMandatory = ({
  epdVersionId,
  rows,
  isForPdf,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
}: TProps) => {
  const { generalDisclaimerNote, showVariationColumn } = useLcaGridHelper(epdVersionId);

  const notes: LcaGridNote[] = [
    {
      note: 'Acronyms',
      description: 'GWP-GHG = Global warming potential greenhouse gas.',
    },
  ];

  if (generalDisclaimerNote) {
    notes.push(generalDisclaimerNote);
  }

  notes.push({
    note: (
      <span>
        Disclaimer<sup>1</sup>
      </span>
    ),
    description:
      'The GWP-GHG indicator is termed GWP-IOBC/GHG in the ILCD+EPD+ data format. The indicator accounts for all greenhouse gases except biogenic carbon dioxide uptake and emissions and biogenic carbon stored in the product. As such, the indicator is identical to GWP-total except that the CF for biogenic CO2 is set to zero.',
  });

  let columns = isForPdf ? LcaResultsGridColumnsPdf : LcaResultsGridColumns;
  if (showVariationColumn && (!isForPdf || (isForPdf && rows?.some((r) => r.variation)))) {
    const disclaimerNumber = 2;
    columns = [...columns, createVariationColumnWithDisclaimer(disclaimerNumber)];
    notes.push(createVariationDisclaimerNote(disclaimerNumber));
  }

  if (isForPdf) {
    return <LcaResultsGridPdf rows={rows} columns={columns} notes={notes} />;
  }
  return (
    <LcaResultsGrid
      epdVersionId={epdVersionId}
      value={rows}
      columns={columns}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaResultsGridAdditionalMandatory;
