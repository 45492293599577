import WizardSelectControlledComponent from 'components/v2/epd-wizard-inputs/WizardSelectControlled';
import FilesList from 'components/v2/file-row/FilesList';
import FileUpload from 'components/v2/file-upload/FileUpload';
import { HelpBox } from 'components/v2/help-boxes';
import { ButtonUploadIcon } from 'components/v2/icons';
import SmartLabel from 'components/v2/labels/SmartLabel';
import { EPD_DEVELOPMENT_VERIFICATION_TEMPLATES_URL } from 'constants/constants';
import { ChangeEvent, useEffect, useState } from 'react';
import { createFilter } from 'react-select';
import { EPDModel, FileLoadingModel, LanguageModel, UploadDocumentTypesModel } from 'services/EpdClient';
import LanguagesService from 'services/LanguagesService';
import UploadDocumentTypesService from 'services/UploadDocumentTypesService';
import styled from 'styled-components';
import { HyperLink } from 'styles/Styles.styled';
import { Option } from 'types/types';

import EditDialog from './EditDialog';

type OptionWithCode = Option & { code?: string };

type TProps = {
  epd: EPDModel;
  isOpened: boolean;
  header: string;
  onHide: any;
  onChangeEpd: any;
  isConstruction?: boolean;
  fileLoading?: FileLoadingModel[];
};

const UploadDocumentsDialog = ({ isOpened, onHide, epd, onChangeEpd, isConstruction, fileLoading }: TProps) => {
  const [languagesLookup, setLanguagesLookup] = useState<OptionWithCode[]>([]);
  const [documentTypesLookup, setDocumentTypesLookup] = useState<Option[]>([]);
  const [documentType, setDocumentType] = useState<Option>();
  const [documentLanguage, setDocumentLanguage] = useState<OptionWithCode>();

  useEffect(() => {
    const fetchLanguages = async () => {
      const result = await LanguagesService.getLanguages();
      setLanguagesLookup(
        result?.map((x: LanguageModel) => {
          return { value: x.id, label: x.name, code: x.code };
        })
      );
    };

    const fetchDocumentTypes = async () => {
      const result = await UploadDocumentTypesService.getDocumentTypes(epd.isCompilerFormat);
      setDocumentTypesLookup(
        result?.map((x: UploadDocumentTypesModel) => {
          return { value: x.id, label: x.name };
        })
      );
    };

    fetchLanguages();
    fetchDocumentTypes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];
    if (!file || !epd) {
      return null;
    }

    onChangeEpd && (await onChangeEpd(propertyName, file));
  };

  const renameAndUploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];

    if (!file || !epd) {
      return null;
    }

    if (documentType?.value !== '2489c33d-56f6-4fe7-b5cd-a94e82900708') {
      propertyName = 'DocumentOther';
    }

    let newName = renameDocument(file?.name.split('.').pop() || '');
    onChangeEpd && onChangeEpd(propertyName, new File([file], newName, { type: file.type }));
    // @ts-ignore
    setDocumentType(null);
    // @ts-ignore
    setDocumentLanguage(null);
  };

  const renameDocument = (fileExtension: string) => {
    let dublicates = 0;
    let newName = '';
    let nameFound = false;
    while (!nameFound) {
      let numDoc = dublicates === 0 ? '' : `(${dublicates})`;
      newName = `${documentType?.label} ${
        epd?.fullIdentificationNumber
      } ${documentLanguage?.code?.toLocaleUpperCase()}${numDoc}.${fileExtension}`;
      if (
        epd?.documents &&
        epd.documents.filter((x) => x.name?.toLocaleLowerCase() === newName.toLocaleLowerCase()).length > 0
      ) {
        dublicates += 1;
      } else nameFound = true;
    }

    return newName;
  };

  const isLCALoading = fileLoading?.find((loading) => loading.fileType === 'LCA' && loading.loadingsCount > 0) !== undefined;

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'start' as const,
  };

  return (
    <EditDialog
      header="Upload documents"
      isOpened={isOpened}
      onHide={() => {
        setDocumentLanguage(undefined);
        setDocumentType(undefined);
        onHide();
      }}
    >
      <HelpBox>
        Several EPD documents could be uploaded in a PDF format.
        <br />
        Please note, the EPD document is a mandatory type.
        <br />
        All uploaded documents will be auto saved.
      </HelpBox>

      <FieldPanel>
        <WizardSelectControlledComponent
          label="Document type"
          tooltip=""
          name="docType"
          options={documentTypesLookup}
          required
          value={documentType}
          onChanged={(name: string, value: string) =>
            setDocumentType(
              value ? { value: value, label: documentTypesLookup.find((x) => x.value == value)?.label || value } : undefined
            )
          }
          isClearable={true}
        />
        <WizardSelectControlledComponent
          label="Document language"
          tooltip=""
          name="docLang"
          options={languagesLookup}
          required
          value={documentLanguage}
          onChanged={(name: string, value: string) =>
            setDocumentLanguage({
              value: value,
              label: languagesLookup.find((x) => x.value == value)?.label || value,
              code: languagesLookup.find((x) => x.value == value)?.code,
            })
          }
          isClearable={false}
          filterOption={createFilter(filterConfig)}
        />
      </FieldPanel>

      <FileUpload
        label="Upload document"
        name="Document"
        icon={ButtonUploadIcon}
        accept=".pdf"
        validExtensions={['pdf']}
        onFileUpload={renameAndUploadFile}
        disabled={!(documentLanguage && documentType)}
      />
      <FilesList
        files={epd?.documents}
        onRemoveFile={onChangeEpd}
        fileLoading={
          fileLoading?.find(
            (loading) =>
              (loading.fileType === 'Document' || loading.fileType === 'DocumentOther') && loading.loadingsCount > 0
          ) !== undefined
        }
      />

      <div style={{ marginTop: '1.75rem' }}>
        <SmartLabel label="LCA results" required={isConstruction} hasValue={(epd?.lcAs?.length || 0) > 0} />

        <HelpBox>
          <span>
            Please upload your LCA results in XLSX format using the{' '}
            <HyperLink href={EPD_DEVELOPMENT_VERIFICATION_TEMPLATES_URL} target="_blank" rel="noopener noreferrer">
              {'template'}
            </HyperLink>
            <br />
            Only one set of LCA results can be uploaded per EPD.
          </span>
        </HelpBox>
      </div>

      <FileUpload
        label={'Upload LCA results'}
        icon={ButtonUploadIcon}
        accept=".xlsx"
        validExtensions={['xlsx']}
        name={'LCA'}
        onFileUpload={uploadFile}
        disabled={isLCALoading || (epd?.lcAs?.length || 0) > 0}
      />
      <FilesList files={epd?.lcAs} onRemoveFile={onChangeEpd} fileLoading={isLCALoading} />
    </EditDialog>
  );
};

const FieldPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: start;
  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 1fr 1fr;
  }
`;

export default UploadDocumentsDialog;
