import React, { useEffect, useRef } from 'react';

interface RenderCounterProps {
  componentName?: string;
  threshold?: number;
}

const RenderCounter: React.FC<RenderCounterProps> = ({ componentName = 'Component', threshold = 0 }) => {
  const renderCount = useRef(0);
  renderCount.current += 1;

  if (renderCount.current > 1) {
    // eslint-disable-next-line
    console.info(`${componentName} render count: ${renderCount.current}`);
  }

  if (threshold > 0 && renderCount.current < threshold) {
    return null;
  }

  return <div style={{ color: 'red', position: 'absolute' }}>{`${componentName} Render Count: ${renderCount.current}`}</div>;
};

export default RenderCounter;
