import { withActionState } from 'pages/v2/WithActionState';
import { Button } from 'primereact/button';
import { ScrollTop } from 'primereact/scrolltop';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const ToolButton = styled(Button)`
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.primaryGreen};
  padding: 0;
  width: 48px !important;
  height: 48px !important;
  font-size: 1.5rem;
`;

export const SpeedButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
  border-color: ${(props) => props.theme.colors.secondaryLightGreen30};
  border-radius: 2px;
  color: ${(props) => props.theme.colors.primaryGreen};
  padding: 0;
  width: 24px !important;
  height: 24px !important;

  &&:hover {
    background-color: ${(props) => props.theme.colors.primaryGreen};
    border-color: ${(props) => props.theme.colors.primaryGreen};
    color: ${(props) => props.theme.colors.primaryWhite};
  }
`;

const ButtonStyles = css`
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;

  &:disabled {
    opacity: 0.5;
  }

  &&:hover {
    background-color: ${(props) => props.theme.colors.lightOrange};
    border-color: ${(props) => props.theme.colors.lightOrange};
  }
`;

export const IconButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.secondaryLightGreen};
  border-color: ${(props) => props.theme.colors.secondaryLightGreen};
  border-radius: 2px;
  color: ${(props) => props.theme.colors.primaryOrange};
  padding: 0;
  width: 24px !important;
  height: 24px !important;
`;

export const HelpButton = styled(IconButton)`
  background-color: ${(props) => props.theme.colors.primaryOrange};
  border-color: ${(props) => props.theme.colors.primaryOrange};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${(props) => props.theme.colors.primaryWhite};
  padding: 0;
  width: 50px !important;
  height: 50px !important;
  position: fixed;
  bottom: 80px;
  right: 60px;
  & .pi {
    font-size: 24px;
  }

  &&:hover {
    background-color: ${(props) => props.theme.colors.elementsButtonHover};
    border-color: ${(props) => props.theme.colors.elementsButtonHover};
  }
`;

export const ScrollTopButton = styled(ScrollTop)`
  background-color: ${(props) => props.theme.colors.primaryOrange} !important;
  border-color: ${(props) => props.theme.colors.primaryOrange} !important;
  border-radius: 50%;

  color: ${(props) => props.theme.colors.primaryWhite};
  width: 50px !important;
  height: 50px !important;
  bottom: 150px;
  right: 60px;
  & .pi {
    font-size: 24px;
  }

  &&:hover {
    background-color: ${(props) => props.theme.colors.elementsButtonHover};
    border-color: ${(props) => props.theme.colors.elementsButtonHover};
  }
`;

export const PrimaryButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.primaryOrange};
  border-color: ${(props) => props.theme.colors.primaryOrange};
  min-width: 170px;
  &:disabled {
    opacity: 0.5;
    background-color: black;
  }
  ${ButtonStyles}
  height: 3rem;
  min-height: 48px;
  ${(props) => props.theme.typography.buttonButtonDefault};
`;

export const PrimaryImageGreenButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.primaryGreen};
  border-color: ${(props) => props.theme.colors.primaryGreen};
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;

  &:disabled {
    opacity: 0.5;
    background-color: black;
  }

  &&:hover {
    background-color: #497e73;
    border-color: #497e73;
  }

  height: 3rem;
  min-height: 48px;
  min-width: 48px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  ${(props) => props.theme.typography.buttonButtonDefault};
  padding: 0.5rem;
`;

export const PrimarySmallButton = styled(Button)`
  ${ButtonStyles}
  ${(props) => props.theme.typography.buttonButtonSmall};
  background-color: ${(props) => props.theme.colors.primaryOrange};
  border-color: ${(props) => props.theme.colors.primaryOrange};
  border-radius: 2px;
  &:disabled {
    opacity: 0.5;
    background-color: black;
  }
  height: 22px;
`;

export const CompilerSecondaryButton = styled(Button)`
  border-color: ${(props) => props.theme.colors.primaryOrange};
  background-color: transparent;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.primaryOrange};
  min-width: 170px;

  &&&:hover {
    background-color: transparent;
    border-color: ${(props) => props.theme.colors.elementsButtonHover};
    color: ${(props) => props.theme.colors.elementsButtonHover};
  }

  height: 3rem;
  min-height: 48px;
  ${(props) => props.theme.typography.buttonButtonDefault};
`;

export const SecondaryButton = styled(Button)`
  border-color: ${(props) => props.theme.colors.primaryOrange};
  background-color: ${(props) => props.theme.colors.primaryWhite};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.primaryOrange};
  min-width: 170px;

  &&&:hover {
    background-color: ${(props) => props.theme.colors.primaryWhite};
    border-color: ${(props) => props.theme.colors.elementsButtonHover};
    color: ${(props) => props.theme.colors.elementsButtonHover};
  }
  height: 3rem;
  min-height: 48px;
  ${(props) => props.theme.typography.buttonButtonDefault};
`;

export const AcceptButton = styled(Button)`
  height: 24px;
  padding: 0.7rem 0.75rem 0.85rem 0.75rem;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.primaryGreen};
  border-color: ${(props) => props.theme.colors.primaryGreen};
  font-weight: 500;
  &:disabled {
    color: ${(props) => props.theme.colors.primaryWhite};
    background-color: ${(props) => props.theme.colors.elementsButtonDisabled};
    border-color: ${(props) => props.theme.colors.elementsButtonDisabled};
    border-radius: 2px;
  }
`;

export const DotsMenu = styled(Button)``;

export const DeclineButton = styled(Button)`
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  color: ${(props) => props.theme.colors.primaryOrange};
  border-color: ${(props) => props.theme.colors.primaryWhite};
  &&&:hover {
    background-color: ${(props) => props.theme.colors.primaryWhite};
    color: ${(props) => props.theme.colors.primaryOrange};
    border-color: ${(props) => props.theme.colors.primaryWhite};
  }
`;

export const PrimaryButtonLink = styled(Link)`
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.primaryOrange};
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 16px;
  border: 1px solid ${(props) => props.theme.colors.primaryOrange};

  text-align: center;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  margin: 0;
  padding: 1rem 2rem;

  color: ${(props) => props.theme.colors.primaryWhite};

  &:disabled {
    background-color: ${(props) => props.theme.colors.elementsButtonDisabled};
    border-color: ${(props) => props.theme.colors.elementsButtonDisabled};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.elementsButtonHover};
    border-color: ${(props) => props.theme.colors.elementsButtonHover};
  }
`;

export const ActionSecondaryButton = withActionState(SecondaryButton);
export const CompilerActionSecondaryButton = withActionState(CompilerSecondaryButton);
export const ActionPrimaryButton = withActionState(PrimaryButton);
export const ActionImageGreenButton = withActionState(PrimaryImageGreenButton);
export const ActionAcceptButton = withActionState(AcceptButton);
export const ActionDeclineButton = withActionState(DeclineButton);
export const ActionDotsMenuButton = withActionState(DotsMenu);

export const SecondaryButtonLink = styled(Link)`
  border-radius: 4px;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 16px;
  border: 1px solid ${(props) => props.theme.colors.primaryOrange};

  text-align: center;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  margin: 0;
  padding: 1rem 2rem;

  color: ${(props) => props.theme.colors.primaryOrange};
  font-weight: 500;
  font-size: 1rem;

  &:disabled {
    color: ${(props) => props.theme.colors.elementsButtonDisabled};
    background-color: transparent;
    border-color: ${(props) => props.theme.colors.elementsButtonDisabled};
  }

  &:hover {
    color: ${(props) => props.theme.colors.elementsButtonHover};
    border-color: ${(props) => props.theme.colors.elementsButtonHover};
  }
`;

export const TextButton = styled(Button)`
  background-color: transparent;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  &.p-button .p-button-label {
    ${(props) => props.theme.typography.buttonButtonSmall}
  }
  &.p-button .p-button-icon {
    font-size: 12px;
  }
  &&&:hover {
    background-color: transparent;
    color: ${(props) => props.theme.colors.elementsButtonHover};
  }
  &:disabled {
    background-color: transparent;
    color: ${(props) => props.theme.colors.elementsButtonDisabled};
  }
`;

export const ButtonTopHeader = styled(Button)`
  border: 0;
  background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
  color: ${(props) => props.theme.colors.primaryGreen};
  border-radius: 4px;
  padding: 0;
  margin-left: 16px;
  justify-content: center;
  && {
    width: 32px;
    height: 32px;
  }
  &&:hover {
    background-color: ${(props) => props.theme.colors.primaryGreen};
    color: ${(props) => props.theme.colors.primaryWhite};
  }
  > div {
    position: absolute;
  }
`;
