const MachineReadableEPDKey = {
  GTIN: 'GTIN',
  REFERENCE_FLOWS: 'Reference flows',
  MATERIAL_PROPERTIES: 'Material properties',
  GEOGRAPHICAL_SCOPE_DESCRIPTION: 'Geographical scope description',
  TECHNOLOGY_DESCRIPTION: 'Technology description including background system',
  TECHNICAL_PURPOSE: 'Technical purpose of product or process',
  SUBTYPE: 'Subtype',
  DATA_SOURCES: 'Data sources used for this data set',
  REFERENCE_PACKAGE_VERSION_15804: 'Version of the EN 15804 reference package',
  CHARACTERISATION_METHODS: 'Characterisation methods',
  USE_ADVICE: 'Use advice',
  EPD_BASED_ON_UNIT: 'EPD based on declared or functional unit',
  FUNCTIONAL_UNIT_DESCRIPTION: 'Functional unit description'
};

export default MachineReadableEPDKey;
