import ErrorFallback from 'components/error-fallback/ErrorFallback';
import { Column, ColumnEvent, ColumnProps } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTableValueArray } from 'primereact/datatable';
import { Row } from 'primereact/row';
import { ErrorBoundary } from 'react-error-boundary';
import { useUpsertEpdLcaResult } from 'services/api/mutationsLcaResults';

import { DataTableStyled } from '../StyledGrid';
import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';
import { LcaGridNote } from '../GridService';

type TProps = {
  epdVersionId: string;
  value?: DataTableValueArray | undefined;
  columns?: ColumnProps[] | undefined;
  notes?: LcaGridNote[];
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};
const LcaResultsGrid = ({
  epdVersionId,
  value,
  columns,
  notes,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
}: TProps) => {
  const updateMutation = useUpsertEpdLcaResult(epdVersionId);
  const isReadOnly = useIsReadOnlyMode() || disabled;

  const rowClassName = () => ({ 'p-disabled': isReadOnly && !preferEnabledAppearanceEvenWhenDisabled });

  const footerGroup = notes ? (
    <ColumnGroup>
      {notes.map((x, i) => (
        <Row key={i}>
          <Column footer={x.note} className="p-row-note" />
          <Column footer={x.description} colSpan={columns?.length} className="p-row-note" />
        </Row>
      ))}
    </ColumnGroup>
  ) : null;

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field } = e;
    const newData = {
      ...rowData,
      [field]: newValue,
    };
    updateMutation.mutate(newData);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DataTableStyled
        value={value}
        dataKey="indicatorId"
        tableStyle={{ minWidth: '49rem' }}
        showGridlines
        footerColumnGroup={footerGroup}
        editMode="cell"
        rowClassName={rowClassName}
      >
        {columns?.map((x) => (
          <Column
            key={x.field}
            {...x}
            onCellEditComplete={x.editor ? onCellEditComplete : undefined}
            {...(isReadOnly && { editor: undefined })}
          />
        ))}
      </DataTableStyled>
    </ErrorBoundary>
  );
};

export default LcaResultsGrid;
