import menuIcon from 'assets/images/icons/button/menu.svg';
import pdfDownloadIcon from 'assets/images/icons/button/pdf-download.svg';
import { ActionImageGreenButton, ActionPrimaryButton, CompilerActionSecondaryButton } from 'components/v2/buttons';
import { confirmAlert } from 'components/v2/confirm-dialog/ConfirmAlert';
import { confirmDelete } from 'components/v2/confirm-dialog/ConfirmDelete';
import ConfirmEditorialSendToPublishDialog from 'components/v2/dialogs/ConfirmEditorialSendToPublishDialog';
import ConfirmEditorialSendToPublishProcessCertificationDialog from 'components/v2/dialogs/ConfirmEditorialSendToPublishProcessCertificationDialog';
import { ConfirmWithCommentPayload } from 'components/v2/dialogs/ConfirmWithCommentDialog';
import DeclinePublicationDialog from 'components/v2/dialogs/DeclinePublicationDialog';
import DeclineVerificationDialog from 'components/v2/dialogs/DeclineVerificationDialog';
import DepublishAdminDialog from 'components/v2/dialogs/DepublishAdminDialog';
import { HelpBox, HelpBoxHeader } from 'components/v2/help-boxes';
import { toaster } from 'components/v2/toast';
import { ProcessStatus } from 'constants/constants';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import { ActionState } from 'services/EpdClient';
import EpdStateService from 'services/EpdStateService';
import FileService from 'services/FileService';
import { getEpdAdditionalDocuments } from 'services/api/epdApi';
import {
  useApproveVerification,
  useCancelApproveVerificationMutation,
  useCancelEpdDeregistration,
  useCancelRegistrationEpd,
  useCancelUpdateEpd,
  useCancelVerification,
  useDeclinePublicationEpd,
  useDeclineVerification,
  useDepublishEpd,
  useDeregisterEpd,
  useEditorialUpdating,
  useLicenseeAdminVerificationApprove,
  useLicenseeAdminVerificationDecline,
  usePublishEpd,
  useRegisterEditorial,
  useRegisterEpd,
  useStartVerification,
  useUnlockEpd,
} from 'services/api/mutations';
import styled from 'styled-components';
import { CompilerActionsStatusModel, EPDDefinitionModel } from 'types/types';
import { formatUtcDateWithOffset } from 'util/utils';

import { CheckBoxPanel, RadioButtonStyled } from '../../../styles/v2/Styles.styled';

type VisibleDialogType =
  | 'unlock'
  | 'declineVerification'
  | 'confirmEditorialSendToPublish'
  | 'declinePublication'
  | 'declinePublicationLicensee'
  | 'depublishByAdmin';

type Props = {
  epdId: string;
  epdVersionId: string;
  epdDefinition: EPDDefinitionModel;
  actionsStates: CompilerActionsStatusModel;
  onActionStatusChange(newStatus: ProcessStatus): void;
};

const CompilerToolButtons: React.FC<Props> = ({
  epdId,
  epdVersionId,
  epdDefinition,
  actionsStates,
  onActionStatusChange,
}) => {
  const [visibleDialog, setVisibleDialog] = useState<VisibleDialogType | null>(null);
  const [epdUpdateType, setEpdUpdateType] = useState<'update' | 'editorialUpdate'>('update');
  const actionMenuRef = useRef<Menu>(null);
  const { t } = useTranslation();
  const history = useHistory();

  const cancelUpdateEpdMutation = useCancelUpdateEpd(epdId, epdVersionId);
  const startVerificationMutation = useStartVerification(epdId, epdVersionId);
  const cancelVerificationMutation = useCancelVerification(epdId, epdVersionId);
  const registerEpdMutation = useRegisterEpd(epdId, epdVersionId);
  const cancelRegistrationEpdMutation = useCancelRegistrationEpd(epdId, epdVersionId);
  const deregisterEpdMutation = useDeregisterEpd(epdId, epdVersionId);
  const cancelEpdDeregistrationMutation = useCancelEpdDeregistration(epdId, epdVersionId);
  const unlockEpdMutation = useUnlockEpd(epdId, epdVersionId);
  const approveVerificationMutation = useApproveVerification(epdId, epdVersionId);
  const declineVerificationMutation = useDeclineVerification(epdId, epdVersionId);
  const cancelApproveVerificationMutation = useCancelApproveVerificationMutation(epdId, epdVersionId);
  const licenseeAdminVerificationApproveMutation = useLicenseeAdminVerificationApprove(epdId, epdVersionId);
  const publishEpdMutation = usePublishEpd(epdId, epdVersionId);
  const declinePublicationMutation = useDeclinePublicationEpd(epdId, epdVersionId);
  const depublishEpdMutation = useDepublishEpd(epdId, epdVersionId);
  const licenseeAdminVerificationDeclineMutation = useLicenseeAdminVerificationDecline(epdId, epdVersionId);
  const registerEditorialMutation = useRegisterEditorial(epdId, epdVersionId);
  const editorialUpdatingMutation = useEditorialUpdating(epdId, epdVersionId);

  const onAcceptCancelUpdateEpd = async () => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await cancelUpdateEpdMutation.mutateAsync();
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdCancelled') as string,
      });
      location.reload();
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD update cancellation has not been accomplished due to unexpected error.',
      });
    }
  };

  const onCancelUpdateEpd = () =>
    confirmAlert({
      caption: `Are you sure you want to cancel the ${epdDefinition.fullIdentificationNumber} ${epdDefinition.declarationName} update?`,
      description: <div>All updated changes will be lost.</div>,
      acceptLabel: t('Submit'),
      rejectLabel: t('Cancel'),
      onConfirm: onAcceptCancelUpdateEpd,
    });

  const onAcceptDeleteEpd = async () => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await EpdStateService.delete(epdId);
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdDeleted', {
          epdNumber: epdDefinition.fullIdentificationNumber,
        }) as string,
      });
      history.push(EpdLinks.dashboard());
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({ severity: 'error', summary: 'Error', details: 'EPD has not been deleted due to unexpected error.' });
      console.error(error);
    }
  };

  const onDeleteEpd = () =>
    confirmDelete({
      caption: `Are you sure you want to delete the EPD "${epdDefinition.fullIdentificationNumber} ${epdDefinition.declarationName}"?`,
      description: <div>The EPD data will be lost and you will not be able to restore it.</div>,
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      onConfirm: onAcceptDeleteEpd,
    });

  const onStartVerification = async () => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await startVerificationMutation.mutateAsync();
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.startVerification') as string,
      });
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD verification has not been started due to unexpected error.',
      });
    }
  };

  const onCancelVerification = async () => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await cancelVerificationMutation.mutateAsync();
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.cancelVerification') as string,
      });
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD verification has not been canceled due to unexpected error.',
      });
    }
  };

  const onRegister = async () => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await registerEpdMutation.mutateAsync();
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: (
          <div>
            <b>{'Thank you for registering your EPD with the International EPD System!'}</b>
            <p style={{ marginBottom: 0 }}>
              {
                'We will attend to it as soon as possible. We aim to publish the EPD within 1-3 days. If you have any questions feel free to contact us in the Secretariat at support@environdec.com.'
              }
            </p>
            <p style={{ marginBottom: 0 }}>
              {
                'If any information is missing or if any irregularities are recognized in the submitted material the support team will contact you.'
              }
            </p>
          </div>
        ),
      });
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD has not been registered due to unexpected error.',
      });
    }
  };

  const onCancelRegistrationClicked = () => {
    if (!epdDefinition) {
      return;
    }
    confirmAlert({
      description: (
        <span style={{ whiteSpace: 'pre-wrap' }}>
          {t('confirmModal.cancelRegistrationEPD', {
            name: `${epdDefinition.fullIdentificationNumber} ${epdDefinition.declarationName}`,
          })}
        </span>
      ),
      acceptLabel: t('Submit'),
      onConfirm: onCancelRegistrationConfirmed,
    });
  };

  const onCancelRegistrationConfirmed = async () => {
    if (!epdDefinition) {
      return;
    }
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await cancelRegistrationEpdMutation.mutateAsync();
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.canceledRegistrationEpd', {
          name: `${epdDefinition.fullIdentificationNumber} ${epdDefinition.declarationName}`,
        }) as string,
      });
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD publication has not been canceled due to unexpected error.',
      });
    }
  };

  const onConfirmDeregisterEpd = async () => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await deregisterEpdMutation.mutateAsync();
      onActionStatusChange(ProcessStatus.Success);
      history.go(0);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdDeregister', {
          epdNumber: epdDefinition.fullIdentificationNumber,
        }) as string,
      });
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD has not been deregistered due to unexpected error.',
      });
    }
  };

  const onDeregisterEpd = () =>
    confirmDelete({
      caption: t('confirmModal.deregisterEPD'),
      acceptLabel: t('deregister'),
      rejectLabel: t('cancel'),
      onConfirm: onConfirmDeregisterEpd,
    });

  const onCancelEpdDeregistration = async () => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await cancelEpdDeregistrationMutation.mutateAsync();
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.cancelDeregistration') as string,
      });
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Cancelation of EPD deregistration has not been accomplished due to unexpected error.',
      });
    }
  };

  const onStartUpdatingEpd = async () => {
    if (actionsStates.isVerifierInvolved) {
      setVisibleDialog('unlock');
      return;
    }

    startUpdatingHandler('editorialUpdate');
  };

  const startUpdatingHandler = async (updateType: 'update' | 'editorialUpdate') => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);

      if (updateType === 'update') {
        await unlockEpdMutation.mutateAsync();
      } else if (updateType === 'editorialUpdate') {
        await editorialUpdatingMutation.mutateAsync({ epdId });
      }
      onActionStatusChange(ProcessStatus.Success);
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Updating of EPD has not been started due to unexpected error.',
      });
    }
  };

  const handleEditorialSendToPublishConfirmed = (payload: ConfirmWithCommentPayload | null) => {
    setVisibleDialog(null);
    if (!payload) {
      return;
    }
    registerEditorialHandler(payload);
  };

  const registerEditorialHandler = async (payload: ConfirmWithCommentPayload) => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await registerEditorialMutation.mutateAsync({ epdId, notes: payload.commentText });

      onActionStatusChange(ProcessStatus.Success);
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Updating of EPD has not been started due to unexpected error.',
      });
    }
  };

  const onApproveVerificationEpd = async () => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      const currentDateTimeWithTimezone = formatUtcDateWithOffset(new Date());
      const approveModel = new FormData();
      approveModel.append('LastRevisionDate', currentDateTimeWithTimezone);
      await approveVerificationMutation.mutateAsync(approveModel);
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.approvedVerification', {
          epdNumber: epdDefinition.fullIdentificationNumber,
        }) as string,
      });
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Approval of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const onDeclineVerificationEpd = async (payload: ConfirmWithCommentPayload) => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await declineVerificationMutation.mutateAsync({ reason: payload.commentText });
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.declinedVerification', {
          epdNumber: epdDefinition.fullIdentificationNumber,
        }) as string,
      });
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Rejection of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const onRetractVerification = async () => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await cancelApproveVerificationMutation.mutateAsync();
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.retractVerification') as string,
      });
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Retraction of EPD verification has not been accomplished due to unexpected error.',
      });
    }
  };

  const onLicenseeAdminApproveEpd = async () => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await licenseeAdminVerificationApproveMutation.mutateAsync();
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.approveEpdChanges') as string,
      });
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Approval of EPD by licensee admin has not been accomplished due to unexpected error.',
      });
    }
  };

  const onPublishEpd = async () => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await publishEpdMutation.mutateAsync(epdVersionId);
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdPublished', {
          epdNumber: epdDefinition.fullIdentificationNumber,
        }) as string,
      });
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Publication of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const onDeclinePublicationEpd = async (payload: ConfirmWithCommentPayload, actor: 'bySuperAdmin' | 'byLicensee') => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      if (actor === 'bySuperAdmin') {
        await declinePublicationMutation.mutateAsync({
          declineReason: payload.commentText,
        });
      } else {
        await licenseeAdminVerificationDeclineMutation.mutateAsync({
          declineReason: payload.commentText,
        });
      }
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.declinedPublication', {
          epdNumber: epdDefinition?.fullIdentificationNumber,
        }) as string,
      });
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Rejection of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const onDepublishEpd = async (payload?: ConfirmWithCommentPayload) => {
    try {
      onActionStatusChange(ProcessStatus.Fetching);
      await depublishEpdMutation.mutateAsync({
        depublicationReason: payload ? payload.commentText : null,
      });
      onActionStatusChange(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdDepublished', { epdNumber: epdDefinition.fullIdentificationNumber }) as string,
      });
    } catch (error) {
      onActionStatusChange(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Depublish of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const handleDownloadPDF = async () => {
    const additionalDocuments = await getEpdAdditionalDocuments(epdVersionId);
    const compilerDocumentId = additionalDocuments?.find((doc) => doc.name === `EPD-${epdVersionId}.pdf`)?.id;
    if (compilerDocumentId) {
      window.open(FileService.getImageUrl(compilerDocumentId), '_blank', 'noreferrer');
    } else {
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'The EPD document in PDF format was not found, please contact Secretariat.',
      });
    }
  };

  const actionMenuItems: MenuItem[] = [
    {
      label: 'Depublish EPD',
      command: () => setVisibleDialog('depublishByAdmin'),
      visible: actionsStates.depublishByAdmin === ActionState.Enabled,
    },
    {
      label: 'Depublish EPD',
      command: () => onDepublishEpd(),
      visible: actionsStates.depublishByCustomer === ActionState.Enabled,
    },
    {
      label: 'Deregister EPD',
      command: onDeregisterEpd,
      visible: actionsStates.deregister === ActionState.Enabled,
    },
    {
      label: 'Preview PDF',
      url: EpdLinks.epdVersionDocumentPreview(epdVersionId),
      target: '_blank',
      visible: actionsStates.previewPdf === ActionState.Enabled,
    },
    {
      label: 'Download PDF',
      command: () => {
        handleDownloadPDF();
      },
      visible: actionsStates.downloadPdf === ActionState.Enabled,
    },
  ];

  const epdDetailsForDialogs = epdDefinition
    ? {
        fullIdentificationNumber: epdDefinition.fullIdentificationNumber,
        title: epdDefinition.declarationName,
      }
    : null;

  const handlePreviewPDF = () => {
    window.open(EpdLinks.epdVersionDocumentPreview(epdVersionId), '_blank');
  };

  return (
    <>
      <CompilerActionSecondaryButton
        actionState={actionsStates.cancelUpdate}
        label="Cancel update"
        onClick={onCancelUpdateEpd}
        outlined
        type="reset"
      />
      <CompilerActionSecondaryButton
        actionState={actionsStates.deleteDraft}
        label="Delete EPD"
        onClick={onDeleteEpd}
        outlined
        type="reset"
      />
      <CompilerActionSecondaryButton
        actionState={actionsStates.declineVerification}
        label="Decline EPD"
        onClick={() => setVisibleDialog('declineVerification')}
        outlined
      />
      <CompilerActionSecondaryButton
        actionState={actionsStates.declinePublication}
        label="Decline EPD"
        onClick={() => setVisibleDialog('declinePublication')}
        outlined
      />
      <ActionPrimaryButton
        style={{ marginLeft: '16px' }}
        label="Start verification"
        onClick={onStartVerification}
        actionState={actionsStates.startVerification}
      />
      <ActionPrimaryButton
        style={{ marginLeft: '16px' }}
        label="Cancel verification"
        onClick={onCancelVerification}
        actionState={actionsStates.cancelVerification}
      />
      <ActionPrimaryButton
        style={{ marginLeft: '16px' }}
        label="Cancel publication"
        onClick={onCancelRegistrationClicked}
        actionState={actionsStates.cancelRegistration}
      />
      <ActionPrimaryButton
        style={{ marginLeft: '16px' }}
        label="Send to publish"
        onClick={onRegister}
        actionState={actionsStates.register}
      />
      <ActionPrimaryButton
        style={{ marginLeft: '16px' }}
        label="Cancel deregistration"
        onClick={onCancelEpdDeregistration}
        actionState={actionsStates.cancelDeregistration}
      />
      <ActionPrimaryButton
        style={{ marginLeft: '16px' }}
        label="Update EPD"
        onClick={onStartUpdatingEpd}
        actionState={actionsStates.unlock}
      />
      <ActionPrimaryButton
        style={{ marginLeft: '16px' }}
        label="Send to publish"
        onClick={() => setVisibleDialog('confirmEditorialSendToPublish')}
        actionState={actionsStates.registerEditorial}
      />
      {actionsStates.isVerifierInvolved ? (
        <ConfirmEditorialSendToPublishDialog
          isOpened={visibleDialog === 'confirmEditorialSendToPublish'}
          onClose={handleEditorialSendToPublishConfirmed}
        />
      ) : (
        <ConfirmEditorialSendToPublishProcessCertificationDialog
          isOpened={visibleDialog === 'confirmEditorialSendToPublish'}
          onClose={handleEditorialSendToPublishConfirmed}
        />
      )}
      <ActionPrimaryButton
        style={{ marginLeft: '16px' }}
        label="Retract Verification"
        onClick={onRetractVerification}
        actionState={actionsStates.retractVerification}
      />
      <ActionPrimaryButton
        style={{ marginLeft: '16px' }}
        label="Approve EPD"
        onClick={onApproveVerificationEpd}
        actionState={actionsStates.approveVerification}
      />
      <ActionPrimaryButton
        style={{ marginLeft: '16px' }}
        label="Publish EPD"
        onClick={onPublishEpd}
        actionState={actionsStates.publish}
      />
      <ActionPrimaryButton
        style={{ marginLeft: '16px' }}
        label="Approve EPD"
        onClick={onLicenseeAdminApproveEpd}
        actionState={actionsStates.licenseeAdminVerification}
      />
      <ActionPrimaryButton
        style={{ marginLeft: '16px' }}
        label="Decline EPD"
        onClick={() => setVisibleDialog('declinePublicationLicensee')}
        actionState={actionsStates?.licenseeAdminVerification}
      />
      {actionMenuItems.filter((m) => m.visible).length > 1 ? (
        <ActionImageGreenButton
          style={{ marginLeft: '16px' }}
          onClick={(e: any) => actionMenuRef.current?.toggle(e)}
          actionState={ActionState.Enabled}
        >
          <img alt="menu" src={menuIcon} />
        </ActionImageGreenButton>
      ) : (
        actionsStates?.previewPdf === ActionState.Enabled && (
          <ActionImageGreenButton
            style={{ marginLeft: '16px' }}
            onClick={handlePreviewPDF}
            actionState={ActionState.Enabled}
          >
            <img alt="menu" src={pdfDownloadIcon} />
          </ActionImageGreenButton>
        )
      )}

      <Menu ref={actionMenuRef} model={actionMenuItems} popupAlignment={'right'} popup />
      <ConfirmDialog
        header={<>Update EPD</>}
        closable={false}
        visible={visibleDialog === 'unlock'}
        style={{ width: '35rem' }}
        onHide={() => setVisibleDialog(null)}
        acceptLabel="Submit"
        rejectLabel="Cancel"
        accept={() => startUpdatingHandler(epdUpdateType)}
        className="custom-dialog"
        message={
          <StyledContentContainer>
            <HelpBox>
              <HelpBoxHeader>This EPD was approved by the verifier.</HelpBoxHeader>
              <div>
                You are allowed to make only the editorial changes to the verified EPD, such as correction of spelling errors
                without verification.
              </div>
              <div>If the EPD information needs an update, either the product or LCA, you shall verify the EPD again.</div>
            </HelpBox>
            <CheckBoxPanel>
              <RadioButtonStyled
                inputId={'updateMode_update'}
                value="update"
                name="updateMode"
                checked={epdUpdateType === 'update'}
                onChange={(e) => {
                  setEpdUpdateType(e.value);
                }}
              />
              <label htmlFor={'updateMode_update'} style={{ textTransform: 'none', marginLeft: '0.5rem' }}>
                The EPD needs a verification, I will update the EPD information.
              </label>
            </CheckBoxPanel>
            <CheckBoxPanel style={{ marginTop: '0.5rem' }}>
              <RadioButtonStyled
                inputId={'updateMode_editorialUpdate'}
                value="editorialUpdate"
                name="updateMode"
                checked={epdUpdateType === 'editorialUpdate'}
                onChange={(e: RadioButtonChangeEvent) => {
                  setEpdUpdateType(e.value);
                }}
              />
              <label htmlFor={'updateMode_editorialUpdate'} style={{ textTransform: 'none', marginLeft: '0.5rem' }}>
                The EPD verification is not needed, I will make the editorial update.
              </label>
            </CheckBoxPanel>
          </StyledContentContainer>
        }
      />
      <DeclineVerificationDialog
        isOpened={visibleDialog === 'declineVerification'}
        epd={epdDetailsForDialogs}
        onClose={(payload) => {
          setVisibleDialog(null);
          if (!payload) {
            return;
          }
          onDeclineVerificationEpd(payload);
        }}
      />
      <DeclinePublicationDialog
        isOpened={visibleDialog === 'declinePublication'}
        epd={epdDetailsForDialogs}
        onClose={(payload) => {
          setVisibleDialog(null);
          if (!payload) {
            return;
          }
          onDeclinePublicationEpd(payload, 'bySuperAdmin');
        }}
      />
      <DeclinePublicationDialog
        isOpened={visibleDialog === 'declinePublicationLicensee'}
        epd={epdDetailsForDialogs}
        onClose={(payload) => {
          setVisibleDialog(null);
          if (!payload) {
            return;
          }
          onDeclinePublicationEpd(payload, 'byLicensee');
        }}
      />
      <DepublishAdminDialog
        isOpened={visibleDialog === 'depublishByAdmin'}
        epd={epdDetailsForDialogs}
        onClose={(payload) => {
          setVisibleDialog(null);
          if (!payload) {
            return;
          }
          onDepublishEpd(payload);
        }}
      />
      <DepublishAdminDialog
        isOpened={visibleDialog === 'depublishByAdmin'}
        epd={epdDetailsForDialogs}
        onClose={(payload) => {
          setVisibleDialog(null);
          if (!payload) {
            return;
          }
          onDepublishEpd(payload);
        }}
      />
    </>
  );
};

export default CompilerToolButtons;

const StyledContentContainer = styled.div`
  padding: 1rem 2rem 2rem 2rem;
  ${(props) => props.theme.fonts.textSmall}
`;
