import 'primeflex/primeflex.css';

import { SpeedButton } from 'components/v2/buttons';
import React from 'react';
import { EPDCopyGroupStatus } from 'services/EpdClient';
import styled from 'styled-components';
import { EPDGroupStatusBadge } from 'styles/v2/Styles.styled';

type TProps = {
  title: React.ReactNode;
  status?: EPDCopyGroupStatus;
  backTo: string;
  onBack: any;
};

const TitleAndBack = ({ title, status, backTo, onBack }: TProps) => {
  const groupStatus =
    status === EPDCopyGroupStatus.ApprovedByLicenseeAdmin
      ? 'Sent for publication'
      : status === EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin
      ? 'Declined publication'
      : status;
  return (
    <>
      <div style={{ width: '35px', marginTop: '-10px' }}>
        <SpeedButton icon="pi pi-arrow-left" aria-label="Back" onClick={() => onBack()} />
        <BackText>{backTo}</BackText>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', marginTop: '-10px' }}>
        <TitleText style={{ minWidth: '5rem' }}>{title}</TitleText>
        {status && (
          <div>
            <EPDGroupStatusBadge status={status}>{groupStatus}</EPDGroupStatusBadge>
          </div>
        )}
      </div>
    </>
  );
};

const BackText = styled.div`
  ${(props) => props.theme.typography.bodyInput}
  position: absolute;
  margin-left: 35px;
  margin-top: 5px;
`;

const TitleText = styled.div`
  ${(props) => props.theme.typography.headerH2}
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default TitleAndBack;
