import { TextButton } from 'components/v2/buttons';
import FilesList from 'components/v2/file-row/FilesList';
import { ButtonUploadIcon } from 'components/v2/icons';
import { ImagesUpload } from 'components/v2/images-upload';
import SmartLabel from 'components/v2/labels/SmartLabel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EPDModel, FileLoadingModel, FlowPropertyModel, MaterialPropertyModel, VersionStateType } from 'services/EpdClient';
import EPDService from 'services/EpdService';
import FlowPropertiesService from 'services/FlowPropertiesService';
import MaterialPropertiesService from 'services/MaterialPropertiesService';
import styled from 'styled-components';
import { ErrorTextBox } from 'styles/v2/Styles.styled';

import UploadDocumentsDialog from '../dialogs/UploadDocumentsDialog';
import EpdDefinition from './definition/EpdDefinition';
import MachineReadable from './machine-readable/MachineReadable';
import ProductInformation from './product-information/ProductInformation';

type TProps = {
  epd: EPDModel;
  onChangeEpd: any;
  isConstruction?: boolean;
  title: string | undefined;
  setTitle: any;
  isReadOnly: boolean;
  canUpload: boolean;
  fileLoading?: FileLoadingModel[];
};

const EpdDetails = ({ epd, onChangeEpd, isConstruction, title, setTitle, isReadOnly, canUpload, fileLoading }: TProps) => {
  const { t } = useTranslation();
  const [isUploadOpened, setIsUploadOpened] = useState<boolean>(false);

  const [flowProperties, setFlowProperties] = useState<FlowPropertyModel[]>([]);
  const [materialProperties, setMaterialProperties] = useState<MaterialPropertyModel[]>([]);

  useEffect(() => {
    const fetchFlowProperties = async () => {
      
      const res = await FlowPropertiesService.getFlowProperties();
      const excludedFlowProperties = [
        '-',
        'area (m^2)',
        'Carbon content (biogenic)',
        'Carbon content (biogenic) - packaging',
        'number of pieces (pieces)',
        'volume (m^3)',
        'UNDEFINED',
      ];
      setFlowProperties(res.filter((x) => !excludedFlowProperties.includes(x.name!)));
    };

    const fetchMaterialProperties = async () => {
      const res = await MaterialPropertiesService.getMaterialProperties();
      setMaterialProperties(res);
    };

    fetchFlowProperties();
    fetchMaterialProperties();
  }, []);

  return (
    <>
      {epd.versionState === VersionStateType.DeclinedVerification && (
        <DeclinedReason>
          <ErrorTextBox>{epd.verificationDeclineReason || 'EPD has been declined on verification step.'}</ErrorTextBox>
        </DeclinedReason>
      )}

      <EpdDefinition epd={epd} onChangeEpd={onChangeEpd} isReadOnly={isReadOnly} title={title} setTitle={setTitle} />

      <ProductInformation epd={epd} onChangeEpd={onChangeEpd} isReadOnly={isReadOnly} />

      <SmartLabel label={'Product images'} required hasValue={epd?.productImages && epd?.productImages.length > 0} />
      <ImagesUpload
        name={t('epdWizard.wizardStep2.properties.images')}
        images={epd?.productImages}
        onUpload={(e: any) => {}}
        onReorder={(imgIds: string[]) => EPDService.arrangeEpdFilesByModifyDate(imgIds)}
        onRemove={() => {}}
        disabled
      />

      <div className="my-3">
        <SmartLabel label={'EPD documents'} required hasValue={epd?.documents && epd?.documents.length > 0} />
        <FilesList files={epd?.documents} disabled />

        {canUpload && (
          <>
            <TextButton
              className="white-space-nowrap pt-0"
              label="Upload documents"
              icon={ButtonUploadIcon}
              text
              onClick={() => setIsUploadOpened(true)}
            />
            <UploadDocumentsDialog
              header="Upload documents"
              isOpened={isUploadOpened}
              onHide={() => setIsUploadOpened(false)}
              epd={epd}
              onChangeEpd={onChangeEpd}
              isConstruction={isConstruction}
              fileLoading={fileLoading}
            />
          </>
        )}
      </div>

      <MachineReadable
        epd={epd}
        onChangeEpd={onChangeEpd}
        isConstruction={isConstruction}
        isReadOnly={isReadOnly}
        flowProperties={flowProperties}
        materialProperties={materialProperties}
      />

      <SmartLabel label="LCA results" required={isConstruction} hasValue={epd?.lcAs && epd?.lcAs.length > 0} />
      <FilesList files={epd?.lcAs} disabled />
      {canUpload && (
        <TextButton
          className="white-space-nowrap pt-0"
          label="Upload documents"
          icon={ButtonUploadIcon}
          text
          onClick={() => setIsUploadOpened(true)}
        />
      )}
    </>
  );
};

const DeclinedReason = styled.div`
  ${ErrorTextBox} {
    word-break: break-word;
  }
`;

export default EpdDetails;
