import React from 'react';
import { useEpdCpcrSpecificData } from 'services/api/queries';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { CpcrPerformanceTableModel } from 'types/types';

import { HelpBox, HelpBoxHeader } from '../help-boxes';
import DynamicGrid from './grids/lca-results/DynamicGrid';
import FixedGrid from './grids/lca-results/FixedGrid';
import GridPanel from './panels/expandable/GridPanel';
import SkeletonTechnicalPerformanceTab from './skeletons/SkeletonTechnicalPerformanceTab';

const TechnicalPerformanceTab: React.FunctionComponent<{
  epdVersionId: string;
  validationState: any;
}> = ({ epdVersionId, validationState }) => {
  const cpcrSpecificData = useEpdCpcrSpecificData(epdVersionId).data;

  const isLoading = cpcrSpecificData === undefined || validationState === undefined;
  if (isLoading) {
    return <SkeletonTechnicalPerformanceTab />;
  }

  return (
    <>
      <FieldPanelFullWidth>
        <HelpBox>
          <HelpBoxHeader>Technical characteristics and performance</HelpBoxHeader>
          <span>
            Product standards (including c-PCRs) and other relevant documents may contain relevant information to properly
            define the technical performance.
          </span>
        </HelpBox>
      </FieldPanelFullWidth>

      {cpcrSpecificData.tables.map((t: CpcrPerformanceTableModel) => (
        <FieldPanelFullWidth key={t.tableCode}>
          {t.isFixed ? (
            <FixedGrid
              epdVersionId={epdVersionId}
              tableTitle={t.tableTitle}
              tableCode={t.tableCode}
              columns={cpcrSpecificData.columns?.filter((x: any) => x.tableCode == t.tableCode)}
              value={cpcrSpecificData.data?.filter((x: any) => x.tableCode == t.tableCode) || []}
              errors={null}
            />
          ) : (
            <GridPanel
              title={t.tableTitle}
              tooltip={t.tableDescription}
              style={{ marginTop: '-12px' }}
              error={
                t.tableTitle.includes('PCR') &&
                validationState?.errors?.technicalPerformance &&
                Object.keys(validationState?.errors?.technicalPerformance).length > 0 &&
                'Required'
              }
            >
              <DynamicGrid
                epdVersionId={epdVersionId}
                tableTitle={'Products'}
                tableCode={t.tableCode}
                columns={cpcrSpecificData.columns?.filter((x: any) => x.tableCode == t.tableCode)}
                value={cpcrSpecificData.data?.filter((x: any) => x.tableCode == t.tableCode)}
                errors={null}
              />
            </GridPanel>
          )}
        </FieldPanelFullWidth>
      ))}
    </>
  );
};

export default TechnicalPerformanceTab;
