import LcaResultsGrid from './LcaResultsGrid';
import {
  createVariationColumnWithDisclaimer,
  createVariationDisclaimerNote,
  LcaResultsGridColumns,
  LcaResultsGridColumnsPdf,
} from './LcaResultsGridColumns';
import { LcaResultModel } from 'types/types';
import LcaResultsGridPdf from './LcaResultsGridPdf';
import useLcaGridHelper from '../../hooks/useLcaGridHelper';
import { LcaGridNote } from '../GridService';

type TProps = {
  epdVersionId: string;
  rows: LcaResultModel[] | undefined;
  isAdditionalDisclaimerVisible: boolean | undefined;
  isForPdf?: boolean;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};

const LcaResultsGridAdditionalConstruction = ({
  epdVersionId,
  rows,
  isAdditionalDisclaimerVisible,
  isForPdf,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
}: TProps) => {
  const { generalDisclaimerNote, showVariationColumn } = useLcaGridHelper(epdVersionId);

  const formattedRows = (() => {
    if (!rows?.length) {
      return [];
    }

    return rows.map((r) => {
      if (r.indicatorName === 'IRP') {
        return {
          ...r,
          indicatorName: 'IRP<sup>1</sup>',
        };
      }
      if (r.indicatorName === 'ETP-fw') {
        return {
          ...r,
          indicatorName: isAdditionalDisclaimerVisible ? 'ETP-fw<sup>2</sup>, <sup>3</sup>' : 'ETP-fw<sup>2</sup>',
        };
      }
      if (r.indicatorName === 'HTP-c') {
        return {
          ...r,
          indicatorName: isAdditionalDisclaimerVisible ? 'HTP-c<sup>2</sup>, <sup>3</sup>' : 'HTP-c<sup>2</sup>',
        };
      }
      if (r.indicatorName === 'HTP-nc') {
        return {
          ...r,
          indicatorName: isAdditionalDisclaimerVisible ? 'HTP-nc<sup>2</sup>, <sup>3</sup>' : 'HTP-nc<sup>2</sup>',
        };
      }
      if (r.indicatorName === 'SQP') {
        return {
          ...r,
          indicatorName: isAdditionalDisclaimerVisible ? 'SQP<sup>2</sup>, <sup>3</sup>' : 'SQP<sup>2</sup>',
        };
      }
      return r;
    });
  })();

  const notes: LcaGridNote[] = [
    {
      note: 'Acronyms',
      description:
        'PM = Potential incidence of disease due to particulate matter emissions; IRP = Potential human exposure efficiency relative to U235; ETP-fw = Potential comparative toxic unit for ecosystems; HTP-c = Potential comparative toxic unit for humans; HTP-nc = Potential comparative toxic unit for humans; SQP = Potential soil quality index.',
    },
  ];

  if (generalDisclaimerNote) {
    notes.push(generalDisclaimerNote);
  }

  notes.push(
    {
      note: (
        <>
          Disclaimer<sup>1</sup>
        </>
      ),
      description:
        'This impact category deals mainly with the eventual impact of low dose ionizing radiation on human health of the nuclear fuel cycle. It does not consider effects due to possible nuclear accidents, occupational exposure nor due to radioactive waste disposal in underground facilities. Potential ionizing radiation from the soil, from radon and from some construction materials is also not measured by this indicator.',
    },
    {
      note: (
        <>
          Disclaimer<sup>2</sup>
        </>
      ),
      description:
        'The results of this environmental impact indicator shall be used with care as the uncertainties of these results are high or as there is limited experience with the indicator.',
    }
  );

  if (isAdditionalDisclaimerVisible) {
    notes.push({
      note: (
        <>
          Disclaimer<sup>3</sup>
        </>
      ),
      description:
        'The results of the impact categories land use, human toxicity (cancer), human toxicity, noncancer and ecotoxicity (freshwater) may be highly uncertain in LCAs that include capital goods/infrastructure in generic datasets, in case infrastructure/capital goods contribute greatly to the total results. This is because the LCI data of infrastructure/capital goods used to quantify these indicators in currently available generic datasets sometimes lack temporal, technological and geographical representativeness. Caution should be exercised when using the results of these indicators for decision-making purposes.',
    });
  }

  let columns = isForPdf ? LcaResultsGridColumnsPdf : LcaResultsGridColumns;
  if (showVariationColumn && (!isForPdf || (isForPdf && rows?.some((r) => r.variation)))) {
    const disclaimerNumber = isAdditionalDisclaimerVisible ? 4 : 3;
    columns = [...columns, createVariationColumnWithDisclaimer(disclaimerNumber)];
    notes.push(createVariationDisclaimerNote(disclaimerNumber));
  }

  if (isForPdf) {
    return <LcaResultsGridPdf rows={formattedRows} columns={columns} notes={notes} />;
  }
  return (
    <LcaResultsGrid
      epdVersionId={epdVersionId}
      value={formattedRows}
      columns={columns}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaResultsGridAdditionalConstruction;
