import { YES_NO } from 'components/v2/compiler/constants';
import ContributionOfScrapInputsGridPdf from 'components/v2/compiler/grids/contribution-of-scrap-inputs/ContributionOfScrapInputsGridPdf';
import DataQualityAssessmentAndReferenceYearsPdf from 'components/v2/compiler/grids/data-quality-assessment-and-ref-years/DataQualityAssessmentAndReferenceYearsPdf';
import DeclarationOfCO2GridPdf from 'components/v2/compiler/grids/declaration-of-co2/DeclarationOfCo2GridPdf';
import ElectricityGridPdf from 'components/v2/compiler/grids/electricity/ElectricityGridPdf';
import TransportationPerformanceGridPdf from 'components/v2/compiler/grids/specification/TransportationPerformanceGridPdf';
import React from 'react';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import {
  useEpdConversionFactor,
  useEpdDataSourceOptions,
  useEpdReferenceFlow,
  useGetEpdCO2UptakeAssociation,
  useLCASoftwareOptions,
} from 'services/api/queries';
import styled from 'styled-components';
import {
  DataSourceOptionModel,
  DictionaryValue,
  EPDBasedOnUnitEnum,
  EPDDataSourceModel,
  EPDPDFModel,
  Option,
  ScrapInputsContributionLevelEnum,
} from 'types/types';
import DebugOnly from 'util/dev-tools/DebugOnly';
import RenderCounter from 'util/dev-tools/RenderCounter';

import { PDF_H1, PDF_PAGE, PDF_Table } from './Styles.styled';

const LCAInformationPage: React.FunctionComponent<{
  epdVersionId: string;
  e: EPDPDFModel;
  getOptionsFromDictionary: (code: string) => Option[];
  dataSources: any;
  referenceFlowUnits: DictionaryValue[] | undefined;
}> = ({ epdVersionId, e, getOptionsFromDictionary, dataSources, referenceFlowUnits }) => {
  const epdBasedOnUnitOptions = getOptionsFromDictionary(EPD_DICTIONARY_CODES.EPD_BASED_ON_UNIT);
  const scrapInputsContributionLevelOptions = getOptionsFromDictionary(EPD_DICTIONARY_CODES.SCRAP_INPUT_CONTRIBUTION_LEVEL);
  const dataSourceOptionsSource = useEpdDataSourceOptions().data;
  const referenceFlowData = useEpdReferenceFlow(epdVersionId!).data;
  const conversionFactorData = useEpdConversionFactor(epdVersionId!).data;
  const cO2UptakeAssociation = useGetEpdCO2UptakeAssociation(epdVersionId!).data;
  const lcaSoftwareOptions = useLCASoftwareOptions().data;

  const getDataSourceVersionOptions = (dataSourceId?: string) => {
    if (!dataSourceId) {
      return [] as Option[];
    }

    const dataSource = dataSourceOptionsSource?.find(
      (x: DataSourceOptionModel) => x.id.toLowerCase() === dataSourceId.toLowerCase()
    );

    return dataSource?.dataSourceVersions;
  };

  const selectedEPDBasedOnUnit = epdBasedOnUnitOptions.find(
    (x: any) =>
      e.lcaSpecification?.epdBasedOnUnit &&
      x.value === Object.keys(EPDBasedOnUnitEnum).indexOf(e.lcaSpecification?.epdBasedOnUnit).toString()
  );

  const selectedLCASoftwareOption = lcaSoftwareOptions?.find(
    (x) => e.lcaSpecification?.lcaSoftware && x.id.toString() === e.lcaSpecification?.lcaSoftware.toString()
  );

  const getSelectedLCASoftwareVersionOption = (lcaSoftware: any, lcaSoftwareVersionId: string | undefined) => {
    if (lcaSoftware?.versionRequired) {
      return (
        lcaSoftware?.lcaSoftwareVersions?.find(
          (x: Option) => x.value.toLowerCase() === lcaSoftwareVersionId?.toLowerCase()
        ) ?? null
      );
    }

    if (!lcaSoftware) {
      return null;
    }

    return { value: '', label: 'N/A' } as Option;
  };

  const selectedScrapInputsContributionLevel = scrapInputsContributionLevelOptions.find(
    (x: any) =>
      e.lcaSpecification?.scrapInputsContributionLevel &&
      x.value ===
        Object.keys(ScrapInputsContributionLevelEnum).indexOf(e.lcaSpecification?.scrapInputsContributionLevel).toString()
  );

  const selectedReferenceFlowUnit = referenceFlowUnits?.find(
    (x: DictionaryValue) => x.value.toLowerCase() === referenceFlowData?.unitId?.toLowerCase()
  )?.description;

  const referenceFlowName =
    e?.lcaSpecification?.epdBasedOnUnit === EPDBasedOnUnitEnum.DeclaredUnit
      ? 'Declared unit and reference flow'
      : 'Reference flow';

  const isTransportationTableEnabled = e?.lcaSpecification?.shouldTransportationPerformanceBeFetched ?? false;
  const isCO2Visible = e?.lcaSpecification?.shouldDeclarationsBeFetched ?? false;

  return (
    <PDF_PAGE>
      <DebugOnly>
        <RenderCounter componentName="LCA information" threshold={3} />
      </DebugOnly>

      <PDF_H1>LCA information</PDF_H1>
      <PDF_Table>
        <thead>
          <tr>
            <th style={{ width: '30%' }}></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>EPD based on declared or functional unit</td>
            <td>{selectedEPDBasedOnUnit?.label}</td>
          </tr>
          {e?.lcaSpecification?.epdBasedOnUnit === EPDBasedOnUnitEnum.FunctionalUnit && (
            <tr>
              <td>Functional unit description</td>
              <td>{e?.lcaSpecification?.functionalUnitDescription}</td>
            </tr>
          )}
          <tr>
            <td>{referenceFlowName}</td>
            <td>
              {referenceFlowData?.productFlowName}
              <br />
              {selectedReferenceFlowUnit}, {referenceFlowData?.value}
            </td>
          </tr>
          <tr>
            <td>Conversion factor to mass</td>
            <td>{conversionFactorData?.value}</td>
          </tr>
          <tr>
            <td>Datasources used for this EPD</td>
            <td>
              {dataSources?.map((item: EPDDataSourceModel, index: number) => (
                <React.Fragment key={index}>
                  {
                    dataSourceOptionsSource?.find(
                      (x: DataSourceOptionModel) => x.id.toLowerCase() === item.dataSourceId?.toLowerCase()
                    )?.name
                  }
                  <br />
                  {
                    getDataSourceVersionOptions(item.dataSourceId)?.find(
                      (x: Option) => x.value.toLowerCase() === item?.dataSourceVersionId?.toLowerCase()
                    )?.label
                  }
                  {index < dataSources.length - 1 && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                </React.Fragment>
              ))}
            </td>
          </tr>
          <tr>
            <td>LCA Software</td>
            <td>
              {selectedLCASoftwareOption?.name} <br /> 
              {getSelectedLCASoftwareVersionOption(selectedLCASoftwareOption, e?.lcaSpecification?.lcaSoftwareVersion)?.label}
            </td>
          </tr>
          <tr>
            <td>Additional information about the underlying LCA-based information</td>
            <td>{e?.lcaSpecification?.lcaBasedDescription}</td>
          </tr>
          <tr>
            <td>List of characterisation methods</td>
            <td>{e?.lcaSpecification?.characterisationMethods}</td>
          </tr>
          <tr>
            <td>Technology description including background system</td>
            <td>{e?.lcaSpecification?.technologyDescription}</td>
          </tr>
          <tr>
            <td>Scrap inputs contribution level</td>
            <td>{selectedScrapInputsContributionLevel?.label}</td>
          </tr>
          {e.infrastructureAndCapitalGoods?.includedInProcesses === YES_NO.NO &&
            !e.infrastructureAndCapitalGoods?.contributionLevel && (
              <tr>
                <td>Infrastructure and capital goods</td>
                <td>Excluded</td>
              </tr>
            )}
          {e.infrastructureAndCapitalGoods?.contributionLevel === YES_NO.YES && (
            <tr>
              <td>Infrastructure and capital goods</td>
              <td>
                Included and contributing more than 10% to the cradle-to-gate results for all environmental impact indicators
              </td>
            </tr>
          )}
          {e.infrastructureAndCapitalGoods?.contributionLevel === YES_NO.NO && (
            <tr>
              <td>Infrastructure and capital goods</td>
              <td>Excluded</td>
            </tr>
          )}
          {e.infrastructureAndCapitalGoods?.includedInProcesses === YES_NO.YES &&
            e.infrastructureAndCapitalGoods?.contributionLevel === YES_NO.YES && (
              <tr>
                <td>Detailed description of infrastructure and capital goods</td>
                <td>
                  <StyledWrapper
                    dangerouslySetInnerHTML={{
                      __html: e.infrastructureAndCapitalGoods?.description ?? '',
                    }}
                  ></StyledWrapper>
                </td>
              </tr>
            )}
        </tbody>
      </PDF_Table>
      <br />

      <DataQualityAssessmentAndReferenceYearsPdf epdVersionId={epdVersionId} />

      {e.lcaSpecification?.scrapInputsContributionLevel &&
        e.lcaSpecification?.scrapInputsContributionLevel === ScrapInputsContributionLevelEnum.MorePermissiblePercent && (
          <ContributionOfScrapInputsGridPdf epdVersionId={epdVersionId} />
        )}
      <div style={{ pageBreakBefore: 'always' }}>
        <ElectricityGridPdf epdVersionId={epdVersionId} />
      </div>
      {(isTransportationTableEnabled || isCO2Visible) && (
        <div style={{ pageBreakBefore: 'always' }}>
          {isTransportationTableEnabled && (
            <>
              <TransportationPerformanceGridPdf epdVersionId={epdVersionId} />
              <br />
              <br />
            </>
          )}
          {isCO2Visible && (
            <DeclarationOfCO2GridPdf
              epdVersionId={epdVersionId}
              disabled={cO2UptakeAssociation?.association !== YES_NO.YES}
            />
          )}
        </div>
      )}
    </PDF_PAGE>
  );
};

const StyledWrapper = styled.div`
  p {
    margin: 0;
  }
`;

export default LCAInformationPage;
