import 'primeflex/primeflex.css';

import { ReactNode } from 'react';
import { HeaderDialog } from 'styles/Styles.styled';

import { AlertIcon } from '../icons';
import { confirmGeneric } from './ConfirmGeneric';
import { Description } from './styled';

type TProps = {
  caption?: string | undefined;
  description?: ReactNode;
  acceptLabel: string;
  rejectLabel?: string | undefined;
  onConfirm: any;
  onReject?: any;
};

export const confirmAlert = ({ caption, description, acceptLabel, rejectLabel, onConfirm, onReject }: TProps) => {
  return confirmGeneric({
    contents: (
      <>
        <AlertIcon />
        <HeaderDialog>{caption || 'Are you sure?'}</HeaderDialog>
        <Description>{description}</Description>
      </>
    ),
    acceptLabel: acceptLabel,
    rejectLabel: rejectLabel ?? 'Cancel',
    onConfirm,
    onReject,
  });
};
