import React from 'react';
import { useHistory } from 'react-router-dom';
import { TabPanel } from 'primereact/tabview';
import { TabViewStyled } from 'styles/v2/Styles.styled';
import { EpdLinks } from 'routes/EpdRoutes';
import AdditionalInformationTab from './AdditionalInformationTab';
import DefinitionTab from './DefinitionTab';
import OrganizationTab from './OrganizationTab';
import PcrLcaAndVerificationTab from './PcrLcaAndVerificationTab';
import { compilerStep1Tabs } from './compilerNavConstants';

const Step1: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  tab: string | null | undefined;
  validationState: any;
}> = ({ epdId, epdVersionId, tab, validationState }) => {
  const history = useHistory();

  let tabIndex = compilerStep1Tabs.findIndex((t) => t === tab);
  if (tabIndex === -1) {
    tabIndex = 0;
  }

  return (
    <TabViewStyled
      activeIndex={tabIndex}
      onTabChange={(e) =>
        history.push(EpdLinks.epdCompilerSpecific(epdId, { step: 'step1', tab: compilerStep1Tabs[e.index] }))
      }
    >
      <TabPanel header="EPD definition">
        <DefinitionTab epdId={epdId} epdVersionId={epdVersionId!} validationState={validationState} />
      </TabPanel>
      <TabPanel header="PCR, LCA, EPD verification">
        <PcrLcaAndVerificationTab epdId={epdId} epdVersionId={epdVersionId} validationState={validationState} />
      </TabPanel>
      <TabPanel header="Organisation information">
        <OrganizationTab
          epdId={epdId}
          epdVersionId={epdVersionId}
          canUpload={false}
          isReadOnly={false}
          validationState={validationState}
        />
      </TabPanel>
      <TabPanel header="Additional information">
        <AdditionalInformationTab epdId={epdId} epdVersionId={epdVersionId!} isReadOnly={false} />
      </TabPanel>
    </TabViewStyled>
  );
};

export default Step1;
