import React, { ChangeEvent } from 'react';
import { ButtonUploadIcon } from 'components/v2/icons';
import { FileModel } from 'services/EpdClient';
import FileUpload from 'components/v2/file-upload/FileUpload';
import SmartLabel from 'components/v2/labels/SmartLabel';
import FilesList from 'components/v2/file-row/FilesList';
import _ from 'lodash';
import { HelpBox } from 'components/v2/help-boxes';
import TextWithAnimatedEllipsis from 'components/v2/file-upload/TextWithAnimatedEllipsis';

interface CertificateSelectorProps {
  processCertificates: FileModel[];
  onFileSelected: any;
  onRemoveCertificate: any;
  error: any;
  showUploadingIndicator: boolean;
}

const CertificateSelector: React.FunctionComponent<CertificateSelectorProps> = ({
  processCertificates,
  onFileSelected,
  onRemoveCertificate,
  error,
  showUploadingIndicator,
}) => {
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];
    if (!file) {
      return null;
    }

    onFileSelected && onFileSelected(propertyName, file);
  };

  return (
    <div>
      <SmartLabel
        label={'Process certificate'}
        tooltip="The certificate on the EPD process that was issued by an accredited certification body. The limited scope of the EPD process shall cover the scope of the EPD, for example, in terms of CPC codes, PCR(s), type of EPD, etc."
        required
        error={error}
        hasValue={!!processCertificates?.length}
      />
      {processCertificates?.length < 1 && (
        <HelpBox>Please note, the process certificate should be uploaded as it is a mandatory type.</HelpBox>
      )}
      <FilesList files={processCertificates} onRemoveFile={onRemoveCertificate} />
      <FileUpload
        disabled={processCertificates?.length >= 1}
        label="Upload certificate"
        name="processCertificate"
        icon={ButtonUploadIcon}
        accept=".pdf"
        validExtensions={['pdf']}
        onFileUpload={uploadFile}
      />
      {showUploadingIndicator && <TextWithAnimatedEllipsis className="mt-2">Uploading file</TextWithAnimatedEllipsis>}
    </div>
  );
};

export default CertificateSelector;
