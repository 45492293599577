import { Dialog, DialogProps } from 'primereact/dialog';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

type TProps = PropsWithChildren<
  {
    isOpened: boolean;
    header: string;
  } & DialogProps
>;

const EditDialog = ({ isOpened, header, children, ...props }: TProps) => {
  return (
    <Dialog header={header} visible={isOpened} style={{ width: '50vw' }} footer={null} {...props}>
      <DialogContentContainer>{children}</DialogContentContainer>
    </Dialog>
  );
};

const DialogContentContainer = styled.div`
  padding: 0 2rem 2rem 2rem;
  overflow-y: auto;
`;

export default EditDialog;
