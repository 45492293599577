import { customToolbarEpd } from 'components/form/customToolbar';
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { isNullOrEmptyHtml } from 'pages/wizard/Validation';
import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';

import useIsReadOnlyMode from '../compiler/hooks/useIsReadOnlyMode';
import SmartLabel from '../labels/SmartLabel';

const WizardHTMLEditor = ({
  name,
  label,
  tooltip,
  tooltipPosition,
  labelStyle,
  value,
  error,
  onChanged,
  disabled,
  ...props
}: any) => {
  const [editorState, setEditorState] = useState<EditorState | null>(
    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value ?? '') as any))
  );

  const newValue = draftToHtml(convertToRaw(editorState!.getCurrentContent()));
  const isReadOnly = useIsReadOnlyMode() || disabled;

  return (
    <div>
      {label && (
        <SmartLabel
          label={label}
          required={props.required}
          labelStyle={labelStyle}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={!!value && !isNullOrEmptyHtml(newValue)}
          error={error}
        />
      )}
      <StyledEditorWrapper isReadOnly={isReadOnly}>
        <Editor
          toolbarHidden={false}
          toolbar={customToolbarEpd}
          editorState={editorState}
          onEditorStateChange={(newEditorState) => setEditorState(newEditorState)}
          onBlur={() => {
            onChanged(name, newValue);
          }}
          readOnly={isReadOnly}
          {...props}
        />
      </StyledEditorWrapper>
    </div>
  );
};

const StyledEditorWrapper = styled.div<{ isReadOnly: boolean }>`
  .rdw-option-wrapper {
    background-color: ${(props) =>
      props.isReadOnly ? props.theme.colors.elementsInputBorder : props.theme.colors.secondaryLightGreen30};
    pointer-events: ${(props) => (props.isReadOnly ? 'none' : 'all')};
  }
  .rdw-editor-main {
    border: 1px solid ${(props) => props.theme.colors.elementsInputBorder};
    padding: 0 1rem;
    font-size: 14px;
    height: fit-content;
    min-height: 100px;
    max-height: 400px;
    border-radius: 2px;
    &:focus-within,
    &:hover {
      border-color: ${(props) =>
        props.isReadOnly ? props.theme.colors.elementsInputBorder : props.theme.colors.primaryOrange};
    }
    background-color: ${(props) =>
      props.isReadOnly ? props.theme.colors.elementsFilledInput : props.theme.colors.primaryWhite};
  }
`;

export default WizardHTMLEditor;
