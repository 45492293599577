import React, { useRef, useState } from 'react';
import { PrimaryButton, SecondaryButton } from 'components/v2/buttons';
import { InputTextarea } from 'primereact/inputtextarea';
import styled from 'styled-components';
import SmartLabel from 'components/v2/labels/SmartLabel';
import EditDialog from '../copy/dialogs/EditDialog';

export interface ConfirmWithCommentPayload {
  commentText: string;
}

interface TProps {
  isOpened: boolean;
  onClose(payload: ConfirmWithCommentPayload | null): void;

  header: string;
  help: React.ReactElement;
  inputLabelText: string;
  primaryButtonText: string;

  isCommentRequired?: boolean;
}

const ConfirmWithCommentDialog = ({
  isOpened,
  onClose,
  header,
  help,
  inputLabelText,
  primaryButtonText,
  isCommentRequired,
}: TProps) => {
  const [commentText, setCommentText] = React.useState<string>('');
  const [isCommentTouched, setCommentTouched] = useState<boolean>(false);
  const isDismissingRef = useRef<boolean>(false);
  const haveError = !!isCommentRequired && !commentText;

  const handleDismiss = () => {
    isDismissingRef.current = true;
    onClose(null);
  };

  const handlePrimaryButtonClick = () => {
    if (haveError) {
      setCommentTouched(true);
      return;
    }

    isDismissingRef.current = false;
    onClose({ commentText });
  };

  return (
    <StyledEditDialog
      header={header}
      isOpened={isOpened}
      onHide={handleDismiss}
      transitionOptions={
        {
          onExited() {
            // Reset state only after closing animation has finished to avoid noticeable flickering
            setCommentTouched(false);
            if (!isDismissingRef.current) {
              setCommentText('');
            }
          },
        } as any
      }
    >
      {help}
      <StyledSmartLabel htmlFor="confirmDialogComment" label={inputLabelText} error={haveError ? 'Required' : undefined} />
      <StyledInputTextarea
        id="confirmDialogComment"
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        onBlur={() => setCommentTouched(true)}
        style={{ width: '100%' }}
        rows={3}
        placeholder="Type here"
        autoResize
      />
      <ButtonContainer>
        <SecondaryButton label="Cancel" onClick={handleDismiss} />
        <PrimaryButton
          label={primaryButtonText}
          onClick={handlePrimaryButtonClick}
          disabled={haveError && isCommentTouched}
        />
      </ButtonContainer>
    </StyledEditDialog>
  );
};

const StyledEditDialog = styled(EditDialog)`
  & .p-dialog-header .p-dialog-title {
    font-familty: Inter;
    font-size: 16px;
  }
`;

const StyledSmartLabel = styled(SmartLabel)`
  margin-top: 31px;
  margin-bottom: 10px;
`;

const StyledInputTextarea = styled(InputTextarea)`
  border-color: ${(props) => props.theme.colors.elementsInputBorder};
  border-radius: 2px;
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  gap: 16px;
`;
export default ConfirmWithCommentDialog;
