import React from 'react';
import WizardInputSkeleton from 'components/v2/epd-wizard-inputs/WizardInputSkeleton';
import { FieldPanel, FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Skeleton } from 'primereact/skeleton';

const SkeletonSpecificationTab: React.FC<{}> = () => {
  return (
    <>
      <FieldPanelFullWidth>
        <Skeleton width="10rem" />
        <WizardInputSkeleton />
      </FieldPanelFullWidth>
      <FieldPanelFullWidth>
        <Skeleton width="10rem" />
        <div>
          <Skeleton width="20rem" />
          <Skeleton height="67px" className="mt-2" />
        </div>
        <Skeleton height="177px" />
      </FieldPanelFullWidth>
      <FieldPanel style={{ alignItems: 'flex-start' }}>
        <div className="w-full">
          <Skeleton width="10rem" />
        </div>
        <div>
          <WizardInputSkeleton />
          <Skeleton width="8rem" className="mt-1" />
        </div>
        <div>
          <WizardInputSkeleton />
        </div>
        <div className="w-full">
          <Skeleton height="3rem" />
          <Skeleton width="8rem" className="mt-1" />
        </div>
      </FieldPanel>
    </>
  );
};

export default SkeletonSpecificationTab;
