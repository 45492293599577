import React from 'react';
import { EPDProductInformationModel } from 'types/types';

import GeographySection from './GeographySection';
import LifespanSection from './LifespanSection';
import ProductClassificationSection from './ProductClassificationSection';
import ProductDetailsSection from './ProductDetailsSection';
import ProductionSitesSection from './ProductionSitesSection';
import MaterialPropertiesSection from './MaterialPropertiesSection';

const ProductDataSection: React.FC<{
  epdId?: string;
  epdVersionId: string;
  product: EPDProductInformationModel | undefined;
  onChange: any;
  errors: any;
}> = ({ epdId, epdVersionId, product, onChange, errors }) => {
  return (
    <>
      <ProductDetailsSection
        product={product}
        epdId={epdId!}
        epdVersionId={epdVersionId}
        onChange={(name: string, value: any) => onChange(name, value, product?.id as string, epdVersionId)}
        errors={errors}
      />
      <MaterialPropertiesSection
        product={product}
        onChange={(name: string, value: any) => onChange(name, value, product?.id as string, epdVersionId)}
        errors={errors}
      />
      <ProductionSitesSection product={product} epdVersionId={epdVersionId} errors={errors} />
      <ProductClassificationSection
        product={product}
        onChange={(name: string, value: any) => onChange(name, value, product?.id as string, epdVersionId)}
        errors={errors}
      />
      <GeographySection
        product={product}
        onChange={(name: string, value: any) => onChange(name, value, product?.id as string, epdVersionId)}
        errors={errors}
      />
      <LifespanSection
        product={product}
        onChange={(name: string, value: any) => onChange(name, value, product?.id as string, epdVersionId)}
      />
    </>
  );
};

export default ProductDataSection;
