import { FlattenSimpleInterpolation, css } from 'styled-components';

export interface Theme {
  colors: ThemeColors;
  typography: ThemeTypography;
  media: any;
  fonts: any;
  fontFamilies: any;
  variables: any;
  breakpoints: any;
  fontSizes: any;
  matchMedia: any;
  logo: any;
  verifiersUrl: string;
  serviceAgreementUrl: string;
}

export type ThemeTypography = {
  headerH2: FlattenSimpleInterpolation;
  headerH4: FlattenSimpleInterpolation;
  headerCaptionRegular: FlattenSimpleInterpolation;
  headerCaptionSemiBold: FlattenSimpleInterpolation;
  headerCaptionMedium: FlattenSimpleInterpolation;
  bodyInput: FlattenSimpleInterpolation;
  bodyCellBody: FlattenSimpleInterpolation;
  contentTableBodyXs: FlattenSimpleInterpolation;
  contentTableTitleXs: FlattenSimpleInterpolation;
  buttonButtonSmall: FlattenSimpleInterpolation;
  bodyBody14Regular: FlattenSimpleInterpolation;
  bodyBody14Semibold: FlattenSimpleInterpolation;
  buttonButtonDefault: FlattenSimpleInterpolation;
  statusLabelsRedText: FlattenSimpleInterpolation;
};

export const defaultTypography = {
  headerCaptionRegular: css`
    color: var(--primary-black, #151515);

    /* Header/Caption Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  `,
  headerCaptionSemiBold: css`
    color: #000;

    /* Header/Caption Semi Bold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  `,
  headerCaptionMedium: css`
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  `,
  headerH2: css`
    color: var(--primary-black, #151515);

    /* Header/H2 */
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `,
  headerH4: css`
    color: var(--primary-black, #151515);
    /* Header/H4 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `,
  bodyBody14Regular: css`
    color: var(--primary-black-80, rgba(21, 21, 21, 0.8));

    /* Body/Body 14 Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  `,
  bodyBody14Semibold: css`
    color: var(--primary-black, #151515);
    /* Body/Body 14 Semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  `,
  bodyInput: css`
    color: var(--primary-black-70, rgba(21, 21, 21, 0.7));

    /* Body/Input */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  bodyCellBody: css`
    color: var(--primary-black-80, rgba(21, 21, 21, 0.8));

    /* Body/Cell body */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
  `,
  contentTableBodyXs: css`
    color: var(--Primary-Black, #151515);

    /* Content/table-body-xs */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
  `,
  contentTableTitleXs: css`
    color: var(--Primary-Black, #151515);

    /* Content/table-title-xs */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `,
  statusLabelsRedText: css`
    color: var(--status-labels-red-text, #cc302b);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  `,
  buttonButtonSmall: css`
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  `,
  buttonButtonDefault: css`
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  `,
};

export type ThemeColors = {
  lightGray: string;
  gray: string;
  mediumGrey: string;
  darkGray: string;
  lightGreen: string;
  green: string;
  mediumGreen: string;
  lightOrange: string;
  orange: string;
  darkOrange: string;
  driedGreen: string;
  darkKhaki: string;
  shadedRed: string;
  black: string;
  regionColorLight: string;
  regionColor: string;
  regionColorMedium: string;
  regionColorDark: string;
  button: string;
  buttonHover: string;
  toolBoxImageFilter: string;

  //new
  // toolkit
  // primary
  primaryBlack: string;
  primaryBlack50: string;
  primaryBlack70: string;
  primaryBlack80: string;
  primaryWhite: string;
  primaryOrange: string;
  primaryOrange01: string;
  primaryGreen: string;
  // secondary
  secondaryMiddleGreen: string;
  secondaryLightGreen: string;
  secondaryLightGreen30: string;
  secondaryGray: string;
  // system status
  systemStatusError: string;
  systemStatusSuccess: string;
  systemStatusAttention: string;
  systemStatusUnknown: string;
  // status labels
  statusLabelRedText: string;
  statusLabelRedBg: string;
  statusLabelYellowText: string;
  statusLabelYellowBg: string;
  statusLabelGreenText: string;
  statusLabelGreenBg: string;
  statusLabelGrayText: string;
  statusLabelGrayBg: string;
  statusLabelBlueText: string;
  statusLabelBlueBg: string;
  // elements
  elementsButtonHover: string;
  elementsButtonDisabled: string;
  elementsFilledInput: string;
  elementsInputBorder: string;
  elementsTableRow: string;
  elementsDropDown: string;
  elementDropdownSelectedDisabled: string;
};

export const defaultThemeColors = {
  lightGray: '#eeeeee',
  gray: 'rgb(217,218,219)',
  mediumGrey: '#b6b6b6',
  darkGray: 'rgb(142,142,142)',
  lightGreen: 'rgb(165, 204, 178)',
  green: 'rgb(101, 133, 108)',
  mediumGreen: 'rgb(19,95,81)',
  lightOrange: '#EB7342',
  orange: 'rgb(231,81,19)',
  darkOrange: '#cf4811',
  driedGreen: 'rgb(108,140,116)',
  darkKhaki: '#CACF85',
  shadedRed: '#E65F5C',
  black: '#000000',
  regionColorLight: 'rgb(165, 204, 178)',
  regionColor: 'rgb(101, 133, 108)',
  regionColorMedium: 'rgb(19,95,81)',
  regionColorDark: '#000000',
  button: 'rgb(231, 81, 19)',
  buttonHover: '#EB7342',
  toolBoxImageFilter: 'invert() brightness(0.2) sepia(1) hue-rotate(120deg) saturate(7);',

  // toolkit
  // primary
  primaryBlack: '#151515',
  primaryBlack50: 'rgba(21, 21, 21, 0.5);',
  primaryBlack70: 'rgba(21, 21, 21, 0.7);',
  primaryBlack80: 'rgba(21, 21, 21, 0.8);',
  primaryWhite: '#FFFFFF',
  primaryOrange: '#E75113',
  primaryOrange01: 'rgba(231, 81, 19, 0.1)',
  primaryGreen: '#1E6052',
  // secondary
  secondaryMiddleGreen: '#6F896A',
  secondaryLightGreen: '#B4D0B6',
  secondaryLightGreen30: 'rgba(180, 208, 182, 0.3);',
  secondaryGray: '#D9DADB',
  // system status
  systemStatusError: '#E20238',
  systemStatusSuccess: '#02C038',
  systemStatusAttention: '#F5DD00',
  systemStatusUnknown: '#EBECF4',
  // status labels
  statusLabelRedText: '#CC302B',
  statusLabelRedBg: '#F6E5E4',
  statusLabelYellowText: '#864A14',
  statusLabelYellowBg: '#FBEBBA',
  statusLabelGreenText: '#1E6052',
  statusLabelGreenBg: '#E2EBE3',
  statusLabelGrayText: '#7F8081',
  statusLabelGrayBg: '#E3E3E3',
  statusLabelBlueText: '#223B60',
  statusLabelBlueBg: '#EBF9FF',
  // elements
  elementsButtonHover: '#FA8656',
  elementsButtonDisabled: '#757575',
  elementsFilledInput: '#F3F4FB',
  elementsInputBorder: '#E0E0E0',
  elementsTableRow: '#F9F9FF',
  elementsDropDown: '#EAEAED',
  elementDropdownSelectedDisabled: '#495057',
};
