import FilesList from 'components/v2/file-row/FilesList';
import { CUSTOM_RESULT_KEYS } from 'constants/constants';
import React from 'react';
import { EPD_DICTIONARY_CODES, INDICATOR_GROUP_CODES } from 'services/api/constants';
import styled from 'styled-components';

import { YES_NO } from '../../constants';
import LcaResultsGridAdditionalConstruction from '../../grids/lca-results/LcaResultsGridAdditionalConstruction';
import LcaResultsGridAdditionalMandatory from '../../grids/lca-results/LcaResultsGridAdditionalMandatory';
import LcaResultsGridAdditionalVoluntary from '../../grids/lca-results/LcaResultsGridAdditionalVoluntary';
import LcaResultsGridCoreConstruction from '../../grids/lca-results/LcaResultsGridCoreConstruction';
import LcaResultsGridOutputFlowConstruction from '../../grids/lca-results/LcaResultsGridOutputFlowConstruction';
import LcaResultsGridResourceConstruction from '../../grids/lca-results/LcaResultsGridResourceConstruction';
import LcaResultsGridWasteConstruction from '../../grids/lca-results/LcaResultsGridWasteConstruction';
import LcaCustomBestWorstResultsGrid from '../../grids/lca-results/custom/LcaCustomBestWorstResultsGrid';
import LcaCustomBiogenicResultsGrid from '../../grids/lca-results/custom/LcaCustomBiogenicResultsGrid';
import LcaCustomAdditionalResultsGrid from '../../grids/lca-results/custom/LcaCustomAdditionalResultsGrid';
import VersionHistoryExpandableGridWrapper from '../VersionHistoryExpandableGridWrapper';
import VersionHistorySection from '../VersionHistorySection';
import { VersionHistorySummaryGridSkeleton } from '../VersionHistorySummaryGrid';
import { useEpdVersionHistoryDataOrWhileLoadingThenNull } from '../utils/EpdVersionHistoryDataContext';
import VersionHistoryLcaCustomResultGrid from './VersionHistoryLcaCustomResultGrid';

const VersionHistoryLcaResultsSection: React.FC<{}> = () => {
  const data = useEpdVersionHistoryDataOrWhileLoadingThenNull();

  return (
    <VersionHistorySection title="LCA results">
      {data ? (
        <>
          <div>
            <VersionHistoryExpandableGridWrapper title="Mandatory environmental performance indicators according to EN 15804">
              <LcaResultsGridCoreConstruction
                epdVersionId={data.epdVersionId}
                rows={data.lcaResults.lcaResults?.filter((x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.CORE15804)}
                isAdditionalDisclaimerVisible={data.infrastructureAndCapitalGoods?.includedInProcesses === YES_NO.YES}
                disabled
                preferEnabledAppearanceEvenWhenDisabled
              />
            </VersionHistoryExpandableGridWrapper>

            <VersionHistoryExpandableGridWrapper title="Additional mandatory environmental performance indicators">
              <LcaResultsGridAdditionalMandatory
                epdVersionId={data.epdVersionId}
                rows={data.lcaResults.lcaResults?.filter(
                  (x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.ADDITIONALMANDATORY
                )}
                disabled
                preferEnabledAppearanceEvenWhenDisabled
              />
            </VersionHistoryExpandableGridWrapper>

            <VersionHistoryExpandableGridWrapper title="Additional voluntary environmental performance indicators according to EN 15804">
              <LcaResultsGridAdditionalConstruction
                epdVersionId={data.epdVersionId}
                rows={data.lcaResults.lcaResults?.filter(
                  (x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.ADDITIONALVOLUNTARY15804
                )}
                isAdditionalDisclaimerVisible={data.infrastructureAndCapitalGoods?.includedInProcesses === YES_NO.YES}
                disabled
                preferEnabledAppearanceEvenWhenDisabled
              />
            </VersionHistoryExpandableGridWrapper>

            <VersionHistoryExpandableGridWrapper title="Additional voluntary environmental performance indicators">
              <LcaResultsGridAdditionalVoluntary
                epdVersionId={data.epdVersionId}
                disabled
                preferEnabledAppearanceEvenWhenDisabled
              />
            </VersionHistoryExpandableGridWrapper>

            <VersionHistoryExpandableGridWrapper title="Resource use indicators according to EN 15804">
              <LcaResultsGridResourceConstruction
                epdVersionId={data.epdVersionId}
                rows={data.lcaResults.lcaResults.filter((x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.RESOURCE15804)}
                disabled
                preferEnabledAppearanceEvenWhenDisabled
              />
            </VersionHistoryExpandableGridWrapper>

            <VersionHistoryExpandableGridWrapper title="Waste indicators according to EN 15804">
              <LcaResultsGridWasteConstruction
                epdVersionId={data.epdVersionId}
                rows={data.lcaResults.lcaResults.filter(
                  (x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.WASTE15804
                )}
                disabled
                preferEnabledAppearanceEvenWhenDisabled
              />
            </VersionHistoryExpandableGridWrapper>

            <VersionHistoryExpandableGridWrapper title="Output flow indicators according to EN 15804">
              <LcaResultsGridOutputFlowConstruction
                epdVersionId={data.epdVersionId}
                rows={data.lcaResults.lcaResults.filter(
                  (x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.OUTPUTFLOW15804
                )}
                disabled
                preferEnabledAppearanceEvenWhenDisabled
              />
            </VersionHistoryExpandableGridWrapper>

            {data.lcaCustomResults.map((customResult) => {
              const resultTypeEntry = customResult.resultType
                ? data.dictionaries.find(
                    (d) =>
                      d.dictionaryCode === EPD_DICTIONARY_CODES.ADDITIONAL_LCA_RESULTS_TYPE &&
                      d.key === Number(customResult.resultType)
                  )
                : null;
              const title = resultTypeEntry ? resultTypeEntry.value : 'Additional environmental performance results';
              return (
                <VersionHistoryExpandableGridWrapper key={customResult.id} title={title} hideMaximizeButton>
                  <VersionHistoryLcaCustomResultGrid customResult={customResult} />

                  <CustomResultGridWrapper>
                    {[CUSTOM_RESULT_KEYS.A4C4, CUSTOM_RESULT_KEYS.MODELING, CUSTOM_RESULT_KEYS.ELECTRICITY].includes(
                      customResult.resultType
                    ) && (
                      <LcaCustomAdditionalResultsGrid
                        epdVersionId={data.epdVersionId}
                        customResult={customResult}
                        disabled
                        preferEnabledAppearanceEvenWhenDisabled
                      />
                    )}
                    {customResult.resultType === CUSTOM_RESULT_KEYS.BIOGENIC && (
                      <LcaCustomBiogenicResultsGrid
                        epdVersionId={data.epdVersionId}
                        customResultId={customResult.id}
                        disabled
                        preferEnabledAppearanceEvenWhenDisabled
                      />
                    )}
                    {[CUSTOM_RESULT_KEYS.BEST, CUSTOM_RESULT_KEYS.WORST].includes(customResult.resultType) && (
                      <LcaCustomBestWorstResultsGrid
                        epdVersionId={data.epdVersionId}
                        customResultId={customResult.id}
                        disabled
                        preferEnabledAppearanceEvenWhenDisabled
                      />
                    )}
                  </CustomResultGridWrapper>
                </VersionHistoryExpandableGridWrapper>
              );
            })}
          </div>
        </>
      ) : (
        <VersionHistorySummaryGridSkeleton skeletonRowsCount={6} />
      )}
    </VersionHistorySection>
  );
};

export default VersionHistoryLcaResultsSection;

const CustomResultGridWrapper = styled.div`
  margin-top: 16px;
`;
