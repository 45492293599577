import { ReactNode } from 'react';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import { LCAModules } from 'types/types';

import { Placeholder } from './StyledGrid';

export type LcaGridNote = { note: ReactNode; description: ReactNode };

export function columnTotal<T>(columnName: keyof T, rows: T[] | undefined | null) {
  const total = rows?.reduce((a: number, b: any) => {
    const value = b[columnName] as number | undefined;
    return a + (value ? value : 0);
  }, 0);

  const finalTotal = Number(total);

  const totalString = finalTotal.toString();
  const [integerPart, decimalPart] = totalString.split('.');

  if (!decimalPart) {
    return integerPart;
  }

  return integerPart + '.' + decimalPart.substring(0, 2);
}

export const mandatoryBodyTemplate = (value: any, error?: any) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' /*, position: 'relative'*/ }}>
      <span>{value}</span>
      {(error || !value) && <TooltipErrorIcon content={error || 'Required'} />}
    </div>
  );
};

export const validatedBodyTemplateWithPlaceholder = (value: any, placeholder?: string, error?: any) => {
  if (value && !error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{value}</span>
      </div>
    );
  }

  if (value && error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{value}</span>
        <TooltipErrorIcon content={error || 'Required'} />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Placeholder>{placeholder}</Placeholder>
      <TooltipErrorIcon content={error || 'Required'} />
    </div>
  );
};

export const cellTemplateWithPlaceholder = (value: any, placeholder?: string) => {
  if (value) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
        <span>{value}</span>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
      <Placeholder>{placeholder}</Placeholder>
    </div>
  );
};

export const validatedCellTemplate = (value: any, error?: any, placeholder?: string) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
      <span>{value || <Placeholder>{placeholder}</Placeholder>}</span>
      {error && <TooltipErrorIcon content={error} />}
    </div>
  );
};

export const cellTotalTemplate = (fieldName: string, rows: any, errors?: any) =>
  validatedCellTemplate(columnTotal(fieldName, rows), errors?.[`${fieldName}Total`]);

export const validatedCellPercentTemplate = (value: any, error?: any) => {
  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      <span>{value}%</span>
      {error && <TooltipErrorIcon content={error} />}
    </div>
  );
};

export const cellTotalPercentTemplate = (fieldName: string, rows: any, errors?: any) => {
  return validatedCellPercentTemplate(columnTotal(fieldName, rows), errors?.[`${fieldName}Total`]);
};

export const emptyLCAModuleValues: LCAModules<string> = {
  a1: '',
  a2: '',
  a3: '',
  a1A2A3: '',
  a4: '',
  a5: '',
  b1: '',
  b2: '',
  b3: '',
  b4: '',
  b5: '',
  b6: '',
  b7: '',
  c1: '',
  c2: '',
  c3: '',
  c4: '',
  d: '',
  variation: '',
};
