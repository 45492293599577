import React from 'react';
import WizardInputSkeleton from 'components/v2/epd-wizard-inputs/WizardInputSkeleton';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';

const SkeletonDefinitionTab: React.FC<{}> = () => {
  return (
    <FieldPanelFullWidth>
      {Array(10)
        .fill(0)
        .map((_, i) => (
          <WizardInputSkeleton key={i} />
        ))}
    </FieldPanelFullWidth>
  );
};

export default SkeletonDefinitionTab;
