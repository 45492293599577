import { Column, ColumnProps } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {
  useCreateEpdLcaResultAdditional,
  useDeleteEpdLcaResultsAdditional,
  useUpdateEpdLcaResultAdditional,
  useUpsertEpdLcaResultAdditionalAcronyms,
} from 'services/api/mutationsLcaResults';
import { LcaResultModel } from 'types/types';

import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';
import DynamicGridHeader from '../DynamicGridHeader';
import { emptyLCAModuleValues, LcaGridNote } from '../GridService';
import { ColumnFooterInputText, DataTableStyled } from '../StyledGrid';
import useDynamicGridWithEmptyFirstRow from '../useDynamicGridWithEmptyFirstRow';
import useGridRowSelectionViaCheckbox from '../useGridRowSelectionViaCheckbox';
import { useEpdLcaResultsAdditionalAcronyms } from 'services/api/queries';

type TProps = {
  epdVersionId: string;
  rows: LcaResultModel[] | undefined;
  columns: ColumnProps[];
  notes: LcaGridNote[];
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};
const LcaResultsGridExtended = ({
  epdVersionId,
  rows,
  columns,
  notes,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
}: TProps) => {
  const isReadOnly = useIsReadOnlyMode() || !!disabled;

  const createMutation = useCreateEpdLcaResultAdditional(epdVersionId);
  const updateMutation = useUpdateEpdLcaResultAdditional(epdVersionId);
  const deleteMutation = useDeleteEpdLcaResultsAdditional(epdVersionId);

  const { dataTableProps, checkboxColumnNode, handleDeleteSelected, isDeleteDisabled, gridId } =
    useGridRowSelectionViaCheckbox<LcaResultModel>(isReadOnly, deleteMutation);

  const acronymsQuery = useEpdLcaResultsAdditionalAcronyms(epdVersionId);
  const upsertAcronymsMutation = useUpsertEpdLcaResultAdditionalAcronyms(epdVersionId);

  const rowClassName = () => ({ 'p-disabled': isReadOnly && !preferEnabledAppearanceEvenWhenDisabled });

  const { gridData, handleAddRow, handleCellEdit } = useDynamicGridWithEmptyFirstRow<LcaResultModel>({
    rows,
    createMutation,
    updateMutation,
    emptyEntity: { epdVersionId, ...emptyLCAModuleValues },
  });

  const header =
    isReadOnly && preferEnabledAppearanceEvenWhenDisabled ? null : (
      <DynamicGridHeader
        caption="Indicators data"
        onAddRow={handleAddRow}
        onDeleteRows={handleDeleteSelected}
        deleteRowsDisabled={isDeleteDisabled}
      />
    );

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Acronyms" colSpan={2} className="p-row-note" />
        <Column
          style={{ padding: '2px' }}
          footer={
            <ColumnFooterInputText
              type="text"
              placeholder={isReadOnly ? '' : 'Acronym¹ description; Acronym² - description; etc.'}
              defaultValue={acronymsQuery.data?.acronyms ?? ''}
              onBlur={(e) => {
                upsertAcronymsMutation.mutate({ acronyms: e.target.value });
              }}
              disabled={isReadOnly}
            />
          }
          colSpan={columns.length}
          className={isReadOnly ? 'p-row-note' : undefined}
        />
      </Row>
      {notes?.map((x, i) => (
        <Row key={i}>
          <Column footer={x.note} colSpan={2} className="p-row-note" />
          <Column footer={x.description} colSpan={columns.length} className="p-row-note" />
        </Row>
      ))}
    </ColumnGroup>
  );

  return (
    <DataTableStyled
      key={gridId}
      value={gridData}
      dataKey="ord"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      footerColumnGroup={footerGroup}
      header={header}
      editMode="cell"
      rowClassName={rowClassName}
      {...dataTableProps}
    >
      {!(isReadOnly && preferEnabledAppearanceEvenWhenDisabled) && checkboxColumnNode}

      {columns.map((x) => (
        <Column
          key={x.field}
          {...x}
          onCellEditComplete={x.editor ? handleCellEdit : undefined}
          {...(isReadOnly && { editor: undefined })}
        />
      ))}
    </DataTableStyled>
  );
};

export default LcaResultsGridExtended;
