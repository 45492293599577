import { UploadDocumentTypesClient } from './EpdClient';

const client = new UploadDocumentTypesClient(process.env.REACT_APP_API_URL);

class UploadDocumentTypesService {
  static getDocumentTypes = async (isForCompiler: boolean = false) => {
    return await client.getDocumentTypes(isForCompiler);
  };
}

export default UploadDocumentTypesService;
