import React from 'react';
import ConfirmWithCommentDialog, { ConfirmWithCommentPayload } from './ConfirmWithCommentDialog';
import { HelpBox } from '../help-boxes';

type Props = {
  isOpened: boolean;
  onClose(payload: ConfirmWithCommentPayload | null): void;
};

const ConfirmEditorialSendToPublishProcessCertificationDialog: React.FC<Props> = ({ isOpened, onClose }) => {
  return (
    <ConfirmWithCommentDialog
      header="Send to publish"
      help={
        <HelpBox>
          <span>
            You are allowed to make the editorial changes without updating the verification report, such as correction of
            spelling errors.
          </span>
          <span>
            If the EPD information was updated, either the product or LCA, you shall update the verification report and both
            the version and validity dates in “Step 4 - EPD verification” again.
          </span>
        </HelpBox>
      }
      inputLabelText="Changes description"
      primaryButtonText="Submit"
      isOpened={isOpened}
      onClose={onClose}
      isCommentRequired
    />
  );
};

export default ConfirmEditorialSendToPublishProcessCertificationDialog;
