import ManageAccounts from 'components/accounts/ManageAccounts';
import ManageInvoiceInfo from 'components/company/ManageInvoiceInfo';
import ManageSettings from 'components/company/ManageSettings';
import ManageMembers from 'components/member/ManageMembers';
import { ProcessStatus } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { TabModel } from 'models/tabModels';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CompaniesService from 'services/CompanyService';
import styled from 'styled-components';
import { Aside, Container, H2, MainView } from 'styles/Styles.styled';

const ManageCompany: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const [hasUserPermissionEditCompanySetting, setUserPermissionStatusEditCompanySetting] = React.useState<boolean>(false);
  const { company, changeCompany } = React.useContext(CompanyContext);
  const { companyid } = useParams<{ companyid: string }>();

  const tabs: TabModel[] = [
    {
      name: t('modelsToEdit.members'),
      component: <ManageMembers key={'members'} />,
      hidden: false,
    },
    {
      name: t('modelsToEdit.accounts'),
      component: <ManageAccounts companyId={company?.id || ''} key={'accounts'} />,
      hidden: false,
    },
    {
      name: t('modelsToEdit.settings'),
      component: <ManageSettings companyId={company?.id || ''} key={'settings'} />,
      hidden: false,
    },
    {
      name: t('modelsToEdit.invoiceInfo'),
      component: <ManageInvoiceInfo companyId={company?.id || ''} key={'invoiceInfo'} />,
      hidden: false,
    },
  ];

  React.useEffect(() => {
    const fetchCompany = async () => {
      try {
        const accesstoEditCompany = await CompaniesService.getAccessToEdit(companyid || '');
        setUserPermissionStatusEditCompanySetting(accesstoEditCompany);
        setStatus(ProcessStatus.Fetching);
        changeCompany && changeCompany(companyid);
        setStatus(ProcessStatus.Success);
      } catch {
        setStatus(ProcessStatus.Error);
      }
    };
    if (companyid && companyid !== '') {
      fetchCompany();
    }
  }, []);

  React.useEffect(() => {
    const fetchCompany = async () => {
      try {
        if (company && companyid && company.id != companyid) {
          setStatus(ProcessStatus.Fetching);
          changeCompany && changeCompany(companyid);
          setStatus(ProcessStatus.Success);
        }
      } catch {
        setStatus(ProcessStatus.Error);
      }
    };
    if (companyid && companyid !== '') {
      fetchCompany();
    }
  }, [company]);

  return (
    <div>
      {hasUserPermissionEditCompanySetting && (
        <Container>
          <MainView>
            <H2>{company?.name}&nbsp;</H2>
            <Tabs>
              {tabs.map(
                (tab, index) =>
                  !tabs.find((x) => x.component.key == tab.component.key)?.hidden && (
                    <Tab key={index} active={index === activeTab} onClick={() => setActiveTab(index)}>
                      {tab.name}
                    </Tab>
                  )
              )}
            </Tabs>
            {tabs.map((tab, index) => index === activeTab && tab.component)}
          </MainView>
          {status && <Aside style={{ backgroundColor: 'white' }}> </Aside>}
        </Container>
      )}
    </div>
  );
};

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: solid 3px ${(props) => props.theme.colors.gray};
`;

const Tab = styled.div<{ active?: boolean }>`
  ${(props) => props.theme.fonts.heading3};
  padding: 1rem;
  margin-right: 1px;
  background-color: ${(props) => (props.active ? props.theme.colors.gray : 'white')};
  border: solid 1px ${(props) => props.theme.colors.gray};
  border-top: solid 3px ${(props) => props.theme.colors.regionColorLight};
  cursor: pointer;
  &:hover {
    border-top-color: ${(props) => props.theme.colors.regionColor};
  }
`;

export default ManageCompany;
