import React from 'react';
import { VersionHistorySummaryGridSkeleton } from '../VersionHistorySummaryGrid';
import VersionHistoryProductInformationGrid from './VersionHistoryProductInformationGrid';
import SmartLabel from 'components/v2/labels/SmartLabel';
import { ImagesUpload } from 'components/v2/images-upload';
import { useEpdVersionHistoryDataOrWhileLoadingThenNull } from '../utils/EpdVersionHistoryDataContext';
import VersionHistorySection from '../VersionHistorySection';

const VersionHistoryProductInformationSection: React.FC<{}> = () => {
  const data = useEpdVersionHistoryDataOrWhileLoadingThenNull();

  if (!data) {
    return (
      <VersionHistorySection title="Product information">
        <VersionHistorySummaryGridSkeleton skeletonRowsCount={13} />
      </VersionHistorySection>
    );
  }

  return (
    <>
      {data.productsInformation.map((p) => (
        <VersionHistorySection
          key={p.id}
          title={
            data.productsInformation.length > 1 && p.productName
              ? `Product information - ${p.productName}`
              : 'Product information'
          }
        >
          <VersionHistoryProductInformationGrid product={p} />

          <div>
            <SmartLabel label="Product images" />
            <ImagesUpload
              name="ProductImages"
              images={p.productDetailsImages}
              onUpload={() => {}}
              onReorder={() => {}}
              onRemove={() => {}}
              disabled
              preferEnabledAppearanceEvenWhenDisabled
            />
          </div>
        </VersionHistorySection>
      ))}
    </>
  );
};

export default VersionHistoryProductInformationSection;
