import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import styled from 'styled-components';

export const PcrListFileDownloadIcon = styled.i`
  align-self: center;
  font-weight: 700;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primaryOrange};
`;

export const PcrListFileTitle = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  border-radius: 4px;
`;

export const PcrListFileItem = styled.div`
  padding: 1rem;
  margin-bottom: 0.5rem;
  background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  span {
    width: 80%;
  }
`;

export const AddButtonBox = styled.div`
  width: 100%;
  border-radius: 4px;
  border: none;
  justify-content: start;
  display: flex;
  max-height: 70px;

  .p-button.p-button-text {
    padding: 0;
    background-color: transparent;
    border: none;
    margin-top: 0.25rem;
    ${(props) => props.theme.fonts.standard};
  }
  .p-button.p-button-text:hover {
    background-color: transparent;
  }
  .p-button.p-button-text:disabled {
    color: #757575;
  }
`;

export const InputTextStyled = styled(InputText)`
  padding: 1rem;
  border: 1px solid
    ${(props) => (props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.elementsInputBorder)};
  border-radius: 2px;
  margin: 0;
  background-color: ${(props) => props.theme.colors.primaryWhite};

  &.p-component.p-disabled {
    background-color: ${(props) => props.theme.colors.elementsFilledInput};
  }

  &:focus {
    border-color: ${(props) => (props.disabled ? props.theme.colors.elementsInputBorder : props.theme.colors.primaryOrange)};
  }

  &:focus-visible {
    outline: 2px solid
      ${(props) => (props.disabled ? props.theme.colors.elementsInputBorder : props.theme.colors.primaryOrange)};
  }

  &:hover {
    border-color: ${(props) => (props.disabled ? props.theme.colors.elementsInputBorder : props.theme.colors.primaryOrange)};
  }

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 44px;
`;

export const PcrInputAddon = styled.span<{ disabled?: boolean | undefined }>`
  border: none;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.primaryWhite};
  cursor: auto;
  padding: 0.5rem 1rem 0.5rem 0rem;
  min-width: 0;
  .pi {
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 700;
  }
`;

export const PcrInputGroup = styled.div<{ disabled: boolean | undefined }>`
  border: 1px solid ${(props) => (props.disabled ? 'transparent' : props.theme.colors.elementsInputBorder)};
  &:disabled {
    border: none;
  }
  .p-inputgroup-addon:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  ${InputTextStyled} {
    border: none;
    cursor: auto;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
`;

export const PcrListItem = styled.div`
  padding: 1rem;
  margin-bottom: 0.25rem;
  cursor: auto;
  border: 1px solid ${(props) => props.theme.colors.elementsInputBorder};
  &.selected {
    background-color: ${(props) => props.theme.colors.elementsTableRow};
    border: none;
  }
`;

export const PcrListItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DownloadButton = styled(Button)`
  margin-top: 0.5rem;
  padding: 0.25rem 0.5rem;
  ${(props) => props.theme.fonts.textSmall}
  .pi {
    font-size: 0.75rem;
  }
`;

export const DialogContentContainer = styled.div`
  padding: 0.25rem 2rem 2rem 2rem;
`;

export const DialogStyled = styled(Dialog)`
  ${(props) => props.theme.fonts.textSmall}
  .p-dialog-header .p-dialog-title {
    font-weight: 700;
    padding: 0.75rem 0.5rem 1rem 1rem;
    ${(props) => props.theme.fonts.textSmall}
  }
`;
