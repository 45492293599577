import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import styled from 'styled-components';
import QRCodeItem from './QRCodeItem';
import { PrimaryButton, SecondaryButton } from 'components/v2/buttons';
import { useGetEpdQRCode } from 'services/api/queries';
import { QRCodeTypeEnum } from 'types/types';

interface Props {
  friendlyUrl: string | undefined;
  disabled: boolean;
}

const QRCodeDownloadDialog: React.FunctionComponent<Props> = ({ friendlyUrl, disabled }) => {
  const [isQRDownloadDialogOpen, setIsQRDownloadDialogOpen] = useState(false);
  const [selectedQRCodeType, setSelectedQRCodeType] = useState<QRCodeTypeEnum>();
  const { refetch, isFetching } = useGetEpdQRCode(friendlyUrl!, selectedQRCodeType as QRCodeTypeEnum);

  const handleDownload = async () => {
    try {
      const { data: blob } = await refetch();
  
      if (blob) {
        const url = window.URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.download = ''; 
        document.body.appendChild(link);
        link.click();
  
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
    }
  };  

  const footerContent = (
    <div>
      <SecondaryButton label="Cancel" onClick={() => setIsQRDownloadDialogOpen(false)} outlined />
      <PrimaryButton label="Download" onClick={handleDownload} disabled={!selectedQRCodeType || isFetching} autoFocus />
    </div>
  );

  return (
    <>
      <QRCodeButtonBox>
        <Button
          style={{ fontSize: 15, fontWeight: 500 }}
          type="button"
          label="+ Generate QR code"
          text
          onClick={() => setIsQRDownloadDialogOpen(true)}
          disabled={disabled}
        />
      </QRCodeButtonBox>
      <Dialog
        header={'Download QR code '}
        visible={isQRDownloadDialogOpen}
        style={{ width: 560 }}
        footer={footerContent}
        onHide={() => setIsQRDownloadDialogOpen(false)}
      >
        <DialogContentContainer>
          <InformationContainer style={{ marginTop: 0, fontSize: 14 }}>
            You can download and use this QR code to link directly to this page.{' '}
          </InformationContainer>
          <QRCodeItem
            label="Web size coloured (820 x 1110 px, 96 dpi, 32 bit)"
            value={QRCodeTypeEnum.WebSizeColoured}
            setValue={setSelectedQRCodeType}
            isChecked={selectedQRCodeType === QRCodeTypeEnum.WebSizeColoured}
          ></QRCodeItem>
          <QRCodeItem
            label="Web size grayscale (820 x 1110 px, 96 dpi, 32 bit)"
            value={QRCodeTypeEnum.WebSizeGrayscale}
            setValue={setSelectedQRCodeType}
            isChecked={selectedQRCodeType === QRCodeTypeEnum.WebSizeGrayscale}
          ></QRCodeItem>
          <QRCodeItem
            label="Print size coloured (1040 x 1340 px, 300 dpi, 32 bit)"
            value={QRCodeTypeEnum.PrintSizeColoured}
            setValue={setSelectedQRCodeType}
            isChecked={selectedQRCodeType === QRCodeTypeEnum.PrintSizeColoured}
          ></QRCodeItem>
          <QRCodeItem
            label="Print size grayscale (1040 x 1340 px, 300 dpi, 32 bit)"
            value={QRCodeTypeEnum.PrintSizeGrayscale}
            setValue={setSelectedQRCodeType}
            isChecked={selectedQRCodeType === QRCodeTypeEnum.PrintSizeGrayscale}
          ></QRCodeItem>
        </DialogContentContainer>
      </Dialog>
    </>
  );
};

const QRCodeButtonBox = styled.div`
  width: 100%;
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: start;
  align-items: center;
  max-height: 70px;
  margin-top: 10px;
  cursor: pointer;

  .p-button.p-button-text {
    padding: 0;
    background-color: transparent;
    border: none;
    ${(props) => props.theme.fonts.standard};
  }
  .p-button.p-button-text:hover {
    background-color: transparent;
  }
  .p-button.p-button-text:disabled {
    color: #757575;
  }
`;

const DialogContentContainer = styled.div`
  padding: 0.25rem 2rem 2rem 2rem;
`;

const InformationContainer = styled.div`
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  text-align: justify;
  padding: 1rem;
  border-radius: 4px;
  margin-top: 27px;
`;

export default QRCodeDownloadDialog;
