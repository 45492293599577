import { Checkbox } from 'primereact/checkbox';
import { ColumnBodyOptions, ColumnProps } from 'primereact/column';
import { LcaResultModel } from 'types/types';

import { cellDecimalEditor, cellTextEditor } from '../GridCellEditors';
import { cellTemplateWithPlaceholder, validatedCellTemplate } from '../GridService';
import LcaGridErrorsContext from './util/LcaGridErrorsContext';

const eNotationTemplate = (row: LcaResultModel, options: ColumnBodyOptions) => {
  const fieldName = options.field as keyof LcaResultModel;
  return !isNaN(parseFloat(row[fieldName] as string))
    ? parseFloat(row[fieldName] as string)
        .toExponential(2)
        .toUpperCase()
    : row[fieldName];
};

const percentValueBodyTemplate = (row: LcaResultModel, options: ColumnBodyOptions) => {
  const fieldName = options.field as keyof LcaResultModel;
  const rowText = !isNaN(parseFloat(row[fieldName] as string))
    ? `${Number(parseFloat(row[fieldName] as string).toFixed(2))}%`
    : row[fieldName];

  return cellTemplateWithPlaceholder(rowText);
};

const validatedENotationTemplateViaContext = (row: LcaResultModel, options: ColumnBodyOptions) => {
  return (
    <LcaGridErrorsContext.Consumer>
      {(errors) => {
        const eNotation = eNotationTemplate(row, options);
        if (!errors) {
          return eNotation;
        }
        const errorKeyForTableRow = `data[${options.rowIndex}]`;
        const moduleName = options.field as keyof LcaResultModel;
        const fieldError = errors?.[errorKeyForTableRow]?.[moduleName];
        return validatedCellTemplate(eNotation, fieldError);
      }}
    </LcaGridErrorsContext.Consumer>
  );
};

const validatedCellTemplateViaContext = (row: LcaResultModel, options: ColumnBodyOptions, placeholder?: string) => {
  return (
    <LcaGridErrorsContext.Consumer>
      {(errors) => {
        const fieldName = options.field as keyof LcaResultModel;
        const rowText = row[fieldName];
        if (!errors) {
          return !placeholder ? rowText : validatedCellTemplate(rowText, null, placeholder);
        }
        const errorKeyForTableRow = `data[${options.rowIndex}]`;
        const moduleName = options.field as keyof LcaResultModel;
        const fieldError = errors?.[errorKeyForTableRow as string]?.[moduleName];
        return validatedCellTemplate(rowText, fieldError, placeholder);
      }}
    </LcaGridErrorsContext.Consumer>
  );
};

export const checkboxTemplate = (row: any, options: ColumnBodyOptions) => {
  const fieldName = options.field as keyof any;
  return <Checkbox checked={row[fieldName]} style={{ opacity: 0.5 }} />;
};

const indicatorNameTemplate = (row: LcaResultModel) => {
  return <span dangerouslySetInnerHTML={{ __html: row.indicatorName || '' }} />;
};

const unitTemplate = (row: LcaResultModel) => {
  return <span dangerouslySetInnerHTML={{ __html: row.unit || '' }} />;
};

const LcaResultsGridDataColumns: ColumnProps[] = [
  {
    field: 'a1A2A3',
    header: 'A1-A3',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'a4',
    header: 'A4',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'a5',
    header: 'A5',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'b1',
    header: 'B1',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'b2',
    header: 'B2',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'b3',
    header: 'B3',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'b4',
    header: 'B4',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'b5',
    header: 'B5',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'b6',
    header: 'B6',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'b7',
    header: 'B7',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'c1',
    header: 'C1',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'c2',
    header: 'C2',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'c3',
    header: 'C3',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'c4',
    header: 'C4',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
  {
    field: 'd',
    header: 'D',
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => validatedENotationTemplateViaContext(row, options),
  },
];

const LcaResultsGridDataColumnsPdf: ColumnProps[] = [
  {
    field: 'a1A2A3',
    header: 'A1-A3',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'a4',
    header: 'A4',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'a5',
    header: 'A5',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'b1',
    header: 'B1',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'b2',
    header: 'B2',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'b3',
    header: 'B3',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'b4',
    header: 'B4',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'b5',
    header: 'B5',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'b6',
    header: 'B6',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'b7',
    header: 'B7',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'c1',
    header: 'C1',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'c2',
    header: 'C2',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'c3',
    header: 'C3',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'c4',
    header: 'C4',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
  },
  {
    field: 'd',
    header: 'D',
    style: { minWidth: '3rem', fontSize: '8px' },
    body: (row, options) => eNotationTemplate(row, options),
    className: 'module-d',
  },
];

const LcaResultsHeadingColumns: ColumnProps[] = [
  { field: 'impactCategory', header: 'Impact category', style: { minWidth: '12rem' } },
  { field: 'indicatorName', header: 'Indicator', style: { minWidth: '10rem' }, body: indicatorNameTemplate },
  { field: 'unit', header: 'Unit', style: { minWidth: '8rem' }, body: unitTemplate },
];

const LcaResultsHeadingColumnsPdf: ColumnProps[] = [
  { field: 'impactCategory', header: 'Impact category', style: { minWidth: '10rem', fontSize: '8px' } },
  { field: 'indicatorName', header: 'Indicator', style: { minWidth: '6rem', fontSize: '8px' }, body: indicatorNameTemplate },
  { field: 'unit', header: 'Unit', style: { minWidth: '8rem', fontSize: '8px' }, body: unitTemplate },
];

const LcaResultsAdditionalHeadingColumns: ColumnProps[] = [
  {
    field: 'impactCategory',
    header: 'Impact category',
    style: { minWidth: '12rem' },
    editor: (options) => cellTextEditor(options),
    body: (row, options) =>
      validatedCellTemplateViaContext(
        row,
        options,
        'Additional voluntary impact category e.g., the global impact categories according to ISO 21930:2017'
      ),
  },
  {
    field: 'indicatorName',
    header: 'Indicator',
    style: { minWidth: '10rem' },
    editor: (options) => cellTextEditor(options),
    body: (row, options) => validatedCellTemplateViaContext(row, options, 'Indicator'),
  },
  {
    field: 'unit',
    header: 'Unit',
    style: { minWidth: '8rem' },
    editor: (options) => cellTextEditor(options),
    body: (row, options) => validatedCellTemplateViaContext(row, options, 'Unit'),
  },
];

const LcaResultsAdditionalHeadingColumnsPdf: ColumnProps[] = [
  {
    field: 'impactCategory',
    header: 'Impact category',
    style: { minWidth: '12rem' },
    editor: (options) => cellTextEditor(options),
  },
  {
    field: 'indicatorName',
    header: 'Indicator',
    style: { minWidth: '10rem' },
    editor: (options) => cellTextEditor(options),
  },
  {
    field: 'unit',
    header: 'Unit',
    style: { minWidth: '8rem' },
    editor: (options) => cellTextEditor(options),
  },
];

const LcaResultsHeadingColumnsNoCategory: ColumnProps[] = [
  { field: 'impactCategory', header: 'Impact category', style: { minWidth: '12rem' }, hidden: true },
  { field: 'indicatorName', header: 'Indicator', style: { minWidth: '10rem' } },
  { field: 'unit', header: 'Unit', style: { minWidth: '8rem' }, body: unitTemplate },
];

const LcaResultsHeadingColumnsNoCategoryPdf: ColumnProps[] = [
  { field: 'impactCategory', header: 'Impact category', style: { minWidth: '12rem' }, hidden: true },
  { field: 'indicatorName', header: 'Indicator', style: { minWidth: '10rem' } },
  { field: 'unit', header: 'Unit', style: { minWidth: '8rem' }, body: unitTemplate },
];

export const LcaResultsGridColumns: ColumnProps[] = [...LcaResultsHeadingColumns, ...LcaResultsGridDataColumns];
export const LcaResultsGridColumnsPdf: ColumnProps[] = [...LcaResultsHeadingColumnsPdf, ...LcaResultsGridDataColumnsPdf];

export const LcaResultsAdditionalGridColumns: ColumnProps[] = [
  ...LcaResultsAdditionalHeadingColumns,
  ...LcaResultsGridDataColumns,
];

export const LcaResultsAdditionalGridColumnsPdf: ColumnProps[] = [
  ...LcaResultsAdditionalHeadingColumnsPdf,
  ...LcaResultsGridDataColumnsPdf,
];

export const LcaResultsGridColumnsNoCategory: ColumnProps[] = [
  ...LcaResultsHeadingColumnsNoCategory,
  ...LcaResultsGridDataColumns,
];

export const LcaResultsGridColumnsNoCategoryPdf: ColumnProps[] = [
  ...LcaResultsHeadingColumnsNoCategoryPdf,
  ...LcaResultsGridDataColumnsPdf,
];

export function createVariationColumnWithDisclaimer(disclaimerNumber: number): ColumnProps {
  return {
    field: 'variation',
    header: (
      <span>
        Variation<sup>{disclaimerNumber}</sup>
      </span>
    ),
    style: { minWidth: '5rem' },
    editor: (options) => cellDecimalEditor(options),
    body: (row, options) => percentValueBodyTemplate(row, options),
  };
}

export function createVariationDisclaimerNote(disclaimerNumber: number) {
  return {
    note: (
      <span>
        Disclaimer<sup>{disclaimerNumber}</sup>
      </span>
    ),
    description: 'The maximum variation in results between the included products.',
  };
}
