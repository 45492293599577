import { useIsFetching } from '@tanstack/react-query';

const BackCoverPage = () => {
  const isFetching = useIsFetching();

  return (
    <>
      {/* When all fetches are complete the final page is rendered which is the flag for puppeteer to start pdf conversion */}
      {!isFetching && <div className="final"></div>}
    </>
  );
};

export default BackCoverPage;
