import React from 'react';
import WizardInputSkeleton from 'components/v2/epd-wizard-inputs/WizardInputSkeleton';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Skeleton } from 'primereact/skeleton';

const SkeletonStep4: React.FC<{}> = () => {
  return (
    <>
      <FieldPanelFullWidth style={{ marginTop: 0 }}>
        <WizardInputSkeleton />
      </FieldPanelFullWidth>
      <FieldPanelFullWidth>
        <Skeleton width="10rem" />
        <Skeleton height="100px" />
      </FieldPanelFullWidth>
    </>
  );
};

export default SkeletonStep4;
