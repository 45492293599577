import { customSelectStyles } from 'components/form/Select';
import { ProcessStatus, Role } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import individualIcon from 'images/icons/svg/diploma.svg';
import filterIcon from 'images/icons/svg/funnel.svg';
import orgIcon from 'images/icons/svg/id-card-4.svg';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import CompanyService from 'services/CompanyService';
import { ConnectVerifierModel, CountryModel, EPDModel, VerifierModel, VerifierType } from 'services/EpdClient';
import EPDService from 'services/EpdService';
import MembershipService from 'services/MembershipService';
import styled, { css } from 'styled-components';
import { ButtonSmall, ErrorText, InputSmallIcon, SuccessText } from 'styles/Styles.styled';

import { ListBox, ListBoxNoItems } from '../../pages/wizard/EpdWizard';

export enum Command {
  None,
  Inquiry,
  Connect,
}

export const EpdVerifierSelect: React.FunctionComponent<{
  epd: EPDModel;
  setUpdatedEpd?: (epd: EPDModel) => void;
  refetchVerifiers?: () => void;
}> = ({ epd, setUpdatedEpd, refetchVerifiers }) => {
  const { companyId } = useContext(CompanyContext);
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [inquiryStatus, setInquiryStatus] = useState<[ProcessStatus, Command]>([ProcessStatus.None, Command.None]);
  const [verifiers, setVerifiers] = useState<VerifierModel[]>([]);
  const [selectedVerifierId, setSelectedVerifierId] = useState<string>();
  const [countries, setCountries] = useState<{ value: string | undefined; label: string | undefined }[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [filter, setFilter] = React.useState<string>();
  const { t } = useTranslation();

  React.useEffect(() => {
    const fetchVerifierCompanies = async () => {
      try {
        setStatus(ProcessStatus.Fetching);
        const result = await CompanyService.getCompanyVerifiers(companyId, selectedCountry);
        setVerifiers(result);
        setStatus(ProcessStatus.Success);
      } catch {
        setStatus(ProcessStatus.Error);
      }
    };

    fetchVerifierCompanies();
  }, [selectedCountry, companyId]);

  React.useEffect(() => {
    const fetchCountries = async () => {
      const res = await CompanyService.getCountries(Role.EPDVerifier);
      const countryOptions = res.map((country: CountryModel) => {
        return { value: country.id, label: country.name };
      });
      setCountries(countryOptions);
    };

    fetchCountries();
  }, [companyId]);

  const handleSelectedVerifier = (id: any) => {
    if (id === selectedVerifierId) {
      setSelectedVerifierId(undefined);
    } else {
      setSelectedVerifierId(id);
    }
  };

  const handleCountrySelect = (e: any) => {
    setSelectedCountry(e?.value);
  };

  const inquireCollaboration = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();

    try {
      setInquiryStatus([ProcessStatus.Fetching, Command.Inquiry]);
      await MembershipService.inquireCollaboration({
        companyId: selectedVerifierId,
        roleId: Role.EPDVerifier,
      });
      setInquiryStatus([ProcessStatus.Success, Command.Inquiry]);
    } catch {
      setInquiryStatus([ProcessStatus.Error, Command.Inquiry]);
    }
  };

  const addCollaborator = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();

    try {
      if (epd.id == null) {
        throw Error('Invalid EPD, missing ID');
      }

      if (selectedVerifierId == null) {
        throw Error('No collaborator selected');
      }

      const verifier = verifiers.find((v) => v.id === selectedVerifierId);
      if (!verifier) {
        throw Error('Verifier is selected but not found in data set');
      }

      const connectVerifierModel: ConnectVerifierModel = {
        epdId: epd.id,
        individualVerifierMembershipId: verifier.verifierType === VerifierType.Individual ? selectedVerifierId : null,
        certificationBodyCompanyId: verifier.verifierType === VerifierType.Body ? selectedVerifierId : null,
      };

      setInquiryStatus([ProcessStatus.Fetching, Command.Connect]);
      const updated = await EPDService.connectVerifier(connectVerifierModel);
      setUpdatedEpd?.(updated);
      refetchVerifiers?.();
      setInquiryStatus([ProcessStatus.Success, Command.Connect]);
    } catch {
      setInquiryStatus([ProcessStatus.Error, Command.Connect]);
    }
  };

  const handleFilter = (e: any) => {
    setFilter(e.target.value);
  };

  return (
    <>
      <Select
        options={countries}
        styles={customSelectStyles}
        placeholder={t('epdWizard.wizardStep1.epdDeveloperMenu.placeholder.filterOnLocation')}
        isLoading={!countries || countries.length === 0}
        isClearable={true}
        onChange={handleCountrySelect}
      />

      <div style={{ display: 'flex' }}>
        <div style={{ flex: '1 0 auto' }}>
          <InputSmallIcon
            style={{ backgroundImage: `url(${filterIcon})`, width: '-webkit-fill-available' }}
            onChange={handleFilter}
            value={filter || ''}
            placeholder={t('epdWizard.wizardStep1.epdDeveloperMenu.placeholder.filterOnNameOrCode')}
          />
        </div>
      </div>

      <ListBox style={{ marginTop: '0.5rem', height: '250px' }}>
        {verifiers
          .filter((x) => !filter || x.name?.toLowerCase().includes(filter.toLowerCase()))
          .map((verifier) => (
            <VerifierCompanyOption
              key={verifier.id}
              data-id={verifier.id}
              onClick={() => handleSelectedVerifier(verifier.id)}
              selected={verifier.id === selectedVerifierId}
            >
              <span>
                {verifier.verifierType === VerifierType.Body && <ListIcon src={orgIcon} title={verifier.verifierType} />}
                {verifier.verifierType === VerifierType.Individual && (
                  <ListIcon src={individualIcon} title={verifier.verifierType} />
                )}
                <span>{verifier.name}</span>
                {verifier.id === selectedVerifierId && (
                  <>
                    <ListItemButton
                      disabled={inquiryStatus[0] === ProcessStatus.Fetching}
                      onClick={(e) => inquireCollaboration(e)}
                    >
                      {t('epdWizard.wizardStep1.epdVerifierMenu.inquireCollaboration')}
                    </ListItemButton>
                    &nbsp;
                    <ListItemButton
                      disabled={inquiryStatus[0] === ProcessStatus.Fetching}
                      onClick={(e) => addCollaborator(e)}
                    >
                      {t('epdWizard.wizardStep1.epdVerifierMenu.add')}
                    </ListItemButton>
                  </>
                )}
              </span>
              {[verifier.information?.trim(), verifier.email?.trim(), verifier.phoneNumber?.trim()]
                .filter((s) => s)
                .map((s, i) => (
                  <div key={i}>{s}</div>
                ))}
            </VerifierCompanyOption>
          ))}
        {status === ProcessStatus.Fetching && (
          <ListBoxNoItems>{t('epdWizard.wizardStep1.epdVerifierMenu.messages.loading')}</ListBoxNoItems>
        )}
        {status === ProcessStatus.Success && verifiers && verifiers.length === 0 && (
          <ListBoxNoItems>{t('epdWizard.wizardStep1.epdVerifierMenu.messages.noCompanies')}</ListBoxNoItems>
        )}
      </ListBox>

      {inquiryStatus[0] === ProcessStatus.Success && inquiryStatus[1] === Command.Inquiry && (
        <SuccessText>{t('epdWizard.wizardStep1.epdVerifierMenu.messages.successInquiry')}</SuccessText>
      )}
      {inquiryStatus[0] === ProcessStatus.Success && inquiryStatus[1] === Command.Connect && (
        <SuccessText>{t('epdWizard.wizardStep1.epdVerifierMenu.messages.verifierIsAdded')}</SuccessText>
      )}
      {inquiryStatus[0] === ProcessStatus.Error && <ErrorText>{t('epdWizard.messages.error')}</ErrorText>}
    </>
  );
};

const ListIcon = styled.img`
  width: 25px;
  vertical-align: middle;
  margin-right: 0.5rem;
  float: right;
`;

const ListItemButton = styled(ButtonSmall)`
  padding: 0.5rem;
  align-self: flex-end;
`;

const VerifierCompanyOption = styled.div<{ selected: boolean }>`
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  padding: 0.5rem;

  display: flex;
  flex-direction: column;

  span {
    display: flex;
    align-items: center;
  }

  div {
    display: none;
  }

  ${(props) =>
    props.selected &&
    css`
      border-left: 3px solid ${(props) => props.theme.colors.orange};
      background-color: #f4f4f4;

      > span {
        font-weight: bold;
        border-bottom: 1px solid #ddd;
        padding-bottom: 0.5rem;
      }

      > span > span {
        flex: 1;
      }

      div {
        padding-top: 0.5rem;
        padding-left: calc(25px + 0.5rem);
        display: block;
      }
    `}

  &:hover {
    background-color: ${(props) => props.theme.colors.lightGray};
  }
`;
