import React from 'react';
import { Button } from 'primereact/button';
import AuthService from 'services/AuthService';
import FileService from 'services/FileService';
import styled from 'styled-components';
import { FieldPanelFullWidth, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { useEpdAdditionalDocuments, useEPDDefinitionInformation } from 'services/api/queries';
import { HelpBox } from '../help-boxes';
import { ButtonUploadIcon, EditDataIcon } from '../icons';
import QRCodeDownloadDialog from './dialogs/QRCodeDownloadDialog';
import SkeletonStep6 from './skeletons/SkeletonStep6';

const Step6: React.FunctionComponent<{
  epdVersionId: string | undefined;
}> = ({ epdVersionId }) => {
  const epdDefinition = useEPDDefinitionInformation(epdVersionId!).data;
  const additionalDocuments = useEpdAdditionalDocuments(epdVersionId!).data;

  const isLoading = epdDefinition === undefined || additionalDocuments === undefined;
  if (isLoading) {
    return <SkeletonStep6 />;
  }

  const compilerDocumentId = additionalDocuments?.find((doc) => doc.name === `EPD-${epdVersionId}.pdf`)?.id;

  const handleDownloadPDF = () => {
    window.open(FileService.getImageUrl(compilerDocumentId ?? ''), '_blank', 'noreferrer');
  };

  const handleRegeneratePDF = () => {
    /// not implemented
    /// to do - find out who and when can regenerate the PDF
  };

  return (
    <>
      <FieldPanelFullWidth style={{ marginTop: 0, gridRowGap: '5px' }}>
        <FieldPanelHeader>Generated EPD</FieldPanelHeader>
        <HelpBox style={{ marginTop: '30px' }}>
          After the review and publication are completed by the Secretariat, you will be able to download the generated EPD
          document in PDF format from this section.
        </HelpBox>
        {epdDefinition?.publishedVersionDate && compilerDocumentId && (
          <DownloadPDFDocumentBox>
            <Button
              icon={ButtonUploadIcon}
              style={{ fontSize: 15, fontWeight: 500 }}
              type="button"
              label="Download PDF"
              text
              onClick={handleDownloadPDF}
              disabled={false}
            />
          </DownloadPDFDocumentBox>
        )}
        {epdDefinition?.publishedVersionDate &&
          !compilerDocumentId &&
          (AuthService.isAdmin() ? (
            <DownloadPDFDocumentBox>
              Compiler document had not been created.
              <Button
                icon={EditDataIcon}
                style={{ fontSize: 15, fontWeight: 500, marginLeft: '1rem' }}
                type="button"
                label="Try to regenerate PDF"
                text
                onClick={handleRegeneratePDF}
                disabled={false}
              />
            </DownloadPDFDocumentBox>
          ) : (
            <>Compiler generated document had not been created or deleted. Please contact administrator.</>
          ))}
      </FieldPanelFullWidth>
      <FieldPanelFullWidth style={{ gridRowGap: '5px' }}>
        <FieldPanelHeader>Generate QR code</FieldPanelHeader>
        <HelpBox style={{ marginTop: '30px' }}>
          After publication you can generate a QR code with a link to the published EPD on environdec.com
        </HelpBox>
        <QRCodeDownloadDialog
          friendlyUrl={epdDefinition?.friendlyUrl}
          disabled={epdDefinition?.publishedVersionDate ? false : true}
        />
      </FieldPanelFullWidth>
    </>
  );
};

const DownloadPDFDocumentBox = styled.div`
  width: 100%;
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: start;
  align-items: center;
  max-height: 70px;
  margin-top: 10px;
  cursor: pointer;

  .p-button.p-button-text {
    padding: 0;
    background-color: transparent;
    border: none;
    ${(props) => props.theme.fonts.standard};
  }
  .p-button.p-button-text:hover {
    background-color: transparent;
  }
  .p-button.p-button-text:disabled {
    color: #757575;
  }
`;

export default Step6;
