import { Column } from 'primereact/column';
import { DataTableProps, DataTableValue, DataTableValueArray } from 'primereact/datatable';
import React, { useState } from 'react';

import { UseMutationResult } from '@tanstack/react-query';

interface UseGridRowSelectionViaCheckboxResult<T> {
  selectedRows: T[];
  setSelectedRows: React.Dispatch<React.SetStateAction<T[]>>;
  dataTableProps: DataTableProps<DataTableValueArray> | undefined;
  checkboxColumnNode: React.ReactNode;
  handleDeleteSelected: () => Promise<void>;
  isDeleteDisabled: boolean;
  gridId: string;
}

/**
 * Use this hook to easily disable rows selection in a data table.
 * This reduces code duplication and you don't need to fight Typescript, if working with read-only tables.
 *
 * @param isReadOnly Whether selection is disabled
 * @param deleteMutation is optional, makes deletion of the selected rows in one call via handleDeleteSelected
 * @returns
 */
export default function useGridRowSelectionViaCheckbox<T extends DataTableValue>(
  isReadOnly: boolean,
  deleteMutation?: UseMutationResult<void, any, string[], any>
): UseGridRowSelectionViaCheckboxResult<T> {
  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  // Hack, to force re-render of the table, when selected rows are deleted, this is needed for edit controls to "forget" previously entered values,
  // More explanation: the ord field is defined as key in the table rows, and default row always has the same ord value, so datagrid does not rerender editors for it
  const [gridId, setGridId] = useState<string>(new Date().getTime().toString());

  const handleDeleteSelected = async () => {
    const ids = selectedRows.filter((x) => x.id).map((x) => x.id);
    if (ids.length) {
      await deleteMutation?.mutateAsync(ids);
    }
    setSelectedRows([]);
    setGridId(new Date().getTime().toString());
  };

  const isDeleteDisabled = isReadOnly || !selectedRows?.length || (!!deleteMutation && deleteMutation.isPending);

  return {
    selectedRows,
    setSelectedRows,
    dataTableProps: !isReadOnly
      ? {
          selectionMode: 'checkbox',
          selection: selectedRows,
          onSelectionChange: (e) => setSelectedRows(e.value as T[]),
        }
      : // When table is disabled, we need to unset all selection-related props,
        // otherwise you still can select rows using Tab key.
        undefined,
    checkboxColumnNode: <Column selectionMode={isReadOnly ? undefined : 'multiple'} headerStyle={{ width: '3rem' }} />,
    handleDeleteSelected,
    isDeleteDisabled,
    gridId,
  };
}
