import React from 'react';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Skeleton } from 'primereact/skeleton';
import WizardInputSkeleton from 'components/v2/epd-wizard-inputs/WizardInputSkeleton';

const SkeletonSystemBoundaryTab: React.FC<{}> = () => {
  return (
    <FieldPanelFullWidth>
      <Skeleton width="10rem" />
      <WizardInputSkeleton />
      <WizardInputSkeleton />
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Skeleton width="10rem" />
          <Skeleton width="10rem" />
        </div>
        <Skeleton height="500px" className="mt-3" />
      </div>
      <div>
        <Skeleton width="20rem" />
        <Skeleton height="67px" className="mt-2" />
      </div>
      <div>
        <Skeleton width="20rem" />
        <Skeleton height="106px" className="mt-2" />
      </div>
    </FieldPanelFullWidth>
  );
};

export default SkeletonSystemBoundaryTab;
