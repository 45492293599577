import React from 'react';
import FilesList from 'components/v2/file-row/FilesList';
import { ImagesUpload } from 'components/v2/images-upload';
import SmartLabel from 'components/v2/labels/SmartLabel';
import { VerificationType } from 'services/EpdClient';
import VersionHistorySection from '../VersionHistorySection';
import VersionHistoryGeneralInformationGrid from './VersionHistoryGeneralInformationGrid';
import { useEpdVersionHistoryDataOrWhileLoadingThenNull } from '../utils/EpdVersionHistoryDataContext';
import { VersionHistorySummaryGridSkeleton } from '../VersionHistorySummaryGrid';

const VersionHistoryGeneralInformationSection: React.FC<{}> = () => {
  const data = useEpdVersionHistoryDataOrWhileLoadingThenNull();

  return (
    <VersionHistorySection title="General information">
      {data ? (
        <>
          <VersionHistoryGeneralInformationGrid />

          {data.thirdPartyVerification.verificationType === VerificationType.EPDProcessCertification &&
            data.thirdPartyVerification.processCertificates.length > 0 && (
              <div>
                <SmartLabel label="Process certificate" />
                <FilesList files={data.thirdPartyVerification.processCertificates} disabled />
              </div>
            )}

          {data.verificationReportInfo?.verificationReports.length > 0 && (
            <div>
              <SmartLabel label="Verification report" />
              <FilesList files={data.verificationReportInfo?.verificationReports} disabled />
            </div>
          )}

          <div>
            <SmartLabel label="Product image for cover page" />
            <ImagesUpload
              name="ProductImages"
              images={data.coverPageInformation.productImages}
              onUpload={() => {}}
              onReorder={() => {}}
              onRemove={() => {}}
              single
              disabled
              preferEnabledAppearanceEvenWhenDisabled
            />
          </div>

          <div>
            <SmartLabel label="Organisation images" />
            <ImagesUpload
              name="OrganizationImages"
              images={data.organizationInformation.organizationImages}
              onUpload={() => {}}
              onReorder={() => {}}
              onRemove={() => {}}
              disabled
              preferEnabledAppearanceEvenWhenDisabled
            />
          </div>

          <div>
            <SmartLabel label="Organisation logo" />
            <ImagesUpload
              name="CompanyLogoImages"
              images={data.coverPageInformation.companyLogoImages}
              onUpload={() => {}}
              onReorder={() => {}}
              onRemove={() => {}}
              single
              disabled
              preferEnabledAppearanceEvenWhenDisabled
            />
          </div>
        </>
      ) : (
        <VersionHistorySummaryGridSkeleton skeletonRowsCount={27} />
      )}
    </VersionHistorySection>
  );
};

export default VersionHistoryGeneralInformationSection;
