import { useEffect, useRef } from 'react';
import { ColumnEvent } from 'primereact/column';
import { UseMutationResult } from '@tanstack/react-query';

interface BaseEntity {
  ord: number;
}

interface DynamicGridWithEmptyFirstRowParams<T extends BaseEntity> {
  rows: T[] | null | undefined;

  createMutation: UseMutationResult<T, any, T, any>;
  updateMutation: UseMutationResult<T, any, T, any>;

  emptyEntity: Omit<T, 'ord'>;
}

interface DynamicGridWithEmptyFirstRowResult<T extends BaseEntity> {
  handleAddRow(): void;
  handleCellEdit(e: ColumnEvent): void;
  saveModifiedGridData(row: T & { isFakeRow?: true }): void;
  gridData: (T & { isFakeRow?: true })[];
}

export default function useDynamicGridWithEmptyFirstRow<T extends BaseEntity>({
  rows,
  createMutation,
  updateMutation,
  emptyEntity,
}: DynamicGridWithEmptyFirstRowParams<T>): DynamicGridWithEmptyFirstRowResult<T> {
  const isAlreadyCreatingFakeRowRef = useRef<boolean>(false);

  useEffect(() => {
    isAlreadyCreatingFakeRowRef.current = false;
  }, [rows]);

  const handleAddRow = () => {
    if (!rows) {
      return;
    }
    // initially add default row in case the table is empty and has fake empty row
    if (rows.length === 0) {
      if (!isAlreadyCreatingFakeRowRef.current) {
        isAlreadyCreatingFakeRowRef.current = true;
        createMutation.mutate({ ...emptyEntity, ord: 1 } as T);
      }
      createMutation.mutate({ ...emptyEntity, ord: 2 } as T);
      return;
    }

    const maxOrd = Math.max(...rows.map((r) => r.ord));
    createMutation.mutate({ ...emptyEntity, ord: maxOrd + 1 } as T);
  };

  const handleCellEdit = (e: ColumnEvent) => {
    const { rowData, newValue, field } = e;
    const newRowData = { ...rowData, [field]: newValue };
    saveModifiedGridData(newRowData);
  };

  const saveModifiedGridData = (row: T & { isFakeRow?: true }) => {
    const { isFakeRow, ...newData } = row;
    if (isFakeRow) {
      isAlreadyCreatingFakeRowRef.current = true;
      createMutation.mutate(newData as T);
      return;
    }
    updateMutation.mutate(newData as T);
  };

  const gridData = rows && rows.length > 0 ? rows : [{ ...(emptyEntity as T), ord: 1, isFakeRow: true }];

  return {
    handleAddRow,
    handleCellEdit,
    saveModifiedGridData,
    gridData,
  };
}
