import { Skeleton } from 'primereact/skeleton';
import React from 'react';

const WizardInputSkeleton: React.FC<{}> = () => {
  return (
    <div>
      <Skeleton width="20rem" />
      <Skeleton height="44px" className="mt-2" />
    </div>
  );
};

export default WizardInputSkeleton;
