import React from 'react';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Skeleton } from 'primereact/skeleton';
import WizardInputSkeleton from 'components/v2/epd-wizard-inputs/WizardInputSkeleton';

const SkeletonScenariosTab: React.FC<{}> = () => {
  return (
    <>
      <FieldPanelFullWidth>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Skeleton width="15rem" />
          <Skeleton width="8rem" />
        </div>
      </FieldPanelFullWidth>
      <FieldPanelFullWidth>
        <WizardInputSkeleton />
        <div>
          <Skeleton width="20rem" />
          <Skeleton height="67px" className="mt-2" />
        </div>
      </FieldPanelFullWidth>
      <FieldPanelFullWidth>
        <Skeleton width="10rem" />
        <WizardInputSkeleton />
      </FieldPanelFullWidth>
      <Skeleton height="70px" className="mt-4" />
    </>
  );
};

export default SkeletonScenariosTab;
